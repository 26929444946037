import {
    ChildrenTypeEnum,
  } from './data'
  
  export const consolidateRatingsMenu:
    { summary: string, elementType: ChildrenTypeEnum, detailList?: any[], customKey: string, selected?: any }[] = [
      {
        summary: 'merFurList.menu.assessmentBody',
        elementType: ChildrenTypeEnum.CHECKBOX,
        customKey: 'assessment_body'
      },
      {
        summary: 'merFurList.menu.round',
        elementType: ChildrenTypeEnum.RADIO,
        customKey: 'round'
      }  
    ]
  