import React, { useState } from 'react';
import { ReactComponent as KeyIcon } from '../../assets/icons/icono-key.svg'
import { ReactComponent as DiscussionIcon } from '../../assets/icons/discussion-icon.svg'
import 'react-pivottable/pivottable.css';
import classes from './discussion-menu.module.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
// import GroupsIcon from '@mui/icons-material/Groups';
import UserService from '../../services/UserService';
import { USER_ADMIN_ROLE } from '../../components/common/constant/constants';

export default function DiscussionsMenu() {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [canEdit] = useState<boolean>(UserService.hasRole([USER_ADMIN_ROLE]));

  const onNavigate = (route: string) => {
    localStorage.setItem('lastPath', pathname + search)
    navigate(route)

  }
  return (
    <>

      <div className={classes.title}>{t('discussionMenu.title')}</div>
      <div className='main-menu'>
        {canEdit ?
          <div className='menu-item' onClick={() => onNavigate('/main/discussions')}>
            <div className='wrapper'>
              <DiscussionIcon />
              <p>{t('discussionMenu.discussion')}</p>
            </div>
          </div>
          : null
        }
        <div className='menu-item' onClick={() => onNavigate('/main/key-issues')}>
          <div className='wrapper'>
            <KeyIcon />
            <p>{t('discussionMenu.keyIssue')}</p>
          </div>
        </div>
      </div >
    </>
  )
}
