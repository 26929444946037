import { createContext, useContext, useState } from 'react'

interface ErrorContextProps {
  children: React.ReactNode
}

interface ErrorContextInterface {
  error: string,
  setError: (error: string) => void
  cb?: () => void
  setCb?: (cb: any) => void
}

export const ErrorContext = createContext<ErrorContextInterface>({
  error: null,
  setError: (error: string) => { },
  cb: null,
  setCb: (cb: any) => { },
})

export const useErrorContext = () => useContext(ErrorContext)

export const ErrorProvider = ({ children }: ErrorContextProps) => {
  const [error, setError] = useState<any>(null)
  const [cb, setCb] = useState<any>(null)
  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
        setCb,
        cb
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
