import { useEffect, useState } from 'react'
import ProgressBar from '../../components/common/progress-bar/progress-bar'
import { ReactComponent as KeyIssueIcon } from './../../assets/icons/icono-key.svg'
import KeyIssueStepOne from './step-one/step-one'
import KeyIssueStepTwo from './step-two/step-two'
import DefaultDataService from '../../services/default-data.service'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../contexts/language-context'
import { useErrorContext } from '../../contexts/error-context'

export default function KeyIssues() {

  const { setError: setContextError } = useErrorContext()
  const { t } = useTranslation('global')
  const [step, setStep] = useState<number>(1)
  const [workingGroupList, setWorkingGroupList] = useState<any[]>(null)
  const [plenaryList, setPlenaryList] = useState<any[]>(null)
  const [comparisonId, setComparisonId] = useState<number[]>([])
  const defaultService = DefaultDataService.getInstance(setContextError)
  const { language: appLanguage } = useLanguage()

  useEffect(() => {
    Promise.all([
      defaultService.findAll(appLanguage, 'working-groups'),
      defaultService.findAll(appLanguage, 'plenaries'),
    ]).then(([workingGroups, plenaries]) => {
      if (!workingGroups || !plenaries) return
      setPlenaryList(plenaries)
      setWorkingGroupList(workingGroups)
    })
  }, [appLanguage])

  return (
    <>
      <ProgressBar title={t('navbar.key-issues-antecedents')} icon={KeyIssueIcon} >
        <div className='stepper' style={{ marginLeft: '300px' }}>
          <div className='first-step' style={{ paddingLeft: '10px', paddingRight: '10px' }} onClick={() => setStep(1)}>
            <div className={`button-step ${step === 1 ? 'button-step-active' : ''}`} >
              <span >1</span>
            </div>
            <p className={`step-name ${step === 1 ? 'step-name-active' : ''}`}>{t('keyIssueList.stepper.select')}</p>
          </div>
          <div className='divider divider-step-1-completed'></div>
          <div className='second-step'

            style={{ paddingLeft: '10px', paddingRight: '10px' }}
            onClick={() => {
              if (comparisonId.length === 0) return
              setStep(2)
            }
            }>
            <div className={`button-step ${step === 2 ? 'button-step-active' : ''}`}>
              <span>2</span>
            </div>
            <p className={`step-name ${step === 2 ? 'step-name-active' : ''}`}>{t('keyIssueList.stepper.report')}</p>
          </div>
        </div>
      </ProgressBar>
      <div className='main-content'>

        {(step === 1 && workingGroupList && plenaryList) &&
          <KeyIssueStepOne
            step={step}
            setStep={setStep}
            plenaries={plenaryList}
            workingGroups={workingGroupList}
            setComparisonId={setComparisonId}
          />
        }
        {step === 2 &&
          <KeyIssueStepTwo
            setStep={setStep}
            comparisonId={comparisonId}
          />
        }
      </div>
    </>
  )
}
