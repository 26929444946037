import { Checkbox, FormControlLabel } from '@mui/material'

import { useEffect, useState } from 'react'

export interface InputProps {
  value?: boolean
  label?: string
  defaultChecked?: boolean
  onChange?: any
}

export default function FilterCheckbox({ value, label, defaultChecked = false, onChange }: InputProps = {}) {
  const [checked, setChecked] = useState(defaultChecked)

  useEffect(()=>{
    if(value != undefined) {
      setChecked(value);
    }

  }, [value]);

  const handleChange = (event) => {
    const isChecked = event.target.checked
    setChecked(isChecked)
    if (onChange) {
      onChange(isChecked)
    }
  }

  return (
    <>
      <FormControlLabel
        label={label}
        control={<Checkbox
          size='medium'
          // value={initialVal}
          checked={checked}
          // checked={(dataChecked.has(data.customKey) && dataChecked.get(data.customKey).find(d=>d === el.code) !== undefined) ?? false}
          // defaultChecked={handleInitialValue(idx)}
          onChange={handleChange}
        />} />
    </>
  )
}
