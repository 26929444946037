
import { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select, ThemeProvider, createTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NewMerIcon } from './../../../../assets/icons/new-mer-icon.svg'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'
import { MutualEvaluation } from '../../../../model/models-module';
import { getEffectivenessForm, getTechnicalComplianceForm } from '../../../../configs/data';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import modalStyles from './mer-modal.module.css';
import MutualEvaluationService from '../../../../services/mutual-evaluation.service';
import { useLanguage } from '../../../../contexts/language-context';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useDefaultData } from '../../../../contexts/default-data-context';
import { LanguagesEnum } from '../../../../enums/mer-status.enum';
import { useErrorContext } from '../../../../contexts/error-context';
interface MerModalInterface {
  open: boolean
  setOpen: any
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.7)',
  width: 855,
  bgcolor: '#F5F5F5',
  border: 'none',
  borderRadius: '15px',
  padding: '40px 73px',
}
const menuProps = {
  PaperProps: {
    style: {
      transform: 'scale(0.7)',
      transformOrigin: 'top left',
    },
  },
}

export default function MerModal({ open, setOpen }: MerModalInterface) {

  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { language: appLanguage } = useLanguage()
  const [fileBase64, setFileBase64] = useState(null)
  const [file, setFile] = useState(null)
  const { language } = useLanguage()
  const [loading, setLoading] = useState(false)
  const [spanishLang, setSpanishLang] = useState('')
  const [englishLang, setEnglishLang] = useState('')
  const { register, watch, handleSubmit, formState, setValue, reset } = useForm()
  const { errors } = formState
  const { countries, rounds: roundData } = useDefaultData()
  // const [initialized, setInitialized] = useState<boolean>(false)
  const { setError: setContextError } = useErrorContext()
  const service = MutualEvaluationService.getInstance(setContextError)

  useEffect(() => {
    if (language === LanguagesEnum.EN) {
      setEnglishLang('English')
      setSpanishLang('Spanish')
    }
    if (language === LanguagesEnum.ES) {
      setEnglishLang('Inglés')
      setSpanishLang('Español')
    }
  }, [language])

  const handleClose = () => {
    setOpen(false)
    setFile(null)
    setFileBase64(null)
    reset()
  }

  const goNewMER = (id: number, lang: string) => {
    handleClose()
    navigate(`/main/mer/update/${id}/${lang}`)
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })

  const deleteFile = () => {
    setFile(null)
    setFileBase64(null)
  }
  const onSubmit = (handleSubmit(async (data) => {
    setLoading(true)
    data.file = fileBase64
    const round = roundData.find(r => r.roundCode === data.round);

    const effectiveness = getEffectivenessForm(round.methodology)

    const immediateOutcomes = []

    for (const prop in effectiveness) {
      immediateOutcomes.push({
        immediateOutcomeCode: prop,
        coreIssues: effectiveness[prop].coreIssues.map(el => {
          return {
            coreIssueCode: el.coreIssueCode
          }
        })
      })
    }
    const { recommendations } = getTechnicalComplianceForm(round.methodology).technicalCompliance
    const recommendationsList = []
    for (const prop in recommendations) {
      recommendationsList.push({
        recommendationCode: prop,
        criterias: recommendations[prop].criterias.map(el => {
          return { criterionCode: el.criterionCode }
        })
      })
    }
    const body = {
      ...data,
      immediateOutcomes,
      recommendations: recommendationsList
    }

    try {
      const result: MutualEvaluation = await service.saveMutualEvaluation(appLanguage, body)
      if (result?.id) {
        goNewMER(result.id, data.language)
      } else {
        console.log('error', result)
      }
    } catch (e) {
      // setError(e)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }))

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <div className={modalStyles.modalContainer}>
          <div className='row'>
            <div className='col-12 center'>
              <NewMerIcon style={{ width: '55px', height: '67px' }} />
            </div>
          </div>
          <div className={modalStyles.title}>{t('newMer.modal.title')}</div>
          <div className={modalStyles.subtitle}>{t('newMer.modal.subTitle')}</div>

          {!loading && <div className={modalStyles.content}>
            <div className='row pt-30 pb-30'>
              <div className='col-6 pr-15'>
                <FormControl fullWidth error={!!errors.round} >
                  <InputLabel id='demo-simple-select-label' className={modalStyles.label}>{t('newMer.modal.fields.round')}</InputLabel>
                  <Select
                    {...register('round', { required: t('validations.required') })}
                    labelId='labelRound'
                    id='label-round-id'
                    label='ROUND'
                    value={watch('round') || ''}
                    className={modalStyles.select}
                    MenuProps={menuProps}
                  >
                    {
                      roundData?.map((el, i) => {
                        return (
                          <MenuItem key={i} className={modalStyles.menuItem} value={+el.roundCode}>
                            {el.roundValue}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                  <FormHelperText>{errors.round?.message.toString()}</FormHelperText>
                </FormControl>
              </div>
              <div className='col-6 pl-15'>
                <FormControl fullWidth error={!!errors.countryId}>
                  <Autocomplete
                    {...register('countryId', { required: t('validations.required') })}
                    className={`${modalStyles.select}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event: any, newValue) => {
                      (newValue)
                        ? setValue('countryId', newValue.id)
                        : setValue('countryId', '')
                    }}
                    options={countries?.map(r => {
                      return { label: r.countryName, id: r.id }
                    }) ?? []}
                    renderInput={(params) =>
                      <TextField {...params}
                        label={t('newMer.modal.fields.country')}
                        className={modalStyles.autocompleteLabel} error={!!errors.countryId}
                      />}
                  />
                  <FormHelperText>{errors.countryId?.message.toString()}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className='row pb-30 '>
              <div className='col-6 pr-15'>
                <FormControl fullWidth error={!!errors.language} >
                  <InputLabel id='demo-simple-select-label' className={modalStyles.label}>
                    {t('newMer.modal.fields.language')}
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='LANGUAGE'
                    className={modalStyles.select}
                    {...register('language', { required: t('validations.required') })}
                    value={watch('language') || ''}
                    MenuProps={menuProps}
                  >
                    <MenuItem className={modalStyles.menuItem} value='es'>{spanishLang}</MenuItem>
                    <MenuItem className={modalStyles.menuItem} value='en'>{englishLang}</MenuItem>
                  </Select>
                  <FormHelperText>{errors.language?.message.toString()}</FormHelperText>
                </FormControl>
              </div>
              <div className='col-6 pl-15'>
                <FormControl fullWidth error={!!errors.date}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker

                      {...register('date', { required: t('validations.required') })}
                      slotProps={{ textField: { error: !!errors.date } }}
                      className={`${modalStyles.datePicker}`}
                      format={language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                      onChange={(newValue) => setValue('date', newValue.format('YYYY-MM-DD'))}

                    />
                  </LocalizationProvider>
                  <FormHelperText>{errors.date?.message.toString()}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className='row pb-30'>
              {!file &&
                <div className={`col-12 ${modalStyles.dropZone} ${!!errors.file && modalStyles.required}`}>
                  <div {...getRootProps()} >
                    <input {...Object.assign(getInputProps(), register('file', { required: t('validations.required') }))} />
                    {
                      <div className={modalStyles.dragHere} >
                        <div className='row'>
                          <div className='col-12 center'>
                            <PDFIcon />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 center'>
                            {isDragActive ? <p>{t('newMer.modal.fields.dropFiles')} ...</p> : <p>{t('newMer.modal.fields.dragAndDrop')}</p>}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
              {file &&
                <div className={modalStyles.deleteFile}>
                  {file.name}
                  <span onClick={deleteFile}><DeleteIcon /></span>
                </div>
              }
            </div>
          </div>}

          {loading && <div className='center pt-30'>
            <CircularProgress style={{ margin: 'auto' }} disableShrink />
          </div>}

          {!loading && <div className='row'>
            <div className='col-12 center lato'>
              <button type='button' className='basic-button mr-20 return-button lato' onClick={handleClose} >{t('buttons.return')}</button>
              <button type='submit' className='basic-button blue-button lato' onClick={onSubmit}>{t('buttons.continue')}</button>
            </div>
          </div>}
        </div>
      </Box>
    </Modal>
  )
}
