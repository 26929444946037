import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import './radiobutton.css'
import { useState } from 'react'
import { CodeDescription } from '../../../../model/models-module'
import styles from '../accordion-v2.module.css'

export interface InputProps {
  name?: string
  label?: string
  checked?: boolean
  onChange?: any
  initValue?: string;
  dataRadio: CodeDescription[];
}

const FilterRadioButton: React.FC<InputProps> = ({
  label,
  name,
  initValue = '',
  checked = false,
  onChange,
  dataRadio,
}: InputProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(initValue);
  const handleChange = (val) => {
    setSelectedValue(val);
    if (onChange) {
      onChange(val)
    }
  }

  return (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedValue}
            name="radio-buttons-group"
          >
            {
              dataRadio.map((el, idx) => {
                return (
                  <div key={idx} className={styles.opt}>
                    {(
                      <FormControlLabel value={el.code} control={<Radio checked={selectedValue === el.code}
                        onChange={()=>handleChange(el.code)}
                        name={`radio-${name}-${idx}`}
                      />} label={el.description} />
                    )}
                  </div>
                )
              })
            }
          </RadioGroup>
        </FormControl>
        )
}

export default FilterRadioButton;
