import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import LoadingComponent from '../../../components/common/loading/loading';
import { useTranslation } from 'react-i18next';
import classes from '../consolidate-ratings.module.css'
import { ConsolidatedRatingsResponse, EffectivenessConclusion, TechnicalComplianceConclusion } from '../../../model/models-module';
import FollowUpReportService from '../../../services/follow-up-report.service';
import { handleFile } from '../../../utils/CommonUtils';
import { useLanguage } from '../../../contexts/language-context';
import ConsolidateRatingService from '../../../services/consolidate-ratings.service';
import { useErrorContext } from '../../../contexts/error-context';

interface RatingModalProps {
  openModal: boolean;
  handleClose: () => void;
  loadingModal: boolean;
  dataModal: TechnicalComplianceConclusion | EffectivenessConclusion;
  renderConclusion: () => string;
  ratingClick: {
    title: string
    recommendationCode: string
    originalAssessmentDesc: string
    originalAssessmentShortDesc: string
    originalAssessmentColor: string
    lastAssessmentDesc: string
    lastAssessmentShortDesc: string
    lastAssessmentColor: string
    furId: number
  };
  selectedCountry: ConsolidatedRatingsResponse
}

export default function RatingModal({
  openModal,
  handleClose,
  loadingModal,
  dataModal,
  renderConclusion,
  ratingClick,
  selectedCountry
}: RatingModalProps) {
  const { t } = useTranslation('global')
  const { setError: setContextError } = useErrorContext()
  const { language: appLanguage } = useLanguage()
  const furService = FollowUpReportService.getInstance(setContextError)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const furId: number = ratingClick.furId
  const [dataTranslations, setDataTranslations] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const [error, setError] = useState<any>(null)
  const service = ConsolidateRatingService.getInstance(setContextError)

  useEffect(() => {

    if (furId) {
      setLoading(true)
      service.getFurTranslations(+furId)
        .then((result => {
          setDataTranslations(result.data)
        }))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    }

  }, [furId])


  function getFurLanguage(): string {
    return dataTranslations.furTranslations.find(
      (t) => t.lang === appLanguage
    )?.lang
      ?? dataTranslations.furTranslations[0].lang;
  }

  const downloadPdf = async () => {
    try {
      setShowSpinner(true)
      const result = await furService.downloadFurPDF(appLanguage, ratingClick.furId, getFurLanguage())
      const filename = `${selectedCountry?.countryDesc}-${ratingClick?.title}-${getFurLanguage().toUpperCase()}`
      handleFile(result?.data, `${filename}.pdf`)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setShowSpinner(false)
    }

  }

  useEffect(() => {
    if (error) {
      console.log(error)
    }
    console.warn(dataTranslations)
  }, [error, dataTranslations])

  // Función de guardia de tipo para EffectivenessConclusion
  function isEffectivenessConclusion(
    variable: any
  ): variable is EffectivenessConclusion {
    return (
      (variable as EffectivenessConclusion).immediateOutcomeTranslations !== undefined
    );
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) scale(0.7)',
          bgcolor: loadingModal || showSpinner || loading || !dataModal ? '#fff' : '#003479',
          color: '#fff',
          border: '3px solid #fff',
          boxShadow: 24,
          borderRadius: 3,
          width: '50%',
          maxHeight: '75%',
          overflow: 'auto',
          p: 4,
        }}
      >
        {loadingModal || showSpinner || loading || !dataModal ? (
          <LoadingComponent />
        ) : (
          <>
            <div>
              <div className={classes['modalRatingsContainer']}>
                {ratingClick?.furId != null ? (<div className={classes['furContainer']}>
                  <p>FUR Rating</p>
                  <span
                    key={dataModal.merId}
                    className={classes.ratingTableCellModal}
                    style={{ background: ratingClick.lastAssessmentColor, border: '3px solid #4D4B4B', cursor: 'default' }}
                  >
                    {ratingClick.lastAssessmentShortDesc}
                  </span>
                </div>
                ) : null}
                <div className={classes['ratingContainer']}>
                  <p>MER Rating</p>
                  <span
                    key={dataModal.merId}
                    className={classes.ratingTableCellModal}
                    style={{ background: ratingClick.originalAssessmentColor, cursor: 'default' }}
                  >
                    {ratingClick.originalAssessmentShortDesc}
                  </span>
                </div>
              </div>
              {ratingClick?.furId != null ? (<p className={classes['redirectText']}>{t('consolidateRatings.furRedirect.text')}<a onClick={() => downloadPdf()}>{t('consolidateRatings.furRedirect.redirect')}</a></p>) : null}
            </div>


            <h2>{!isEffectivenessConclusion(dataModal) ? t('recommendation.weightingAndConclusion') : t('recommendation.conclusion')}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: renderConclusion() }} // Ajusta el maxHeight según sea necesario
            />
          </>
        )}
      </Box>
    </Modal>
  );
};
