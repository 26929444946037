import React, { useCallback, useEffect, useState } from 'react'
import CalificationTable from '../../../../../components/common/calificationTable/Calificationtable'
import { ConsolidatedRatingsResponse, EffectivenessConclusion, TableDetailCell, TableHeaderCell, TechnicalComplianceConclusion } from '../../../../../model/models-module';
import classes from './table-of-report.module.css'
import MainLeftMenu from '../../../../../components/layout/left-menu/left-menu';
import { ReactComponent as ColumnGraphIcon } from '../../../../../assets/icons/column-graph-icon.svg'
import { ReactComponent as RowGraphIcon } from '../../../../../assets/icons/row-graph-icon.svg'
import { ReactComponent as CakeGraphIcon } from '../../../../../assets/icons/cake-graph-icon.svg'
import { ReactComponent as DonutGraphIcon } from '../../../../../assets/icons/donut-graph-icon.svg'
import { ReactComponent as CurrentData } from '../../../../../assets/icons/current-data.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../../../../../components/hooks/useData';
import { addParam, handleFile, handleXlsxFile } from '../../../../../utils/CommonUtils';
import { useLanguage } from '../../../../../contexts/language-context';
import LoadingComponent from '../../../../../components/common/loading/loading';
import ProgressBar from '../../../../../components/common/progress-bar/progress-bar';
import { useTranslation } from 'react-i18next';
import { ReactComponent as XlsIcon } from '../../../../../assets/icons/xls-icon.svg'
import RatingModal from '../../../../consolidate-ratings/modal/rating-modal';
import ConsolidateRatingService from '../../../../../services/consolidate-ratings.service';
import { LanguagesEnum } from '../../../../../enums/mer-status.enum';
import { useErrorContext } from '../../../../../contexts/error-context';

export default function TableOfReport() {
  const { setError: setContextError } = useErrorContext()
  const { language: appLanguage } = useLanguage()
  const { t } = useTranslation('global')
  const navigate = useNavigate();
  const location = useLocation();
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
  const { assementBody, dataHeaders, title }: { assementBody: string[], dataHeaders: TableHeaderCell[], title: string } = location.state || {};
  const [stateDataHeaders, setStateDataHeaders] = useState<any>(dataHeaders)
  const [roundFilter] = useState<string>('4');
  const [baseEndpoint] = useState<string>(`consolidate/${roundFilter}`);
  const [endpoint, setEndpoint] = useState<string>(null);
  const [dataModal, setDataModal] = useState<any>(null);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [loading, error, dataGrid] = useData<ConsolidatedRatingsResponse[]>(endpoint, 'GET')
  const [ratingClick, setRatingClick] = useState<any>(null)
  const [params] = useState<Map<string, string>>(new Map())
  const [openModal, setOpenModal] = useState(false)
  const consolidateRatingsService = ConsolidateRatingService.getInstance(setContextError)
  const [isDownload, setIsDownload] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  useEffect(() => {
    setStateDataHeaders((oldValue) => {
      const iOCode = appLanguage === LanguagesEnum.EN ? 'IO' : 'RI'
      const list = oldValue?.map(el => {
        if (el.label.includes('RI') || el.label.includes('IO')) {
          const code = el.label.split('.')[1]
          return {
            ...el,
            label: `${iOCode}.${code}`,
            key: `${iOCode}.${code}`
          }
        } else {
          return el
        }
      })
      return list
    })

  }, [appLanguage])

  const downloadXLS = async () => {

    try {
      setIsDownload(true)
      const countries = 'countries=' + dataGrid.map(el => el.countryCode).join(',')
      const aux = [...stateDataHeaders]
      aux.shift()
      const columns = 'columns=' + aux.map(el => el.label).join(',')
      const lang = 'lang=' + appLanguage
      const worksheet = 'worksheet-name=sheet'
      const result = await consolidateRatingsService.downloadXLS(`${lang}&${countries}&${columns}&${worksheet}`, +roundFilter)
      handleFile(result?.data, 'report.xlsx')
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setIsDownload(false)
    }
  }


  useEffect(() => {
    if (baseEndpoint) {
      addParam(appLanguage, 'lang', params, baseEndpoint, setEndpoint)
    }
  }, [baseEndpoint, appLanguage])

  useEffect(() => {
    if (baseEndpoint && assementBody?.length != 0) {
      addParam(assementBody, 'assessment-bodies', params, baseEndpoint, setEndpoint)
    } else {
      addParam(undefined, 'assessment-bodies', params, baseEndpoint, setEndpoint)
    }
  }, [baseEndpoint, assementBody])

  const handleOpen = async (rating: any, merId: number) => {
    if (rating.recommendationCode) {
      consolidateRatingsService.getRatingEffectiveness(merId, rating.recommendationCode)
        .then((result) => {
          setLoadingModal(true)
          if (result.data) {
            setDataModal(result.data)
            setOpenModal(true)
            setRatingClick(rating)
          } else {
            setContextError('not found data')
            setLoadingModal(false)
          }
        })
        .finally(() => {
          setLoadingModal(false)
        })
    } else {
      consolidateRatingsService.getRatingTechnicalCompliance(merId, rating.immediateOutcomeCode)
        .then((result) => {
          setLoadingModal(true)
          if (result.data) {
            setDataModal(result.data)
            setOpenModal(true)
            setRatingClick(rating)
          } else {
            setContextError('not found data')
            setLoadingModal(false)
          }
        })
        .finally(() => {
          setLoadingModal(false)
        })
    }


  };
  const renderResultsTable = (list: ConsolidatedRatingsResponse[]) => {
    return list?.map((el, index) => {
      const newItems = []
      // if (value == 'TECHNICAL COMPLIANCE') { }
      stateDataHeaders?.forEach((headers, i) => {
        const rating = el.technicalCompliances.find(tech => tech.title == headers.label)
          ?? el.effectiveness.find(eff => eff.title == headers.label)
        if (rating) {
          const label = rating.title
          const color = rating.furId ? rating.lastAssessmentColor : rating.originalAssessmentColor
          const element =
            <span
              key={headers.label}
              className={classes.ratingTableCell}
              style={{ background: color, border: rating.furId != null ? '3px solid #4D4B4B' : null, cursor: 'pointer' }}
              onClick={() => { handleOpen(rating, el.merId) }}
            >
              {rating.furId != null ? rating.lastAssessmentShortDesc : rating.originalAssessmentShortDesc}
            </span>
          newItems.push([label, element])
        }
      })
      return new TableDetailCell({ countryName: el.countryDesc }, null, new Map<string, React.ReactNode>(newItems),)
    })
  }
  // const buildParams = useCallback(() => {
  //   // let args = ''
  //   console.log(dataHeaders)

  // }, [])

  useEffect(() => {
    if (dataGrid) {
      setDataShow(renderResultsTable(dataGrid))
    }
  }, [dataGrid])

  const handleClose = () => {
    setOpenModal(false)
  }
  function isTechnicalComplianceConclusion(
    variable: any
  ): variable is TechnicalComplianceConclusion {
    return (
      (variable as TechnicalComplianceConclusion).recommendationTranslations !== undefined
    );
  }
  // Función de guardia de tipo para EffectivenessConclusion
  function isEffectivenessConclusion(
    variable: any
  ): variable is EffectivenessConclusion {
    return (
      (variable as EffectivenessConclusion).immediateOutcomeTranslations !== undefined
    );
  }
  function renderConclusion() {
    if (isTechnicalComplianceConclusion(dataModal)) {
      const translation = dataModal.recommendationTranslations.find(
        (t) => t.lang === appLanguage
      );
      return translation
        ? translation.weightingAndConclusion
        : dataModal.recommendationTranslations[0].weightingAndConclusion;
    } else if (isEffectivenessConclusion(dataModal)) {
      const translation = dataModal.immediateOutcomeTranslations.find(
        (t) => t.lang === appLanguage
      );
      return translation
        ? translation.conclusion
        : dataModal.immediateOutcomeTranslations[0].conclusion;
    }
  }
  const findCalifications = () => {
    const califications: { calificationName: string, counter: number, color: string }[] = [];

    dataShow.forEach((country) => {
      Array.from(country.customDefinitions.values()).forEach((component) => {
        if (React.isValidElement(component)) {
          const calificationName = component.props.children;
          const existingCalification = califications.find(calification => calification.calificationName === calificationName);

          if (existingCalification) {
            existingCalification.counter += 1;
          } else {
            califications.push({ calificationName, counter: 1, color: component.props.style.background });
          }
        }
      });
    });

    const result = califications.map((calification, i) => ({
      id: i,
      value: calification.counter,
      label: calification.calificationName,
      color: calification.color
    }));

    return result;
  };

  if (assementBody == undefined || dataHeaders == undefined) navigate('*')
  else return (
    <>
       <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <CurrentData />
          <div>
            <span>{t('reportAndStatistics.graphicsData.title')}</span>
            {
              title &&
              <div style={{ color: '#5ECDE9', fontSize: '22px' }}>{t(title)}</div>
            }
          </div>
        </div>
      </div>
      <div className='main-content'>
        <MainLeftMenu >
          <div className={classes['graphMenuContainer']}>
            <div className={classes['title-menu']}>{t('reportAndStatistics.graphicsData.table.graphs')}</div>
            <DonutGraphIcon className={classes['icon-menu']} onClick={() => {
              !loading ? navigate('/main/reports/graphics/graph-of-report', {
                state: {
                  dataShow: findCalifications(),
                  graphType: 'donut'
                }
              }) : null;
            }} />
            <ColumnGraphIcon className={classes['icon-menu']} onClick={() => {
              !loading ? navigate('/main/reports/graphics/graph-of-report', {
                state: {
                  dataShow: findCalifications(),
                  graphType: 'column'
                }
              }) : null;
            }} />
            <CakeGraphIcon className={classes['icon-menu']} onClick={() => {
              !loading ? navigate('/main/reports/graphics/graph-of-report', {
                state: {
                  dataShow: findCalifications(),
                  graphType: 'pie'
                }
              }) : null;
            }} />
            <RowGraphIcon className={classes['icon-menu']} onClick={() => {
              !loading ? navigate('/main/reports/graphics/graph-of-report', {
                state: {
                  dataShow: findCalifications(),
                  graphType: 'row'
                }
              }) : null;
            }} />
          </div>
        </MainLeftMenu>
        <div className={classes['ratingsTable']}>
          <div className={'container-abm-page'}>

            {
              loading || isDownload ?
                <LoadingComponent /> :
                <CalificationTable

                  headers={stateDataHeaders}
                  rows={dataShow}
                  countItems={dataShow.length}
                  rowsPerPage={dataShow.length}
                  hasActions={false}
                  appendOnScrollToBottom={false}
                />
            }
            </div>
            <div className='buttons-section d-flex-center'>
              {!isDownload &&
                <button className=' basic-button-icon blue-button lato svg-button'
                  onClick={downloadXLS} disabled={!(dataGrid)}>
                  <XlsIcon /> {t('buttons.download')} XLS
                </button>
              }
            </div>
          </div>
        {openModal ?
          <RatingModal
            openModal={openModal}
            handleClose={handleClose}
            loadingModal={loadingModal}
            dataModal={dataModal}
            renderConclusion={renderConclusion}
            ratingClick={ratingClick}
            selectedCountry={null}
          />
          : null}
      </div>
    </>
  )
}
