import Keycloak from 'keycloak-js'

const baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL
const jsonUrl = baseURL
  ? baseURL + (baseURL.endsWith('/') ? 'auth/config' : '/auth/config')
  : '/keycloak.json'

const _kc = new Keycloak(jsonUrl)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      checkLoginIframe: true,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated || !isLoggedIn()) {
        console.warn('user is not authenticated..!')
        doLogin()
        localStorage.clear();
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getTokenParsed = () => _kc.tokenParsed

const isLoggedIn = () => !!_kc.token

_kc.onTokenExpired = () => {
  _kc
    .updateToken(5)
    .then(() => {})
    .catch(doLogin)
}

const isTokenGoingToExpire = (): boolean =>
  (_kc.tokenParsed?.exp || 0) + 60000 < Math.round(new Date().getTime() / 1000)

const updateToken = (successCallback: any): Promise<boolean | void> => {
  if (isTokenGoingToExpire()) {
    return _kc.updateToken(5).then(successCallback).catch(doLogin)
  }

  return Promise.resolve().then(successCallback)
}

const getUsername = () => {
  // console.log(_kc.tokenParsed)
  return _kc.tokenParsed?.name || _kc.tokenParsed?.preferred_username
}

const getEmail = () => {
  // console.log(_kc.tokenParsed)
  return _kc.tokenParsed?.email
}

const hasRole = (roles: string[]) => {
  let existRole = roles.some((role: string) => _kc.hasRealmRole(role))
  if (!existRole && _kc.tokenParsed)
    existRole = roles.some((role: string) => _kc.hasResourceRole(role, _kc.tokenParsed?.azp))
  return existRole
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  getEmail,
  hasRole,
  isTokenGoingToExpire,
}

export default UserService
