import BaseService from './BaseService';


export default class ConsolidateRatingService extends BaseService {

  private static _instance: ConsolidateRatingService;
  baseURL: string = 'consolidate'

  public static getInstance(setError: (message: string | null) => void): ConsolidateRatingService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async findAll({ round, lang, args = '' }: { round: string, lang: string, args: string }) {
    try {
      let params = '';
      if (args != '') {
        params += `&${args}`;
      }
      const { data: result } = await this.client({ url: `${this.baseURL}/${round}?lang=${lang}${params}`, method: 'GET' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    }
    catch (e) {
      this.handleError(e)
    }
  }

  async downloadXLS(params: string, round: number): Promise<any> {
    try {
      const result = await this.client({ url: `${this.baseURL}/${round}/excel?${params}`, method: 'GET', responseType: 'blob' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }
  async downloadExcel({ round, lang, args = '' }: { round: string, lang: string, args: string }): Promise<any> {
    try {
      const headers = { 'Content-Type': 'blob' };
      let params = '';
      if (args != '') {
        params += `&${args}`;
      }
      const result = await this.client({
        url: `${this.baseURL}/${round}/excel?lang=${lang}${params}`, method: 'GET', headers,
        responseType: 'arraybuffer',
      })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }
  async getRatingEffectiveness(merId: number, recommendationCode: string): Promise<any> {
    try {
      const result = await this.client({ url: `${this.baseURL}/${merId}/recommendations-translations/${recommendationCode}`, method: 'GET' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }
  async getRatingTechnicalCompliance(merId: number, immediateOutcomes: string): Promise<any> {
    try {
      const result = await this.client({ url: `${this.baseURL}/${merId}/immediate-outcomes-translations/${immediateOutcomes}`, method: 'GET' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async getFurTranslations(furId: number): Promise<any> {
    try {
      const result = await this.client({ url: `${this.baseURL}/fur-translations/${furId}`, method: 'GET' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

}
