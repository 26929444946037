import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ExpandIcon } from './../../../../assets/icons/minus-icon.svg';
import { ReactComponent as MerIcon } from './../../../../assets/icons/icono-mer.svg';
import MainLeftMenu from '../../../../components/layout/left-menu/left-menu';
import styles from './../../mer-fur.module.css';

import { newMerMenu } from '../../../../configs/new-mer.config';
import CustomAccordion from '../../../../components/common/accordion/accordion';
import { getMerInformationForm, MERInformation } from '../../../../configs/data';
import { useParams } from 'react-router';
import MutualEvaluationService from '../../../../services/mutual-evaluation.service';
import { useTranslation } from 'react-i18next';
import CustomFlag from '../../../../components/common/flag/flag';
import { useData } from '../../../../components/hooks/useData';
import { useLanguage } from '../../../../contexts/language-context';
import PlusIcon from '../../../../components/common/icon/plus-icon/plus-icon';
import LoadingComponent from '../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../contexts/error-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDefaultData } from '../../../../contexts/default-data-context';
import { DefaultViewer } from './components/default-viewer';
import { SpecificCharacteristicViewer } from './components/specific-characteristic-viewer';
import { ImmediateOutcomeViewer } from './components/immediate-outcome-viewer';
import { RecommendationViewer } from './components/recommendation-viewer';
import { KeyDeficiencyViewer } from './components/key-deficiency-viewer';

export default function ViewMERNew() {

  const { t } = useTranslation('global')
  const location = useLocation()
  const { id: merId, lang: merLanguage } = useParams()
  const { language: appLanguage } = useLanguage()
  const [methodology, setMethodology] = useState<string>('2022');
  const [loadingMerData, error, merData] = useData<any>(`${appLanguage}/mer/${merId}/header-info?lang=${merLanguage}`, 'GET')
  const [loadingFullMerData, errorFullMerData, fullMerData] = useData<any>(`${appLanguage}/mer/complete/${merId}?lang=${merLanguage}`, 'GET')
  const [selectedItem, setSelectedItem] = useState<{ menu: number, element: number }>(null)
  const [fullName, setFullName] = useState<string>('')

  const getFixedIndex = (index: number): string => {
    return index < 9 ? `0${index + 1}` : `${index + 1}`
  }
  const getItemFullName = (i: number, j: number): { description: string, code: string } => {
    const selectedItemCode = newMerMenu[i]?.detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[j]?.code
    let description: string = null
    let name = null
    let code = null

    if (selectedItemCode === 'recommendation' || selectedItemCode === 'immediateOutcome') {

      if (selectedItemCode === 'recommendation') {
        description = `recommendations.${methodology}.` + selectedItemCode + getFixedIndex(j)
        // code = description + getFixedIndex(j)
      } else {
        description = selectedItemCode + getFixedIndex(j) + `.${methodology}.title`
      }
      code = selectedItemCode + getFixedIndex(j)
      name = `${t(`${selectedItemCode}.${methodology}.title`)} ${j + 1}`
    } else {
      name = `${t(`${selectedItemCode}.${methodology}.title`)}`
      code = selectedItemCode
    }
    return { description: description ? `${name} - ${t(description)}` : `${name}`, code }
  }
  const handleSelection = useCallback((i: number, j: number) => {
    const { description, code: divId } = getItemFullName(i, j)
    setFullName(description)
    setSelectedItem({ menu: i, element: j })
    setTimeout(() => {
      const element = document.getElementById(divId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0)

  }, [methodology])

  const resetSelection = () => {
    setSelectedItem(null)
  }

  useEffect(() => {
    if (selectedItem) {
      const fullName: string = getItemFullName(selectedItem.menu, selectedItem.element).description
      setFullName(fullName)
    }
  }, [appLanguage])

  useMemo(() => {
    if (merData!) {
      setMethodology(merData.roundMethodology ?? '2022');
    }

  }, [merData])

  return (
    <>
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <MerIcon />
          <div>
            <span>{t('mer.description')}</span>
            {
              fullName &&
              <div style={{ color: '#5ECDE9', fontSize: '22px' }}>{fullName}</div>
            }
          </div>
        </div>
        {!loadingMerData && <div className='mer-info right'>
          <CustomFlag code={merData?.countryCode} />
          <span className='pl-15'>{merData?.countryDesc} - {merData?.period} - {merData?.roundDesc} - {merLanguage.toUpperCase()}</span>
        </div>}
      </div>
      <div className='main-content'>
        <MainLeftMenu>
          {

            loadingMerData ?
              <LoadingComponent></LoadingComponent>
              :
              newMerMenu.map((item, i) => {
                const path = 'update-mer.menu'
                const summary = t(path + '.' + item.summary + '.title')
                return (
                  <CustomAccordion
                    onClick={resetSelection}
                    customKey={i}
                    key={i}
                    summary={summary}
                    detailList={item.detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined).map((r, i) => {
                      const aux = path + '.' + item.summary + '.' + r.code + '.title'
                      return (r.code === 'recommendation' || r.code === 'immediateOutcome') ?
                        `${t(path + '.' + item.summary + '.' + r.code + '.title')} ${i + 1}`
                        : t(aux)
                    })}
                    expandIcon={ExpandIcon}
                    collapseIcon={PlusIcon}
                    onClickChildren={(childrenId: number) => { handleSelection(i, childrenId) }}
                  />
                )
              })
          }
        </MainLeftMenu>
        <div className='page-content'>
          {
            !fullMerData ?
              <LoadingComponent />
              :
              <div className={`${styles.merViewer}`}>
                <div className={styles.separator}>{t('update-mer.menu.merInformation.title')}</div>
                {
                  MERInformation.filter(r => r.visibleWithMethodology.find(x => x === methodology))
                    .map(it => {
                      if (it.code === 'specificCharacteristics')
                        return (
                          <SpecificCharacteristicViewer
                            key={it.code}
                            data={fullMerData}
                            methodology={methodology}
                          />
                        )

                      return <DefaultViewer
                        key={it.code}
                        data={fullMerData}
                        methodology={methodology}
                        sourceData={it.code}
                        traductionSection='merInformation'
                      />
                    })
                }
                {
                  <>
                    <ImmediateOutcomeViewer
                      data={fullMerData.immediateOutcomes}
                      methodology={methodology}
                    />
                    <RecommendationViewer
                      data={fullMerData.recommendations}
                      methodology={methodology}
                    />
                    <KeyDeficiencyViewer
                      data={fullMerData.recommendations}
                      methodology={methodology}
                    />
                  </>
                }

              </div>
          }
        </div>
      </div >
    </>
  )
}
