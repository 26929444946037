import { Box, Modal } from '@mui/material'
import './errorDialog.css'
import { useTranslation } from 'react-i18next';
import { ReactComponent as CoverImg } from './../../../assets/images/cover.svg'
export interface ErrorDialogInterface {
  open: boolean,
  content: string,
  onClose: () => void

}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.8)',
  width: 786,
  bgcolor: '#F5F5F5',
  border: 'none',
  borderRadius: '40px',
  outline: 'none'
}

const ErrorDialog = ({ open, content, onClose }: ErrorDialogInterface) => {
  const { t } = useTranslation('global')

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='errorDialog'>
          <div className='container d-flex'>
            <div className='info-section'>
              <div className='title'>
                {t('dialog.error.title')}
              </div>
              <div className='content'>
                {content}
              </div>
              <div className='btn-section' >
                <button className='closeBtn' onClick={handleClose}>{t('dialog.error.close')}</button>
              </div>
            </div>
            <div className='img-section'>
              <CoverImg width={206} height={332} />
            </div>
          </div>
        </Box >
      </Modal >
    </>)
}
export default ErrorDialog;
