import React from 'react';
import { ReactComponent as CurrentData } from '../../assets/icons/current-data.svg'
import { ReactComponent as CustomizedReports } from '../../assets/icons/customized-reports.svg'
import 'react-pivottable/pivottable.css';
import './reports.css';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Reports() {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const onNavigate = (route: string) => {
    localStorage.setItem('lastPath', pathname + search)
    navigate(route)

  }
  return (
    <>

      <div className='title'>{t('reportAndStatistics.selectReport')}</div>
      <div className='main-menu'>
        <div className='menu-item' onClick={() => onNavigate('/main/reports/graphics')}>
          <div className='wrapper'>
            <CurrentData />
            <p>{t('reportAndStatistics.graphicsData.title')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => onNavigate('/main/reports/customized-reports')}>
          <div className='wrapper'>
            <CustomizedReports />
            <p>{t('reportAndStatistics.customizedReports.title')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => onNavigate('/main/reports/pivot-table')}>
          <div className='wrapper'>
            <CustomizedReports />
            <p>{t('reportAndStatistics.pivotTable.title')}</p>
          </div>
        </div>
      </div >
    </>
  )
}
