import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface RichTextAreaInterface {
  value: any,
  onChange: any,
  onBlur?: any,
  className?: string
}

export default function RichTextArea({ value, onChange, onBlur, className }: RichTextAreaInterface) {

  const modules = {
    toolbar: [
      [],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  }

  return (
    <div onBlur={onBlur}>
      <ReactQuill
        className={className ?? ''}
        modules={modules}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
