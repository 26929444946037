import React, { useState } from 'react';
import { ReactComponent as CurrentData } from '../../../../assets/icons/current-data.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/icons/icono-report.svg'
import 'react-pivottable/pivottable.css';
import './graphics-data.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../../../components/common/progress-bar/progress-bar';
import { useLanguage } from '../../../../contexts/language-context';
import { LanguagesEnum } from '../../../../enums/mer-status.enum';

export default function GraphicsData() {

  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { language: appLanguage } = useLanguage()
  const iOLangCode = appLanguage === LanguagesEnum.EN ? 'IO' : 'RI'

  const colStyle = {
    maxWidth: '230px',
    display: 'flex',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    alignItems: 'center',
    color: '#003479',
    background: '#F1F1F1',
    borderRadius: '6px',
    paddingTop: '15px',
    paddingBottom: '15px',
  }

  const headerStyle = {
    minWidth: '230px',
    maxWidth: '230px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    textAlign: 'left',
    paddingTop: '15px',
    paddingBottom: '15px',
    background: '#5ECDE9',
    borderRadius: '6px'
  }

  const calificationRow = {
    minWidth: '105px',
    maxWidth: '105px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    paddingTop: '15px',
    paddingBottom: '15px',
    background: '#5ECDE9',
    borderRadius: '6px'
  }

  return (
    <>
      <ProgressBar title={t('reportAndStatistics.graphicsData.title')} icon={CurrentData} >
        <>
        </>
      </ProgressBar>
      <div className='titleReports'>{t('reportAndStatistics.graphicsData.reports.title')}</div>
      <div className='menuReports'>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 1; index <= 40; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.technicalComplianceGafilat',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.technicalComplianceGafilat')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 1; index <= 11; index++) {
            newHeaders.push({
              label: `${iOLangCode}.${index}`,
              key: `${iOLangCode}.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.effectivenessGafilat',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.effectivenessGafilat')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 1; index <= 40; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['FATF'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.technicalComplianceFatf',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.technicalComplianceFatf')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 1; index <= 11; index++) {
            newHeaders.push({
              label: `${iOLangCode}.${index}`,
              key: `${iOLangCode}.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['FATF'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.effectivenessFatf',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.effectivenessFatf')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.1`,
            key: `${iOLangCode}.1`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          for (let index = 1; index <= 2; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.nationalPoliciesGafilat',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.nationalPoliciesGafilat')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 6; index <= 8; index++) {
            newHeaders.push({
              label: `${iOLangCode}.${index}`,
              key: `${iOLangCode}.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          for (let index = 29; index <= 32; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.legalSystem&OperationalIssue',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.legalSystem&OperationalIssue')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          for (let index = 9; index <= 10; index++) {
            newHeaders.push({
              label: `${iOLangCode}.${index}`,
              key: `${iOLangCode}.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          for (let index = 5; index <= 6; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          newHeaders.push({
            label: 'R.8',
            key: 'R.8',
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.financingProliferation',
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.financingProliferation')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.5`,
            key: `${iOLangCode}.5`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          for (let index = 24; index <= 25; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.legalPerson&Arrangements'
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.legalPerson&Arrangements')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.11`,
            key: `${iOLangCode}.11`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          newHeaders.push({
            label: 'R.7',
            key: 'R.7',
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.financingProliferation'
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.financingProliferation')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.2`,
            key: `${iOLangCode}.2`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          for (let index = 36; index <= 40; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.internacionalCooperation'
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.internacionalCooperation')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.4`,
            key: `${iOLangCode}.4`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          for (let index = 9; index <= 23; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.preventiveMeasures'
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.preventiveMeasures')}</p>
        </div>
        <div className='menu-item' onClick={() => {
          const newHeaders = [
            {
              label: t('consolidateRatings.table.country'),
              key: 'countryName',
              sorteable: false,
              headerStyle,
              colStyle
            }
          ]
          newHeaders.push({
            label: `${iOLangCode}.3`,
            key: `${iOLangCode}.3`,
            sorteable: false,
            headerStyle: calificationRow,
            colStyle
          })
          for (let index = 26; index <= 28; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
          navigate('/main/reports/graphics/table-of-report', {
            state: {
              assementBody: ['GAFILAT'],
              dataHeaders: newHeaders,
              title: 'reportAndStatistics.graphicsData.reports.supervision'
            }
          });
        }}>
          <ReportIcon />
          <p>{t('reportAndStatistics.graphicsData.reports.supervision')}</p>
        </div>
      </div>
    </>
  )
}
