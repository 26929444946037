import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { useNavigate } from 'react-router-dom';import { BarChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import classes from './step-four.module.css'
import ImageIcon from '@mui/icons-material/Image';
interface Props {
    selectedTypeGraph: any
    selectedCalifications: any,
    setStep: any,
}

export default function CustomizedReportsStepFour({
    selectedTypeGraph,
    selectedCalifications,
    setStep,
  }: Props){
    const { t } = useTranslation('global');const navigate = useNavigate();
    const [total, setTotal] = useState<number>(0);
    const ref = useRef()


    useEffect(() => {
        if (selectedCalifications) {
            const sum = selectedCalifications.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            setTotal(sum);
        }
    }, [selectedCalifications]);

    const calculatePercentage = (value: number) => {
        return total !== 0 ? parseFloat(((value / total) * 100).toFixed(1)) : 0;
    };

    const stringifyStylesheet = (stylesheet) => {
      try {
        const pStringifyStylesheet = stylesheet.rules
          ? Array.from(stylesheet.rules)
            .map((rule: any) => rule.cssText || '')
            .join('\n')
          : ''

        return pStringifyStylesheet;
      } catch (e) {
        console.warn(e);
        return '';
      }

    }
    const getStyles = () =>
      Array.from(document.styleSheets)
        .map(s => stringifyStylesheet(s))
        .join('\n')

    const getDefs = () => {
      const styles = getStyles()

      return `<defs><style type="text/css"><![CDATA[${styles}}]]></style></defs>`
    }

    const downloadAsPng = useCallback(() => {

      if (ref && ref.current) {
        const svgElems = (ref.current as any).querySelectorAll('svg')

        if (svgElems.length === 0) {
          console.log('No svg chart found in container')
          return;
        }

        const svgElem = svgElems[0]
        // adding styles
        const defs = getDefs()
        svgElem.insertAdjacentHTML('afterbegin', defs)

        const output = { 'name': 'chart.png', 'width': svgElem.clientWidth, 'height': svgElem.clientHeight }
        // const uriData = `data:image/svg+xml;base64,${btoa(svgElem.outerHTML)}` // it may fail.
        const uriData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svgElem))))}`
        const img = new Image()
        img.src = uriData
        img.onload = () => {
          const canvas = document.createElement('canvas');
          [canvas.width, canvas.height] = [output.width, output.height]
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, output.width, output.height)

          // download
          const a = document.createElement('a')
          const quality = 1.0 // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/imageSmoothingQuality
          a.href = canvas.toDataURL('image/png', quality)
          a.download = output.name
          a.append(canvas)
          a.click()
          a.remove()
        }
      }
    }, [ref]);

    return (
        <div ref={ref} className={classes['graphicPageContainer']}>
            {(selectedTypeGraph == 'donut' ||  selectedTypeGraph == 'pie') && <PieChart
            series={[
                {
                data: selectedCalifications,
                innerRadius: selectedTypeGraph == 'donut' ? 100 : null,
                arcLabel: (params) => `${params.value}` + (calculatePercentage(params.value) < 5 ? '' : `(%${calculatePercentage(params.value)})` ?? '')
                },
            ]}
            sx={{
                fontSize: '18px'
            }}
            width={700}
            height={550}
            />}
            {(selectedTypeGraph == 'row' || selectedTypeGraph == 'column') &&
            <BarChart
                dataset={selectedCalifications}
                series={[{dataKey: 'value'}]}
                xAxis={selectedTypeGraph == 'column' ? [{scaleType: 'band', dataKey: 'label', colorMap: {
                    type:'ordinal',
                    colors: selectedCalifications.map(({color}) => (color))
                }}]: null}
                yAxis={selectedTypeGraph == 'row' ? [{scaleType: 'band', dataKey: 'label', colorMap: {
                    type:'ordinal',
                    colors: selectedCalifications.map(({color}) => (color))
                }}]: null}
                width={1050}
                height={550}
                layout={selectedTypeGraph == 'row' ? 'horizontal' : null}
                barLabel="value"
            />
            }
        <div className={classes['buttonsContainer']}>
          <button type='button' className='basic-button blue-button lato mr-25' onClick={()=>{downloadAsPng()}}>
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <ImageIcon sx={{ color: '#5ECDE9', fontSize: 35, marginRight: '15px' }} />
              <span>{t('buttons.downloadPng')}</span>
            </div>
          </button>
          <button type='button' className='basic-button blue-button lato' onClick={()=>{setStep(3)}}>{t('buttons.return')}</button>
        </div>
        </div>
      );
}
