import { useTranslation } from 'react-i18next'
import { ReactComponent as PdfIcon } from './../../../assets/icons/pdf-icon.svg'
import CustomFlag from '../../../components/common/flag/flag'
import { KeyIssueStepInterface } from '../step-one/step-one'
import { useEffect, useRef, useState } from 'react'
import KeyIssueService from '../../../services/key-issue.service'
import { useLanguage } from '../../../contexts/language-context'
import LoadingComponent from '../../../components/common/loading/loading'
import { useErrorContext } from '../../../contexts/error-context'
import { ReactComponent as XlsIcon } from './../../../assets/icons/xls-icon.svg'
import { ReactComponent as WordIcon } from './../../../assets/icons/word-icon.svg'
import ReportingService from '../../../services/reporting.service'
import { handleFile } from '../../../utils/CommonUtils'

export default function KeyIssueStepTwo({ setStep, step, comparisonId }: KeyIssueStepInterface) {
  const { setError: setContextError } = useErrorContext()
  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const keyIssueService = KeyIssueService.getInstance(setContextError)
  const reportingService = ReportingService.getInstance(setContextError)
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const divRef = useRef(null)

  const [isDownload, setIsDownload] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    keyIssueService.comparisonStep(comparisonId, appLanguage)
      .then(result => {
        setData(result)
      })
      .finally(() => setLoading(false))

  }, [appLanguage])

  const downloadXLS = async () => {

    try {
      setIsDownload(true)
      const result = await reportingService.downloadExcel(appLanguage, comparisonId)
      handleFile(result?.data, 'key-issues-report.xlsx')
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setIsDownload(false)
    }
  }

  const handlePdf = async () => {
    try {
      setIsDownload(true)
      if (divRef.current) {
        const result = await reportingService.downloadPdf(appLanguage, divRef.current.innerHTML)
        if (result) handleFile(result, 'key-issue.pdf')
      }
    } finally {
      setIsDownload(false)
    }
  }
  const handleWord = async () => {
    try {
      setIsDownload(true)
      if (divRef.current) {
        const result = await reportingService.downloadDoc(appLanguage, divRef.current.innerHTML)
        if (result) handleFile(result, 'key-issue.docx')
      }
    } catch (e) {
      console.log(e)
    }
    finally {
      setIsDownload(false)
    }
  }
  return <>
    <div className='page-content'>
      <div className='step-three-container'>
        <div className='title' style={{ paddingTop: 0 }}>{t('comparison-tool.step-3.results')}</div>
        <div className="result-wrapper">
          <div className='results' ref={divRef}>
            {!loading && !isDownload ?
              data?.data?.map((el) => {
                const date = new Date(el.plenary)
                const month = `keyIssueList.table.rows.months.${date.getMonth() + 1}`
                const fullDate = `${t(month)}  ${date.getFullYear()}`

                return <>
                  < div className='comparison-key'>
                    <div className='comparison-key-name' style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '50%' }}>
                        {el.recIo}
                      </div>
                      <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                        <span style={{ marginRight: '20px' }}>
                          {el.assessmentBodyName} / {el.workingGroup} / {fullDate} / {el.countryName}
                        </span>
                        <CustomFlag code={el.countryCode} />
                      </div>
                    </div>
                    <div className='comparison-key-content'>
                      <div className='comparison-key-item'>
                        <div className='result-item-title'>
                          {t('keyIssueList.keyIssueDetail.key-issue')}
                        </div>
                        <div className='result-item-content'
                          style={{ color: '#878787' }}
                          dangerouslySetInnerHTML={{ __html: el.keyIssue }}
                        >
                        </div>
                      </div>
                      <div className='comparison-key-item'>
                        <div className='result-item-title'>
                          {t('keyIssueList.keyIssueDetail.description')}
                        </div>
                        <div className='result-item-content'
                          style={{ color: '#878787' }}
                          dangerouslySetInnerHTML={{ __html: el.keyIssueDescription }}
                        >

                        </div>
                      </div>
                      <div className='comparison-key-item'>
                        <div className='result-item-title'>
                          {t('keyIssueList.keyIssueDetail.relevant-decisions-workinggroup')}
                        </div>
                        <div className='result-item-content'
                          style={{ color: '#878787' }}
                          dangerouslySetInnerHTML={{ __html: el.relevantDecisionsWorkingGroup }}
                        >
                        </div>
                      </div>
                      <div className='comparison-key-item'>
                        <div className='result-item-title'>
                          {t('keyIssueList.keyIssueDetail.relevant-decisions-plenary')}
                        </div>
                        <div className='result-item-content'
                          style={{ color: '#878787' }}
                          dangerouslySetInnerHTML={{
                            __html: el.relevantDecisionsPlenary
                          }}
                        >

                        </div>
                      </div>
                    </div>
                  </div>
                </>
              })
              : <LoadingComponent />}
          </div>
        </div>
        {!loading && !isDownload &&
          <>
            <div className='buttons-section'>
              <button className='basic-button-icon return-button mr-20  lato' onClick={() => setStep(1)}>
                {t('buttons.return')}
              </button>

              <button className=' basic-button-icon blue-button lato svg-button' onClick={handlePdf}>
                <span>
                  <PdfIcon />
                </span>
                {t('buttons.download')} PDF
              </button>
              <button className=' basic-button-icon blue-button lato svg-button'
                onClick={downloadXLS}>
                <XlsIcon /> {t('buttons.download')} XLS
              </button>
              <button className='mr-20 basic-button blue-button lato' onClick={handleWord}>
                <WordIcon className='pr-15' /> {t('buttons.download')} DOC
              </button>
            </div>
            <div className='not-available'>{t('keyIssueList.keyIssueDetail.not-available')}</div>
          </>
        }
      </div>
    </div>
  </>
}
