import './loading.css'
export default function LoadingComponent({ styles }: any) {
  return (
    <div
      style={styles ?? {
        // background: '#00000026',
        background: 'white',
        // opacity: '0.5',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>

      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <div>
      </div>
    </div>
  )
}
