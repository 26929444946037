export enum MerStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export enum LanguagesEnum {
  ES = 'es',
  EN = 'en'
}

export enum ReportTypeEnum {
  MER = 'MER',
  FUR = 'FUR'
}

export enum AutomaticReportTypeEnum {
  TECHNICAL_COMPLIANCE,
  EFFECTIVENESS
}

export enum ConsolidatedRatingEnum {
  TECHNICAL_COMPLIANCE = 'TECHNICAL COMPLIANCE',
  EFFECTIVENESS = 'EFFECTIVENESS'
}
