import { METHODOLOGY_2000, METHODOLOGY_2022 } from '../components/common/constant/constants';

// const { t } = useTranslation('global')

export const assessmentBodyItems: { name: string; value: string }[] = [
  { name: 'GAFILAT', value: 'GAFILAT' },
  { name: 'FATF', value: 'FATF' },
  { name: 'APG', value: 'APG' },
  { name: 'EAG', value: 'EAG' },
  { name: 'MONEYVAL', value: 'MONEYVAL' },
  { name: 'CFATF', value: 'CFATF' },
  { name: 'GIABA', value: 'GIABA' },
  { name: 'GABAC', value: 'GABAC' },
  { name: 'ESAAMLG', value: 'ESAAMLG' },
  { name: 'MENAFTAF', value: 'MENAFTAF' },
]
export const rounds: { name: string; value: string }[] = [
  { name: 'Round 4', value: '4' },
  { name: 'Round 5', value: '5' },
]
export const dates: { name: string; value: string }[] = [
  { name: '2014', value: '2014' },
  { name: '2015', value: '2015' },
  { name: '2016', value: '2016' },
  { name: '2017', value: '2017' },
  { name: '2018', value: '2018' },
  { name: '2019', value: '2019' },
  { name: '2020', value: '2020' },
  { name: '2021', value: '2021' },
  { name: '2022', value: '2022' },
  { name: '2023', value: '2023' },
  { name: '2024', value: '2024' },
]
export const countries: { name: string; value: string }[] = [
  { name: 'Argentina', value: 'Argentina' },
  { name: 'Chile', value: 'Chile' },
  { name: 'Costa Rica', value: 'Costa Rica' },
  { name: 'Perú', value: 'Perú' },
  { name: 'Brasil', value: 'Brasi' },
]

export enum ChildrenTypeEnum {
  CHECKBOX = 'checkbox',
  RADIO = 'radioButton',
  TEXTAREA = 'textArea',
  INPUT = 'input',
  ACCORDION = 'accordion',
}

type ChildrenType = { type?: ChildrenTypeEnum; items: string[] }
interface menuItem {
  name: string
  children?: ChildrenType | menuItem[] | any
}
export const specificCharacteristics: menuItem[] = [
  {
    name: 'POPULATION',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: ['0-10 millions', '10-20 millions', '20-30 millions', '30-40 millions'],
    },
  },
  {
    name: 'GDP',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: ['0-10 millions', '10-20 millions', '20-30 millions', '30-40 millions'],
    },
  },
  {
    name: 'INTERNATIONAL FINANCIAL CENTER',
  },
  {
    name: 'LEGAL PERSONS / ARRANGEMENT CREATION CENTER',
  },
  {
    name: 'INFORMAL ECONOMY',
  },
  {
    name: 'NUMBER OF BANKING INSTITUTIONS',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: ['Large', 'Medium', 'Small'],
    },
  },
]

// step2
export const riskAndContextFactors: string[] = [
  'Risk general situation',
  'Overview of ML/TF risk',
  'Materiality',
  'Background and other Contextual Factors',
  'Overview of AML/CFT strategy',
  'Overview of the legal & institutional framework',
  'Overview of financial sector and DNFBPs',
  'Overview of preventive measures',
  'Overview of legal persons and arrangements',
  'Overview of supervisory arrangements',
  'Overview of international cooperation',
]

export const technicalCompliance: menuItem[] = [
  {
    name: 'RECOMENDATION 1',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
  {
    name: 'RECOMENDATION 2',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
  {
    name: 'RECOMENDATION 3',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
  {
    name: 'RECOMENDATION 4',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
  {
    name: 'RECOMENDATION 5',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
  {
    name: 'RECOMENDATION 6',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: [
        'Key Deficiencies',
        'Criterion 1.1',
        'Criterion 1.2',
        'Criterion 1.3',
        'Criterion 1.4',
        'Criterion 1.5',
        'Criterion 1.6',
        'Criterion 1.7',
        'Criterion 1.8',
        'Criterion 1.8',
        'Criterion 1.9',
        'Criterion 1.10',
        'Criterion 1.11',
        'Criterion 1.12',
      ],
    },
  },
]

export const effectiveness: menuItem[] = [
  {
    name: 'EXECUTIVE SUMMARY',
    children: {
      type: ChildrenTypeEnum.CHECKBOX,
      items: ['Key Recommended Actions', 'Key Finding', 'Priority Actions'],
    },
  },
  {
    name: 'IMMEDIATE OUTCOME',
    children: [
      {
        name: 'Immediate Outcome 1',
        children: {
          items: [
            'Key Findings',
            'Recommended Actions',
            'Core Issue 1.1',
            'Core Issue 1.2',
            'Core Issue 1.3',
            'Core Issue 1.4',
            'Core Issue 1.5',
            'Core Issue 1.6',
          ],
        },
      },
      {
        name: 'Immediate Outcome 2',
        children: {
          items: [
            'Key Findings',
            'Recommended Actions',
            'Core Issue 1.1',
            'Core Issue 1.2',
            'Core Issue 1.3',
            'Core Issue 1.4',
            'Core Issue 1.5',
            'Core Issue 1.6',
          ],
        },
      },
    ],
  },
]

// MERs & FURs
export const typeOfReport: { name: string; value: string }[] = [
  { name: 'merFurList.menu.reportType.mer', value: 'MER' },
  { name: 'merFurList.menu.reportType.fur', value: 'FUR' },
]
export const languages: { name: string; value: string }[] = [
  { name: 'merFurList.menu.language.english', value: 'en' },
  { name: 'merFurList.menu.language.spanish', value: 'es' },
]
export const status: { name: string; value: string }[] = [
  { name: 'merFurList.menu.status.draft', value: 'DRAFT' },
  { name: 'merFurList.menu.status.published', value: 'PUBLISHED' },
  { name: 'merFurList.menu.status.unpublished', value: 'UNPUBLISHED' },
]

export const MERInformation: any[] = [
  {
    name: 'roadmap',
    code: 'roadmap',
    items: [
      {

        element: 'Key Recommended Actions',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'keyRecommended',
        visibleWithMethodology: [METHODOLOGY_2022]
      },
    ],
    visibleWithMethodology: [METHODOLOGY_2022]
  },
  {
    name: 'executive-summary',
    code: 'executiveSummary',
    items: [
      {

        element: 'Key Findings',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'keyFindings',
        visibleWithMethodology: [METHODOLOGY_2000]
      },
      {

        element: 'Priority Actions',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'priorityActions',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
    ],
    visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
  },
  {
    name: 'specific-characteristics',
    code: 'specificCharacteristics',
    items: [
      {

        element: 'Population',
        code: 'population',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Population Source',
        code: 'populationSource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'GDP',
        code: 'gdp',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'GDP Source',
        code: 'gdpSource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'International Financial Center',
        code: 'intlFinancialCenter',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'International Financial Center Source',
        code: 'intlFinancialCenterSource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Broad Money',
        code: 'broadMoney',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Broad Money Source',
        code: 'broadMoneySource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Informal Economy',
        code: 'informalEconomy',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Informal Economy Source',
        code: 'informalEconomySource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Number of banking Institutions',
        code: 'bankingInstitutions',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Number of banking Institutions Source',
        code: 'bankingInstitutionsSource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Level of TF risk',
        code: 'tfRisks',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Level of TF risk Source',
        code: 'tfRisksSource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'High Income Country',
        code: 'highIncomeCountry',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'High Income Country Source',
        code: 'highIncomeCountrySource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Less Developed Country',
        code: 'lessDevelopCountry',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Less Developed Country Source',
        code: 'lessDevelopCountrySource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'High VA materiality',
        code: 'highVaMateriality',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'High VA materiality Source',
        code: 'highVaMaterialitySource',
        elementType: ChildrenTypeEnum.INPUT,
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
    ],
    visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
  },
  {
    name: 'chapter1',
    code: 'chapterOne',
    items: [
      {

        element: 'Risks And General Situation',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'risksAndGeneralSituation',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'ML/TF Risks And Scoping Of Higher-Risk Issues',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'risksAndScopeHighIssues',
        visibleWithMethodology: [METHODOLOGY_2022]
      },
      {

        element: 'Country’s Risk Assessment & Scoping Of Higher Risk Issues',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'countryRisksAndScopeHighIssues',
        visibleWithMethodology: [METHODOLOGY_2022]
      },
      {

        element: 'Overview Risks',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'overviewRisks',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Materiality',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'materiality',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Structural Elements',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'structuralElements',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Contextual Factors',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'contextualFactors',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'AML/CFT Strategy Overview',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'amlCftStrategyOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Overview of the legal & institutional framework',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'legalFrameworkOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Overview of financial sector and DNFBPs',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'financialSectorOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Overview of preventive measures',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'preventiveMeasuresOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Overview of legal persons and arrangements',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'legalPersonsOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
      {

        element: 'Overview of supervisory arrangements',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'supervisorArrangementOverview',
        visibleWithMethodology: [METHODOLOGY_2000]
      },
      {

        element: 'International cooperation description',
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'intlCooperationOverview',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      },
    ],
    visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
  },
]

export const effectivenessMerItems = (elements: number): {
  element: string
  elementType?: ChildrenTypeEnum
  options?: any[]
  code: string
  visibleWithMethodology: string[],
}[] => {
  const aux: any[] = [
    { element: 'Key Findings', elementType: ChildrenTypeEnum.TEXTAREA, code: 'keyFindings', visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022] },
    { element: 'Key Recommended Actions (KRA)', elementType: ChildrenTypeEnum.TEXTAREA, code: 'recommendedActions', visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022] },
    { element: 'Other Recomended Actions', elementType: ChildrenTypeEnum.TEXTAREA, code: 'otherRecommendedActions', visibleWithMethodology: [METHODOLOGY_2022] },
  ]
  for (let index = 1; index <= elements; index++) {
    aux.push({ element: `Core Issue ${index}`, elementType: ChildrenTypeEnum.TEXTAREA, code: 'coreIssue', visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022] })

  }
  aux.push(
    { element: 'Conclusion', elementType: ChildrenTypeEnum.TEXTAREA, code: 'conclusion', visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022] },
    {
      element: 'Assessment',
      code: 'assessmentId',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      options: [
        {
          value: 'HE',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'SE',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'LE',
          elementType: ChildrenTypeEnum.RADIO,
        },
      ],
    })

  return aux
}

export type EffectivenessMERItem = {
  element: string
  options?: { elementType: string; value: string }[]
  elementType?: string
  code: string
  visibleWithMethodology: string[],
}

export const effectivenessMER: {
  name: string
  description: string
  code: string
  items: EffectivenessMERItem[]
  visibleWithMethodology: string[],
  key?: string
}[] = [
    // 1
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(6),
      description: 'Risk, policy and coordination',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome01'
    },
    // 2
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(5),
      description: 'International cooperation',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000],
      key: 'immediateOutcome02'
    },
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'International cooperation',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2022],
      key: 'immediateOutcome02'
    },

    // 3
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(6),
      description: 'Supervision',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome03'
    },
    // 4
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(6),
      description: 'Preventive measures',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome04'
    },
    // 5
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(6),
      description: 'Legal persons and arrangements',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000],
      key: 'immediateOutcome05'
    },
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(5),
      description: 'Legal persons and arrangements',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2022],
      key: 'immediateOutcome05'
    },
    // 6
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'Financial intelligence ML/TF',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome06'
    },
    // 7
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(5),
      description: 'ML investigation and prosecution',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000],
      key: 'immediateOutcome07'
    },
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'ML investigation and prosecution',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2022],
      key: 'immediateOutcome07'
    },
    // 8
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'Confiscation',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome08'
    },
    // 9
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(5),
      description: 'TF investigation and prosecution',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      key: 'immediateOutcome09'
    },
    // 10
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'TF preventive measures and financial sanctions',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000],
      key: 'immediateOutcome10'
    },
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(5),
      description: 'TF preventive measures and financial sanctions',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2022],
      key: 'immediateOutcome10'
    },
    // 11
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(4),
      description: 'PF financial sanctions',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2000],
      key: 'immediateOutcome11'
    },
    {
      name: 'immediate-outcome',
      items: effectivenessMerItems(6),
      description: 'PF financial sanctions',
      code: 'immediateOutcome',
      visibleWithMethodology: [METHODOLOGY_2022],
      key: 'immediateOutcome11'
    },
  ]


export const technicalComplianceMERItems = (elements: number, parentIndex: number = null, childrenName: string = null): EffectivenessMERItem[] => {

  const aux: any[] = [
    { element: 'Introduction', elementType: ChildrenTypeEnum.TEXTAREA, code: 'introduction' }
  ]

  for (let index = 0; index < elements; index++) {
    if (parentIndex && (index === parentIndex + 1)) {
      aux.push({
        element: 'Description criterion ' + index + 1 + ' ' + childrenName,
        elementType: ChildrenTypeEnum.TEXTAREA,
        code: 'criterion',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
        parent: parentIndex,
        childrenName
      })
    }
    aux.push({
      element: 'Description criterion ' + index,
      elementType: ChildrenTypeEnum.TEXTAREA,
      code: 'criterion',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
    })
  }

  aux.push(
    { element: 'Weighting And Conclusion', elementType: ChildrenTypeEnum.TEXTAREA, code: 'weightingAndConclusion' },
    {
      element: 'Assessment', code: 'assessmentId',
      visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      options: [
        {
          value: 'C',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'LC',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'PC',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'NC',
          elementType: ChildrenTypeEnum.RADIO,
        },
        {
          value: 'NA',
          elementType: ChildrenTypeEnum.RADIO,
        },
      ],
    })
  return aux
}

const recommendationCriterion = {
  1: 12, 2: 5, 3: 11, 4: 4,
  5: 10, // se agrega el 5.2bis
  6: 7, 7: 5, 8: 6, 9: 1, 10: 20, 11: 4, 12: 4, 13: 3, 14: 5, 15: 11, 16: 18, 17: 3, 18: 3, 19: 3, 20: 2, 21: 2, 22: 5, 23: 4, 24: 15, 25: 8, 26: 6, 27: 4, 28: 5, 29: 8, 30: 5, 31: 4, 32: 11, 33: 1, 34: 1, 35: 2, 36: 2, 37: 8, 38: 4, 39: 4, 40: 20
}

const getTechnicalData = () => {

  const aux: { name: string; description?: string; items: EffectivenessMERItem[]; code: string, visibleWithMethodology: string[] }[] =
    []
  const keyDeficienciesItems: EffectivenessMERItem[] = []
  for (let index = 1; index <= 40; index++) {
    // const fixedIndex = index <= 9 ? `0${index}` : `${index}`
    let generatedItems: any
    keyDeficienciesItems.push(
      {
        element: `Recommendation ${index}`,
        elementType: ChildrenTypeEnum.TEXTAREA,
        // code: 'recommendation' + fixedIndex,
        code: 'recommendation',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022],
      })
    if (index === 5) { // solo para recomendacion 5 que tiene children (bis)
      generatedItems = technicalComplianceMERItems(recommendationCriterion[index], 1, 'bis')
    } else { // generacion comun de criterios sin children
      generatedItems = technicalComplianceMERItems(recommendationCriterion[index])
    }
    aux.push(
      {
        name: 'recommendation',
        items: generatedItems,
        code: 'recommendation',
        visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022]
      }
    )
    if (index === 40) aux.push({ name: 'keyDeficiencies', items: keyDeficienciesItems, code: 'keyDeficiencies', visibleWithMethodology: [METHODOLOGY_2000, METHODOLOGY_2022] })
  }
  return aux
}
export const technicalComplianceMER: {
  visibleWithMethodology: any[],
  name: string,
  description?: string,
  items: EffectivenessMERItem[],
  code: string
}[] = getTechnicalData()

export const getMerInformationForm = (): {
  executiveSummary: any
  specificCharacteristics: any
  chapterOne: any
} => {
  return MERInformation.reduce((acc, el) => {
    const items: any[] = el.items
    const nested = items.reduce((accN, elN) => {
      accN[elN.code] = ''
      return accN
    }, {})
    acc[el.code] = nested
    return acc
  }, {})
}

export const getEffectivenessForm = (methodology) => {
  return effectivenessMER.filter(e => e.visibleWithMethodology.find(v => v === methodology) !== undefined).reduce((acc, el, index) => {
    const fixedIndex = index < 9 ? `0${index + 1}` : `${index + 1}`
    const immediateOutcomeCode: string = `${el.code}${fixedIndex}`
    const coreIssues = el.items
      .filter((it) => it.code.includes('coreIssue'))
      .reduce((acc, el, ind) => {
        const CIIndex = `0${ind + 1}`
        const aux = {
          coreIssueId: null,
          // coreIssueOrder:CIIndex,
          coreIssueCode: `${el.code}${fixedIndex}${CIIndex}`,
          coreIssueDescription: '',
        }
        acc.push(aux)
        return acc
      }, [])

    const noCoreIssue = el.items
      .filter((it) => !it.code.includes('coreIssue'))
      .filter(it => it.visibleWithMethodology.find(vm => vm === methodology) !== undefined)
      .reduce((acc, el) => {
        acc[el.code] = ''
        return acc
      }, {})
    const final = Object.assign(
      {
        immediateOutcomeId: null,
        // code: immediateOutcomeCode,
      },
      noCoreIssue,
    )

    acc[immediateOutcomeCode] = { ...final, coreIssues }
    return acc
  }, {})
}

export const getTechnicalComplianceForm = (methodology): { technicalCompliance: { recommendations: any, keyDeficiencies: any } } => {

  const recommendations = technicalComplianceMER
    .filter(e => e.visibleWithMethodology.find(v => v === methodology) !== undefined)
    .filter(el => el.code.includes('recommendation'))
    .reduce((acc, el, index) => {
      const recommendationFixedIndex = index < 9 ? `0${index + 1}` : `${index + 1}`
      const recommendationCode = `${el.code}${recommendationFixedIndex}`
      const criterionItems = el.items.filter(el => el.code.includes('criterion'))
      const noCriterionItems = el.items.filter(el => !el.code.includes('criterion'))

      const aux = noCriterionItems.reduce((acc, el) => {
        acc[el.code] = ''
        return acc
      }, {})


      let i = 0
      const itemCriterions = criterionItems.reduce((acc, el: any) => {
        const criterionFixedIndex = i < 9 ? `0${i + 1}` : `${i + 1}`
        let criterionCode: string
        if (el.parent) {
          criterionCode = `${acc[el.parent].criterionCode}${el.childrenName}` // recupero el codigo del paddre y le agrego el prefijo del hijo. 'bis' para la recom 5
        } else {
          criterionCode = `${el.code}${recommendationFixedIndex}${criterionFixedIndex}` // genero el codigo estandar
          i++
        }
        const aux = {
          criterionId: null,
          criterionCode: criterionCode,
          criterionDescription: el?.childrenName ?? null,
        }
        acc.push(aux)

        return acc
      }, [])

      acc[recommendationCode] = {
        recommendationId: null,
        recommendationCode,
        ...aux,
        criterias: itemCriterions
      }
      return acc

    }, {})

  const keyDeficienciesRecommendations = technicalComplianceMER.filter(r => r.code === 'keyDeficiencies')[0].items
    .reduce((acc, el) => {
      acc.push({
        recommendationId: null,
        recommendationCode: el.code,
        keyDeficiencies: '',
      })

      return acc
    }, [])

  return {
    technicalCompliance: { recommendations, keyDeficiencies: { recommendations: keyDeficienciesRecommendations } }
  }
}

export const getRecommendationsList = (methodology) => {
  const { recommendations } = getTechnicalComplianceForm(methodology).technicalCompliance
  const recommendationsList: { code: string, name: string, key: string, index: number }[] = []
  let index = 1
  for (const prop in recommendations) {
    recommendationsList.push({
      code: prop,
      name: 'Recommendation ' + index,
      key: 'recommendation',
      index
    })
    index++
  }

  return recommendationsList
}
