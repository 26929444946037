import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ReportIcon } from '../../../../assets/icons/icono-report.svg'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import './pivot-table.css';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { aggregators } from 'react-pivottable/Utilities'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../contexts/language-context';
import { useData } from '../../../../components/hooks/useData';
import LoadingComponent from '../../../../components/common/loading/loading';
import { ConsolidatedRatingEnum } from '../../../../enums/mer-status.enum';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: any
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, ...style }} >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function PivotTablePage() {
  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()

  const [value, setValue] = useState(0)
  const [effectivenessData, setEffectivenessData] = useState<any[]>([])
  const [technicalComplianceData, setTechnicalComplianceData] = useState<any[]>([])
  const [loading, error, data] = useData<any>(`reporting/${appLanguage}/get-mers`, 'GET')
  const pivotTableDefaultState = {
    valueFilter: {
      Assesment: {},
      Country: {},
      Type: {},
      Object: {},
      ['Population']: {},
      GDP: {},
      ['International Finance Center']: {},
      ['Broad Money']: {},
      ['Informal Economy']: {},
      ['Number of Banking Institutions']: {},
      ['Level of TF risk']: {},
      ['High Income Country']: {},
      ['Less Developed Country']: {},
      ['High VA Materiality']: {}
    }
  }
  const [settingsOne, setSettingsOne] = useState(pivotTableDefaultState)
  const [settingsTwo, setSettingsTwo] = useState(pivotTableDefaultState)

  const [PlotlyRenderers] = useState(createPlotlyRenderers(Plot))
  const InteractivePlot = useCallback((props: any) => {
    const auxProps = Object.assign({}, props);
    const strColors = process.env['REACT_APP_CHARTS_COLORS'];
    const colors = strColors ? strColors.split(',') : [];

    const a = (new PlotlyRenderers[auxProps.rendererName]);
    a.props = props;
    const aux = a.render();

    let idx = 0;
    switch (auxProps.rendererName) {
      case 'Grouped Column Chart':
      case 'Stacked Column Chart':
        for (const i in aux['props'].data) {
          if (colors.length > idx) {
            aux['props'].data[i]['marker'] = {
              color: colors[idx],
            };
          }
          idx++;
        }

        break;
      case 'Grouped Bar Chart':
      case 'Stacked Bar Chart':
        for (const i in aux['props'].data) {
          aux['props'].data[i]['marker'] = {
            color: colors,
          };
        }
        break;
      case 'Multiple Pie Chart':
        for (const i in aux['props'].data) {
          aux['props'].data[i]['marker'] = {
            colors,
          };
        }
        break;
        break;
    }
    return aux;
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }
  const rendererItems = {
    ['Table']: TableRenderers.Table,
    ['Grouped Column Chart']: InteractivePlot,
    ['Stacked Column Chart']: InteractivePlot,
    ['Grouped Bar Chart']: InteractivePlot,
    ['Stacked Bar Chart']: InteractivePlot,
    ['Multiple Pie Chart']: InteractivePlot,

  }
  const aggregatorsItems = { ['Count']: aggregators['Count'] }

  useEffect(() => {
    setSettingsOne(pivotTableDefaultState)
    setSettingsTwo(pivotTableDefaultState)
    if (data) {
      setEffectivenessData(() => {
        return data.filter(el => el.element === 'immediateOutcome').map(element => {
          const aux = {}
          for (const key in element) {
            const trad = key === 'elementTitle' ? ('reports.immediateOutcome') : ('reports.' + key)
            aux[t(trad)] = element[key]
          }
          return aux
        })
      })

      setTechnicalComplianceData(() => {
        return data.filter(el => el.element === 'recommendation').map(element => {
          const aux = {}
          for (const key in element) {
            const trad = key === 'elementTitle' ? ('reports.recommendation') : ('reports.' + key)
            aux[t(trad)] = element[key]
          }
          return aux
        })
      })
    }
  }, [loading, appLanguage])

  return (
    <>
      <div className='progress-bar lato' >
        <div className='description-wrapper'>
          <ReportIcon />
          <div>
            <span>{t('reports.title')}</span>
            <div style={{ color: '#5ECDE9', fontSize: '22px' }}>{t('reports.subtitle')}</div>
          </div>
        </div>
      </div>
      <div className='row'>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', borderTop: 1, borderTopColor: 'divider', background: 'white' }}>
            <Tabs
              value={value} onChange={handleChange} aria-label='basic tabs example'>
              <Tab
                label={t('update-mer.menu.effectiveness.title')}
                className='tab-panel-title'
                {...a11yProps(0)}
                style={value === 0 ? { textTransform: 'none', borderBottom: '2px solid #426BBA' } : { textTransform: 'none' }}
              />
              <Tab
                label={t('update-mer.menu.technicalCompliance.title')}
                className='tab-panel-title'
                {...a11yProps(1)}
                style={value === 1 ? { textTransform: 'none', borderBottom: '2px solid #426BBA' } : { textTransform: 'none' }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0} style={{ background: 'white', height: '750px' }}>
            {!loading ?
              <div className='col-12 pivot-table'>
                <PivotTableUI
                  data={effectivenessData}
                  onChange={(s) => setSettingsOne(s)}
                  hiddenAttributes={['reports.element']}
                  aggregators={aggregatorsItems}
                  renderers={rendererItems}
                  {...settingsOne}
                />
              </div>
              : <LoadingComponent />}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} style={{ background: 'white', height: '750px' }}>
            {!loading ?
              <div className='pivot-table'>
                <PivotTableUI
                  data={technicalComplianceData}
                  onChange={(s) => setSettingsTwo(s)}
                  hiddenAttributes={['reports.element']}
                  aggregators={aggregatorsItems}
                  renderers={rendererItems}
                  {...settingsTwo}
                />
              </div>
              : <LoadingComponent />}
          </CustomTabPanel>

        </Box>
      </div >
    </>
  )
}
