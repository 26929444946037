import { createContext, useContext, useEffect, useState } from 'react'

import { Country } from '../model/models-module'
import DefaultDataService from '../services/default-data.service'
import UserService from '../services/UserService'
import { useLanguage } from './language-context'
import { useErrorContext } from './error-context'

interface DefaultDataContextProps {
  children: React.ReactNode
}

interface DefaultDataContextInterface {
  countries: Country[]
  assessmentBodies: any[]
  rounds: any[]
  merFurYears: any[]
  specificCharacteristics: any[]
  loading: boolean
  error: any
}

export const DefaultDataContext = createContext<DefaultDataContextInterface>({
  countries: null,
  assessmentBodies: null,
  rounds: null,
  merFurYears: null,
  specificCharacteristics: null,
  loading: false,
  error: null,
})

export const useDefaultData = () => useContext(DefaultDataContext)

export const DefaultDataProvider = ({ children }: DefaultDataContextProps) => {
  const { language: appLanguage } = useLanguage()
  const [loading, setLoading] = useState<boolean>(false)
  const [countries, setCountries] = useState<Country[]>(null)
  const [rounds, setRounds] = useState<Country[]>(null)
  const [error, setError] = useState<any>(null)
  const [assessmentBodies, setAssessmentBodies] = useState<Country[]>(null)
  const [merFurYears, setMerFurYears] = useState<Country[]>(null)
  const [specificCharacteristics, setSpecificCharacteristics] = useState<any[]>(null)
  // const [merYears, setMerYears] = useState<Country[]>(null)
  // const [countriesLoading, setCountriesLoading] = useState<boolean>(false)
  const { setError: setContextError } = useErrorContext()
  const defaultService = DefaultDataService.getInstance(setContextError)

  useEffect(() => {
    if (!UserService.isLoggedIn()) return

    setLoading(true)
    Promise.all([
      defaultService.findAll(appLanguage, 'assessment-bodies'),
      defaultService.findAll(appLanguage, 'rounds'),
      defaultService.findAll(appLanguage, 'mers-furs-years'),
      defaultService.findAll(appLanguage, 'countries'),
      defaultService.findAll(appLanguage, 'specific-characteristics-options'),
    ])
      .then(([assessments, rounds, merFurYears, countries, specificChar]) => {
        if (assessments && rounds && merFurYears && countries) {
          setCountries(countries)
          setAssessmentBodies(assessments)
          setRounds(rounds)
          setMerFurYears(merFurYears)
          setSpecificCharacteristics(specificChar)
          // setLoading(false)
        }
      })
      .catch((e) => {
        setError(e)
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
        // setCountriesLoading(false)
      })
  }, [appLanguage, UserService.isLoggedIn()])

  return (
    <DefaultDataContext.Provider
      value={{
        countries,
        assessmentBodies,
        merFurYears,
        rounds,
        specificCharacteristics,
        loading,
        error,
      }}
    >
      {children}
    </DefaultDataContext.Provider>
  )
}
