import BaseService, { FindAllType } from './BaseService'


export type UpdateFileBody = { workingGroupFile?: string, coChairsFile?: string, plenaryFile?: string }
export default class DiscussionService extends BaseService {
  private static _instance: DiscussionService
  baseEndpoint = 'discussions'

  public static getInstance(setError: (message: string | null) => void): DiscussionService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async findAll({ lang, args = '', limit = 10, offset, sort }: FindAllType) {
    try {
      const url = `${lang}/${this.baseEndpoint}/grid`
      return await this.baseFindAll({ lang, args, limit, offset, sort }, url)

    } catch (err: any) {
      throw await this.handleError(err)
    }
  }


  async findDiscussionTranslateById({ lang, discussionTransId, appLanguage }: { lang: string, discussionTransId: number, appLanguage: string }) {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${discussionTransId}?lang=${lang}`, method: 'GET' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

  async findKeyIssuesByDiscussionId({ lang, appLanguage, discussionTransId: discussionId }: { lang: string, appLanguage: string, discussionTransId: number }): Promise<any[]> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${discussionId}/key-issues?localize=${lang}`, method: 'GET' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

  async createDiscussion(appLanguage: string, data: any) {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/discussions`, method: 'POST', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async createKeyIssue(appLanguage: string, discussionId: number, data: any) {
    try {
      delete data.auxEl;
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${discussionId}/key-issues`, method: 'POST', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateKeyIssue(appLanguage: string, discussionId: number, keyIssueId: number, data: any) {
    try {
      delete data.auxEl;
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${discussionId}/key-issues/${keyIssueId}`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async deleteKeyIssue(appLanguage: string, discussionId: number, keyIssueId: number) {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${discussionId}/key-issues/${keyIssueId}`, method: 'DELETE' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async deleteDiscussionT(appLanguage: string, id: any, lang: any) {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/discussions/${id}?lang=${lang}`, method: 'DELETE' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async findElementList(appLang: string, element: string): Promise<any> {
    try {
      const result = await this.client({ url: `${appLang}/discussions/find-elements?element=${element}`, method: 'GET' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data.data
    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

  async downloadPDF(appLanguage: string, id: number, lang: string, urlFile: any): Promise<any> {
    try {
      const result = await this.client({ url: `${appLanguage}/discussions/${id}/file/${lang}?urlfile=${urlFile}`, method: 'GET', responseType: 'blob' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

  async updatePDF(appLanguage: string, id: number, lang: string, data: UpdateFileBody): Promise<boolean> {
    try {
      const result = await this.client({ url: `${appLanguage}/discussions/${id}/file/${lang}`, method: 'PUT', data })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }

  }


}
