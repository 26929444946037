import './step-one.css'

import { ComapisonToolDataTableDTO, GridDTO } from '../../../model/dto-module'
import ComparisonToolFiltersService, {
  FilterServiceEnum,
} from '../../../services/comparison-tool.service'
import { CodeDescription, TableDetailCell, TableHeaderCell } from '../../../model/models-module'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ChildrenTypeEnum, languages } from '../../../configs/data'
import { ReactComponent as CollapseIcon } from './../../../assets/icons/minus-icon.svg'
import { ReactComponent as CollapseIconDefault } from '../../../assets/icons/collapse-icon.svg'
import { ReactComponent as ExpandIconDefault } from '../../../assets/icons/expand-icon.svg'
import CustomFlag from '../../../components/common/flag/flag'
import DataTable from '../../../components/common/datatable/Datatable'
import { useLanguage } from '../../../contexts/language-context'
import { useTranslation } from 'react-i18next'
import LoadingComponent from '../../../components/common/loading/loading'
import { useDefaultData } from '../../../contexts/default-data-context'
import ExpandIcon from '../../../components/common/icon/plus-icon/plus-icon'
import CustomAccordionFilter, { AccordionGroupData } from '../../../components/common/accordionV2/accordion-v2'
import { useErrorContext } from '../../../contexts/error-context'
import { parseParams } from '../../../utils/CommonUtils'

interface Props {
  canChangeStep: any
  setCanChangeStep: any
  setRound: any
  selectedRows?: any
  setSelectedRows: any
  handleContinue: any
  cleanSelectedFilters: boolean
  setCleanSelectedFilters: any
}

const ComparisonStepOne: React.FC<Props> = ({
  canChangeStep,
  setCanChangeStep,
  setRound,
  selectedRows,
  setSelectedRows,
  handleContinue,
  cleanSelectedFilters,
  setCleanSelectedFilters,
}: Props) => {
  // Menu filters
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [filtersData, setFiltersData] = useState<Map<string, any>>(new Map<string, any>())
  const [filtersDataSelected, setFiltersDataSelected] = useState<Map<string, any>>(new Map<string, any>())
  const [filtersInitData, setFiltersInitData] = useState<Map<string, any>>(undefined)
  const [rowSelection, setRowSelection] = useState<Map<any, any>>(selectedRows);
  const [initialDataChecked, setInitialDataChecked] = useState<Map<any, any>>();
  const [loading, setLoading] = useState<boolean>(true);

  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [dataTo, setDataTo] = useState<number>(10)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [sort] = useState<Map<string, string>>(new Map())
  const [filter] = useState<Map<string, string>>(new Map())
  const [initialized, setInitialized] = useState<boolean>(false)
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [dataGrid, setDataGrid] = useState<GridDTO<ComapisonToolDataTableDTO>>();
  const { countries, assessmentBodies, rounds, loading: defaultDataLoading } = useDefaultData()
  const [page, setPage] = useState<number>(1)
  const { language } = useLanguage()
  const { t } = useTranslation('global')
  const [accordionData, setAccordionData] = useState<AccordionGroupData[]>();
  const { setError: setContextError } = useErrorContext()
  const comparisonToolFiltersService = ComparisonToolFiltersService.getInstance(setContextError)
  const [enabledButton, setEnabledButton] = useState<boolean>(true)


  const initFilter = useCallback((filters, optMenu, filtersCache) => {
    if (Array.isArray(filters)) {
      if (filtersCache[optMenu] && filtersCache[optMenu].length > 0) {
        filter.set(optMenu, `${optMenu}:${filters
          .filter(r => filtersCache[optMenu].find(a => a === r.description))
          .map((r: CodeDescription) => r.code).join(':')}`)
      }
    } else {
      filters.forEach((v, k) => {
        if (filtersCache[optMenu] && filtersCache[optMenu][k]) {
          filter.set(k, `${k}:${v
            .filter(r => filtersCache[optMenu][k].find(a => a === r.description))
            .map((r: CodeDescription) => r.code).join(':')}`)
        }
      })
    }
  }, []);

  useEffect(() => {
    setFiltersLoaded(false)
    setInitialized(false)
    sort.clear()


    const lsFilters = localStorage.getItem('CTStep1Filters')
    let filtersObj = null
    if (!lsFilters) {
      filtersObj = { lang: language }
      localStorage.setItem('CTStep1Filters', JSON.stringify(filtersObj))
    } else {
      filtersObj = JSON.parse(lsFilters)
    }

    if (cleanSelectedFilters || !filtersObj['lang'] || filtersObj['lang'] !== language) {
      filtersData.forEach((values, key) => {
        filtersData.set(key, Array.isArray(values) ? [] : {})
      })
      filtersObj = { lang: language }
      localStorage.setItem('CTStep1Filters', JSON.stringify(filtersObj))
      localStorage.setItem('CTStep2Round4Comparables', JSON.stringify(filtersObj))
      setCleanSelectedFilters(false)
      setLoading(true)
      filter.clear()
      if (filtersDataSelected) filtersDataSelected.clear()
      if (filtersInitData) filtersInitData.clear()
      selectedRows.clear()
    }
    if (!defaultDataLoading) {
      Promise.all([
        comparisonToolFiltersService.filter(FilterServiceEnum.ASSESSMENT_BODY, null, assessmentBodies),
        comparisonToolFiltersService.filter(FilterServiceEnum.ROUND, null, rounds),
        comparisonToolFiltersService.filter(FilterServiceEnum.DATE, language),
        comparisonToolFiltersService.filter(FilterServiceEnum.SPECIFIC_CHARACTERISTICS, language),
        comparisonToolFiltersService.filter(FilterServiceEnum.COUNTRY, null, countries),
      ]).then((results) => {
        if (results.includes(undefined)) return

        const lsFilters = localStorage.getItem('CTStep1Filters');
        const filtersObj = JSON.parse(lsFilters);
        const dataLang = languages.map((r, i) => {
          return new CodeDescription(i, r.value, t(r.name))
        })
        setAccordionData([
          { customKey: 'assessment_body', parentDictionaryKey: 'comparison-tool.step-1.menu.assessment-body', summary: t('comparison-tool.step-1.menu.assessment-body'), elementType: ChildrenTypeEnum.CHECKBOX, data: results[0], collapseIcon: CollapseIconDefault, expandIcon: ExpandIconDefault },
          { customKey: 'round', parentDictionaryKey: 'comparison-tool.step-1.menu.round', summary: t('comparison-tool.step-1.menu.round'), elementType: ChildrenTypeEnum.RADIO, data: results[1], collapseIcon: CollapseIconDefault, expandIcon: ExpandIconDefault },
          { customKey: 'date', parentDictionaryKey: 'comparison-tool.step-1.menu.date', summary: t('comparison-tool.step-1.menu.date'), elementType: ChildrenTypeEnum.CHECKBOX, data: results[2], collapseIcon: CollapseIconDefault, expandIcon: ExpandIconDefault },
          { customKey: 'specific_characteristics', parentDictionaryKey: 'comparison-tool.step-1.menu.specific-characteristics', summary: t('comparison-tool.step-1.menu.specific-characteristics.title'), elementType: ChildrenTypeEnum.CHECKBOX, expandIcon: () => <ExpandIcon />, collapseIcon: CollapseIcon, data: results[3] },
          { customKey: 'country', parentDictionaryKey: 'comparison-tool.step-1.menu.country', summary: t('comparison-tool.step-1.menu.country'), elementType: ChildrenTypeEnum.CHECKBOX, data: results[4], collapseIcon: CollapseIconDefault, expandIcon: ExpandIconDefault },
          { customKey: 'lang', parentDictionaryKey: 'comparison-tool.step-1.menu.language', summary: t('comparison-tool.step-1.menu.language'), elementType: ChildrenTypeEnum.CHECKBOX, data: dataLang, collapseIcon: CollapseIconDefault, expandIcon: ExpandIconDefault },
        ])

        initFilter(results[0], 'assessment_body', filtersObj);
        initFilter(results[1], 'round', filtersObj);
        initFilter(results[2], 'date', filtersObj);
        initFilter(results[3], 'specific_characteristics', filtersObj);
        initFilter(results[4], 'country', filtersObj);
        // initFilter(dataLang, 'lang', filtersObj);

        const auxMap = new Map();
        Object.keys(filtersObj).forEach((k) => {
          if (k !== 'lang')
            auxMap.set(k, filtersObj[k]);
        })
        if (!auxMap.has('round')) {
          auxMap.set('round', [results[1][0].code]);
          filtersObj['round'] = [results[1][0].code];
        }
        setRound(+auxMap.get('round')[0])
        setFiltersInitData(auxMap);

        setFiltersData((prevState) => {
          prevState.clear()
          prevState.set('assessment_body', results[0])
          prevState.set('round', results[1])
          prevState.set('date', results[2])
          prevState.set('specific_characteristics', results[3])
          prevState.set('country', results[4])
          return prevState
        })

        setFiltersLoaded(true);
        setInitialized(true);
        setFiltersDataSelected(auxMap);
        findDataGrid({ params: auxMap, lang: language, calledBy: 'Init' });
      })
    }
  }, [defaultDataLoading])

  const findDataGrid = useCallback(({ params = filtersDataSelected, lang = language, limit = dataTo, offset = dataOffset, pSort = sort, withSelection = true, calledBy = 'unknow' }: { params?: Map<string, string[]>, lang?, limit?, offset?, pSort?, withSelection?, calledBy?} = { params: filtersDataSelected, limit: dataTo, offset: dataOffset, pSort: sort, withSelection: true }) => {
    // console.log('Invocado desde ', calledBy);
    setLoading(true);
    // console.log('entra con estos params: ', params, filtersDataSelected);
    // Params
    const args = parseParams(params);
    localStorage.setItem('CTStep1Filters', JSON.stringify({ params: Object.fromEntries(params), limit: limit, offset: offset }));
    // Sort
    let sortArgs = ''
    if (pSort.size > 0) {
      pSort.forEach((value) => {
        if (sortArgs === '') {
          sortArgs += value
        } else {
          sortArgs += `,${value}`
        }
      })
    }

    comparisonToolFiltersService.findDataGrid({ args, lang: lang ?? language, limit, offset, sort: sortArgs })
      .then((data) => {
        setDataGrid(data);
        const cells = data.results.map((o) => {
          const cell = new TableDetailCell(
            ComapisonToolDataTableDTO.fromData(language, o),
            [],
            new Map<string, React.ReactNode>([
              ['countryName', <CustomFlag key={String(o.merId)} code={o.countryCode} flagName={o.countryName} />],
            ]),
          )
          return cell
        })
        const checked = new Map();
        if (withSelection) {
          cells.forEach((ds, idx) => {
            if (rowSelection.has(ds.data.mertId)) {
              checked.set(idx, ds);
            }
          });
        }
        setInitialDataChecked(checked);
        setCanChangeStep(rowSelection.size >= 1)
        setEnabledButton(rowSelection.size >= 1);
        setDataShow(cells);
      })
      .finally(() => {
        setLoading(false)
      })


  }, [filtersDataSelected, language, dataTo, dataOffset, sort, initialized]);

  useEffect(() => {
    if (filtersLoaded) {
      setDataHeader([
        new TableHeaderCell({
          label: t('comparison-tool.step-1.table.columns.country'),
          key: 'countryName',
          sorteable: true,
        }),
        new TableHeaderCell({
          label: t('comparison-tool.step-1.table.columns.language'),
          key: 'merLanguage',
          sorteable: true,
        }),
        new TableHeaderCell({
          label: t('comparison-tool.step-1.table.columns.assessment-body'),
          key: 'assessmentBodyName',
          sorteable: true,
        }),
        new TableHeaderCell({
          label: t('comparison-tool.step-1.table.columns.round'),
          key: 'merRound',
          sorteable: true,
          headerStyle: { minWidth: '100px', textAlign: 'center' },
        }),
        new TableHeaderCell({
          label: t('comparison-tool.step-1.table.columns.date'),
          key: 'merDate',
          sorteable: true,
        }),
      ])
    }
  }, [language, filtersLoaded])

  const handleRowSelected = useCallback((row: TableDetailCell) => {
    // console.log('Entra al select ')
    const auxMap = rowSelection;
    if (auxMap.has(row.data.mertId)) {
      auxMap.delete(row.data.mertId);
    } else {
      auxMap.set(row.data.mertId, row);
    }
    setRowSelection(auxMap);
    setSelectedRows(auxMap)
    setCanChangeStep(rowSelection.size >= 1)
    setEnabledButton(rowSelection.size >= 1);
    localStorage.setItem('CTStep2Round4Comparables', JSON.stringify({ lang: language }));
  }, [rowSelection, setSelectedRows])

  const handleAllRowsSelected = useCallback((rows: TableDetailCell[]) => {
    // console.log('Entra al select all')
    const auxMap = rowSelection;
    if (rows.length > 0) {
      rows.forEach((row) => {
        auxMap.set(row.data.mertId, row);
      })
    } else {
      dataShow.forEach((ds) => {
        if (auxMap.has(ds.data.mertId)) {
          auxMap.delete(ds.data.mertId);
        }
      })
    }
    setRowSelection(auxMap);
    setSelectedRows(auxMap)
    setCanChangeStep(auxMap.size > 1)
    setEnabledButton(auxMap.size > 1)
    localStorage.setItem('CTStep2Round4Comparables', JSON.stringify({ lang: language }));
  }, [rowSelection, dataShow, setSelectedRows])

  return (
    <>
      {filtersLoaded && filtersInitData &&
        <CustomAccordionFilter
          data={accordionData}
          initialValues={filtersInitData}
          onSelectableChange={(dataSelected) => {
            setFiltersDataSelected(dataSelected);
            setDataOffset(0);
            setPage(1);
            // Si aplica filtros a la tabla, limpio la seleccion de paises.
            setRowSelection(new Map());
            setCanChangeStep(false)

            const lsFilters = localStorage.getItem('CTStep1Filters')
            let filtersObj = null
            filtersObj = JSON.parse(lsFilters)

            dataSelected.forEach((v, k) => {
              filtersObj[k] = v;
              if (Array.isArray(v)) {
                if (v.length === 0) {
                  filter.delete(k);
                } else {
                  filter.set(`${k}`, `${k}:${v.join(':')}`)
                }
              }
            });

            localStorage.setItem('CTStep1Filters', JSON.stringify(filtersObj));
            findDataGrid({ params: dataSelected, offset: 0, withSelection: false, calledBy: 'Filtros' });
          }}
          withChips={true}
        />
      }
      <div className='page-content'>
        {loading ? (
          <LoadingComponent />
        ) : null}
        {!loading && filtersLoaded && (
          <div className='table'>
            <div className={'container-abm-page'}>
              <div className={'table-templates-container'}>
                <DataTable
                  headers={dataHeader}
                  rows={dataShow}
                  dataCheckedList={initialDataChecked}
                  countItems={dataGrid ? dataGrid.count : 0}
                  rowsPerPage={dataTo}
                  hasActions={false}
                  onSelectAllRows={handleAllRowsSelected}
                  onSelectRow={handleRowSelected}
                  appendOnScrollToBottom={false}
                  onPageSizeChange={(size) => {
                    setDataTo(size)
                    setDataOffset(0)
                    findDataGrid({ params: filtersDataSelected, limit: size, offset: 0, calledBy: 'Page size' });
                  }}
                  onPageChange={(newPage: number) => {
                    if (newPage !== page) {
                      setPage(newPage)
                      setDataOffset(newPage - 1);
                      findDataGrid({ params: filtersDataSelected, offset: newPage - 1, limit: dataTo, calledBy: 'Page change' });
                    }
                  }}
                  onSortChange={(key: string, order: string) => {
                    if (order === '') {
                      sort.delete(key)
                    } else {
                      sort.set(key, `${key}:${order}`)
                    }
                    setDataOffset(0);
                    setPage(1);
                    findDataGrid({ offset: 0, pSort: sort, calledBy: 'Sort' })
                  }}
                  pageSelected={page}
                  pageable={true}
                  selectable={true}
                  className=' thinner'
                ></DataTable>
                <div className='buttons-section d-flex' style={{ justifyContent: 'center' }} >
                  <button
                    onClick={handleContinue}
                    disabled={!enabledButton}
                    className={`${!enabledButton ? 'disabled-button' : 'blue-button '} basic-button mr-20  lato`}
                  >
                    {t('buttons.continue')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ComparisonStepOne
