import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLanguage } from '../../contexts/language-context'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../../components/common/progress-bar/progress-bar'
import { ReactComponent as ConsolidatedIcon } from '../../assets/icons/icono-rating.svg'
import { ReactComponent as XlsIcon } from '../../assets/icons/xls-icon.svg'
import { ReactComponent as ExpandIconDefault } from '../../assets/icons/expand-icon.svg'
import { ReactComponent as CollapseIconDefault } from '../../assets/icons/collapse-icon.svg'
import DefaultDataService from '../../services/default-data.service'
import { consolidateRatingsMenu } from '../../configs/consolidate-ratings-menu.config'
import { CodeDescription, ConsolidatedRatingsResponse, EffectivenessConclusion, TableDetailCell, TableHeaderInterface, TechnicalComplianceConclusion } from '../../model/models-module'
import classes from './consolidate-ratings.module.css'
import CalificationTable from '../../components/common/calificationTable/Calificationtable'
import { Box, Tab, Tabs } from '@mui/material'
import LoadingComponent from '../../components/common/loading/loading'
import { handleXlsxFile } from '../../utils/CommonUtils'
import RatingModal from './modal/rating-modal'
import ConsolidateRatingService from '../../services/consolidate-ratings.service'
import { ConsolidatedRatingEnum, LanguagesEnum } from '../../enums/mer-status.enum'
import CustomAccordionFilter from '../../components/common/accordionV2/accordion-v2'
import { ChildrenTypeEnum } from '../../configs/data'
import { useErrorContext } from '../../contexts/error-context'

export default function ConsolidateRatings() {
  const { language: appLanguage } = useLanguage()
  const { t } = useTranslation('global')
  const { setError: setContextError } = useErrorContext()
  const defaultDataService = DefaultDataService.getInstance(setContextError)
  const [consolidateRatingsService] = useState<ConsolidateRatingService>(ConsolidateRatingService.getInstance(setContextError));

  const [filtersData, setFiltersData] = useState<Map<string, string[]>>(new Map())
  const [filtersInitData, setFiltersInitData] = useState<Map<string, string[]>>(new Map())
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [consolidateRatingsAccordionMenu, setConsolidateRatingsAccordionMenu] = useState<any[]>(consolidateRatingsMenu)
  const [dataGrid, setDataGrid] = useState<any[]>([]);
  const [dataShow, setDataShow] = useState<TableDetailCell[] | undefined>([])
  const [dataHeader, setDataHeader] = useState<TableHeaderInterface[]>([])
  const [params] = useState<Map<string, string>>(new Map())
  const [endpointModal, setEndPointModal] = useState<string>(null)
  const [ratingClick, setRatingClick] = useState<any>(null)
  const [selectedCountry, setSelectedCountry] = useState<ConsolidatedRatingsResponse>(null)
  const [dataModal, setDataModal] = useState<any>(null);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [changingTab, setChangingTab] = useState<boolean>(false);

  const [value, setValue] = useState(ConsolidatedRatingEnum.EFFECTIVENESS)


  const handleChange = (event: React.SyntheticEvent, newValue: ConsolidatedRatingEnum) => {
    setValue(newValue)
    setChangingTab(true)
  }


  const [openModal, setOpenModal] = useState(null)

  const handleOpen = async (rating: any, merId: number) => {
    if (rating.recommendationCode) {
      consolidateRatingsService.getRatingEffectiveness(merId, rating.recommendationCode)
        .then((result) => {
          setLoadingModal(true)
          if (result.data) {
            setDataModal(result.data)
            setOpenModal(true)
            setRatingClick(rating)
          } else {
            setContextError('not found data')
            setLoadingModal(false)
          }
        })
        .finally(() => {
          setLoadingModal(false)
        })
    } else {
      consolidateRatingsService.getRatingTechnicalCompliance(merId, rating.immediateOutcomeCode)
        .then((result) => {
          setLoadingModal(true)
          if (result.data) {
            setDataModal(result.data)
            setOpenModal(true)
            setRatingClick(rating)
          } else {
            setContextError('not found data')
            setLoadingModal(false)
          }
        })
        .finally(() => {
          setLoadingModal(false)
        })
    }


  };


  const handleClose = () => {
    setOpenModal(null)
  }

  const renderResultsTable = useCallback(({ list, tabSelected }: { list?: ConsolidatedRatingsResponse[], tabSelected?: string } = { list: dataGrid, tabSelected: value }) => {
    return list?.map((el, index) => {
      const newItems = []
      if (tabSelected === ConsolidatedRatingEnum.TECHNICAL_COMPLIANCE) {
        el.technicalCompliances.forEach((techComp, i) => {
          const color = techComp.furId ? techComp.lastAssessmentColor : techComp.originalAssessmentColor
          const element = <span key={techComp.title} className={classes.ratingTableCell} style={{ background: color, border: techComp.furId != null ? '3px solid #4D4B4B' : null }} onClick={() => { handleOpen(techComp, el.merId); setSelectedCountry(el) }}>{techComp.furId != null ? techComp.lastAssessmentShortDesc : techComp.originalAssessmentShortDesc}</span>
          newItems.push([techComp.title, element])
        })
      } else if (tabSelected === ConsolidatedRatingEnum.EFFECTIVENESS) {
        el.effectiveness.forEach((effect, i) => {
          const color = effect.furId ? effect.lastAssessmentColor : effect.originalAssessmentColor
          const element = <span key={effect.title} className={classes.ratingTableCell} style={{ background: color, border: effect.furId != null ? '3px solid #4D4B4B' : null }} onClick={() => { handleOpen(effect, el.merId); setSelectedCountry(el) }}>{effect.furId != null ? effect.lastAssessmentShortDesc : effect.originalAssessmentShortDesc}</span>
          newItems.push([effect.title, element])
        })
      }
      return new TableDetailCell({ countryName: el.countryDesc }, null, new Map<string, React.ReactNode>(newItems),)
    })
  }, [dataGrid, value])

  useEffect(() => {
    if (value) {
      setChangingTab(false)
      const colStyle = {
        maxWidth: '230px',
        display: 'flex',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '18px',
        alignItems: 'center',
        color: '#003479',
        background: '#F1F1F1',
        borderRadius: '6px',
        paddingTop: '15px',
        paddingBottom: '15px',
      }

      const headerStyle = {
        minWidth: '230px',
        maxWidth: '230px',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '18px',
        color: '#003479',
        textAlign: 'left',
        paddingTop: '15px',
        paddingBottom: '15px',
        background: '#5ECDE9',
        borderRadius: '6px'
      }

      const calificationRow = {
        minWidth: '100px',
        maxWidth: '100px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '18px',
        color: '#003479',
        paddingTop: '15px',
        paddingBottom: '15px',
        background: '#5ECDE9',
        borderRadius: '6px'
      }

      if (!loadingData) {
        const newHeaders = [
          {
            label: t('consolidateRatings.table.country'),
            key: 'countryName',
            sorteable: false,
            headerStyle,
            colStyle
          }
        ]
        if (value === ConsolidatedRatingEnum.TECHNICAL_COMPLIANCE) {
          for (let index = 1; index <= 40; index++) {
            newHeaders.push({
              label: `R.${index}`,
              key: `R.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
        } else if (value === ConsolidatedRatingEnum.EFFECTIVENESS) {
          const iOCode = appLanguage === LanguagesEnum.EN ? 'IO' : 'RI'

          for (let index = 1; index <= 11; index++) {
            newHeaders.push({
              label: `${iOCode}.${index}`,
              key: `${iOCode}.${index}`,
              sorteable: false,
              headerStyle: calificationRow,
              colStyle
            })
          }
        }

        setDataHeader(newHeaders)
        setDataShow(renderResultsTable())
      }
    }
  }, [appLanguage, value, loadingData])

  useMemo(() => {

    Promise.all([
      defaultDataService.findAll(appLanguage, 'assessment-bodies'),
      defaultDataService.findAll(appLanguage, 'rounds'),
    ])
      .then(([assessments, rounds]) => {
        if (!assessments || !rounds) return

        // Obtener el último round
        // const lastRound = rounds[rounds.length - 1]?.roundCode;
        const fourRound = rounds.find(r => r.roundCode === 4)?.roundCode ?? 4
        if (!filtersInitData.has('round')) {
          params.set('round', fourRound);
          filtersInitData.set('round', [fourRound]);
        }
        if (!filtersData.has('round')) {
          filtersData.set('round', [fourRound]);
        }

        setConsolidateRatingsAccordionMenu([
          {
            customKey: 'assessment-bodies',
            parentDictionaryKey: 'discussion.menu.assessment-body',
            summary: t('discussion.menu.assessment-body'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: assessments.map(ab => new CodeDescription(ab.id, ab.assessmentBodyName, ab.assessmentBodyName))
          },
          {
            customKey: 'round',
            parentDictionaryKey: 'discussion.menu.round',
            summary: t('discussion.menu.round'),
            elementType: ChildrenTypeEnum.RADIO,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: rounds.map(r => new CodeDescription(r.id, r.roundCode, r.roundValue))
          }
        ]);

        findConsolidated({ params: filtersInitData });

        setLoadingData(false);
      })


  }, [appLanguage])

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function isTechnicalComplianceConclusion(
    variable: any
  ): variable is TechnicalComplianceConclusion {
    return (
      (variable as TechnicalComplianceConclusion).recommendationTranslations !== undefined
    );
  }

  // Función de guardia de tipo para EffectivenessConclusion
  function isEffectivenessConclusion(
    variable: any
  ): variable is EffectivenessConclusion {
    return (
      (variable as EffectivenessConclusion).immediateOutcomeTranslations !== undefined
    );
  }

  // Función para obtener la conclusión
  function renderConclusion() {
    if (isTechnicalComplianceConclusion(dataModal)) {
      const translation = dataModal.recommendationTranslations.find(
        (t) => t.lang === appLanguage
      );
      return translation
        ? translation.weightingAndConclusion
        : dataModal.recommendationTranslations[0].weightingAndConclusion;
    } else if (isEffectivenessConclusion(dataModal)) {
      const translation = dataModal.immediateOutcomeTranslations.find(
        (t) => t.lang === appLanguage
      );
      return translation
        ? translation.conclusion
        : dataModal.immediateOutcomeTranslations[0].conclusion;
    }
  }

  const buildParams = useCallback((params) => {
    let args = ''
    let round;
    params.forEach((value, key) => {
      if (value && value.length > 0) {
        if (key === 'round') {
          round = value[0];
        } else {
          if (args != '') {
            args += `,${key}=${value.join(',')}`;
          } else {
            args += `${key}=${value.join(',')}`;
          }
        }
      }
    });

    return { args, round };
  }, [])

  const downloadExcel = useCallback(async ({ params }: { params: any } = { params: filtersData ?? filtersInitData }) => {
    setShowSpinner(true)
    try {
      const { round, args } = buildParams(params);
      const result = await consolidateRatingsService.downloadExcel({ round, lang: appLanguage, args })

      const blob = new Blob([result.data]);
      handleXlsxFile(blob, 'consolidateRatingsTable.xlsx')
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setShowSpinner(false)
    }

  }, [filtersData, appLanguage])

  const findConsolidated = useCallback(({ params = filtersData, tabSelected = value }) => {
    setShowSpinner(true);
    const { round, args } = buildParams(params);

    consolidateRatingsService.findAll({ round, args, lang: appLanguage }).then((data) => {
      setDataGrid(data);
      setDataShow(renderResultsTable({ list: data, tabSelected }))
    }).finally(() => { setShowSpinner(false) });

  }, [value, filtersData])

  const onSelectFilter = useCallback((dataSelected: Map<string, string[]>) => {
    setFiltersInitData(dataSelected);
    setFiltersData(dataSelected);
    findConsolidated({ params: dataSelected, tabSelected: value });

  }, [setFiltersData, findConsolidated, value])


  return (
    <>
      <ProgressBar title={t('navbar.consolidated-ratings')} icon={ConsolidatedIcon} >
        <>
        </>
      </ProgressBar>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', borderTop: 1, borderTopColor: 'divider', background: 'white' }}>
        <Tabs value={value} onChange={showSpinner ? null : handleChange} aria-label="basic tabs example">
          <Tab
            label={t('consolidateRatings.tabs.effectiveness.title')}
            className='tab-panel-title'
            {...a11yProps(0)}
            value={ConsolidatedRatingEnum.EFFECTIVENESS}
            style={value === ConsolidatedRatingEnum.EFFECTIVENESS ? { textTransform: 'none', borderBottom: '2px solid #426BBA' } : { textTransform: 'none' }}
          />
          <Tab
            label={t('consolidateRatings.tabs.technicalCompliance.title')}
            value={ConsolidatedRatingEnum.TECHNICAL_COMPLIANCE}
            className='tab-panel-title'
            style={value === ConsolidatedRatingEnum.TECHNICAL_COMPLIANCE ? { textTransform: 'none', borderBottom: '2px solid #426BBA' } : { textTransform: 'none' }}
            {...a11yProps(1)} />
        </Tabs>
      </Box>
      {
        changingTab ?
          <LoadingComponent></LoadingComponent>
          :
          <>
            <div className='main-content'>
              <CustomAccordionFilter
                data={consolidateRatingsAccordionMenu}
                initialValues={filtersInitData}
                loadingData={loadingData}
                onSelectableChange={onSelectFilter}
              />
              {!showSpinner || !dataShow ?
                <div className={classes['ratingsTable']}>
                  <div className={'container-abm-page'}>
                    <CalificationTable
                      headers={dataHeader}
                      rows={dataShow}
                      countItems={dataShow.length}
                      rowsPerPage={dataShow.length}
                      hasActions={false}
                      appendOnScrollToBottom={false}
                    >
                    </CalificationTable>

                    {ratingClick != null ?
                      <RatingModal
                        openModal={openModal}
                        handleClose={handleClose}
                        loadingModal={loadingModal}
                        dataModal={dataModal}
                        renderConclusion={renderConclusion}
                        ratingClick={ratingClick}
                        selectedCountry={selectedCountry}
                      />
                      : null}

                  </div>
                  <div className='buttons-section d-flex-center'>
                    <button className='basic-button-icon blue-button lato svg-button' onClick={async () => {
                      downloadExcel()
                    }}>
                      <XlsIcon /> {t('buttons.download')} XLS </button>
                  </div>
                </div> :
                <div className={classes['loadingComponent']}><LoadingComponent />
                </div>
              }
            </div>

          </>
      }
    </>
  );
}
