/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import NewKeyIssueStepOne from './steps/stepOne';
import classes from './NewKeyIssue.module.css'
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CustomWizard, { StepComponent } from '../../../../components/common/wizard/Wizard';
import { useLanguage } from '../../../../contexts/language-context';
import { KeyIssue } from './dto/key-issue-dto';
import NewKeyIssueStepTwo from './steps/stepTwo';
import NewKeyIssueStepThree from './steps/stepThree';
import NewKeyIssueStepFour from './steps/stepFour';
import DiscussionService from '../../../../services/discussion.service';
import LoadingComponent from '../../../../components/common/loading/loading';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from '../../../../contexts/error-context';

interface NewKeyIssueProps {
  open: boolean;
  onClose: (e?: any, reason?: string) => void;
  data?: KeyIssue;
  lang: string;
  discussionId?: number;
  success?: boolean;
  error?: boolean;
  editMode?: boolean;
  successMessage?: string | null;
  errorMessage?: string | null;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.8)',
  width: 1000,
  bgcolor: 'white',
  border: 'none',
  borderRadius: '15px',
  padding: '40px 73px',
}

const NewKeyIssue = ({ open, onClose, data, editMode = false, lang, discussionId }: NewKeyIssueProps = { open: false, onClose: () => { }, data: { id: undefined }, lang: 'en' }) => {

  const { setError: setContextError } = useErrorContext()
  const [steps, setSteps] = useState<StepComponent[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [value, setValue] = useState<KeyIssue>(new KeyIssue(data));
  const [assesments, setAssesments] = useState<any>();
  const [isEdit] = useState(editMode);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { language: appLanguage } = useLanguage();
  const { t } = useTranslation('global')
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [discussionService] = useState<DiscussionService>(DiscussionService.getInstance(setContextError));

  useMemo(() => {
    const auxSteps = [
      {
        label: t('createUpdateDiscussion.key-issue'),
        component:
          <NewKeyIssueStepOne
            lang={appLanguage}
            issueTypeList={issueTypeList}
            updateIssueTypeList={setIssueTypeList}
            isEdit={isEdit}
            onAfterBack={() => onClose()}
            onAfterSubmit={(values) => {
              setValue(Object.assign(value, values));
              setActiveStep(activeStep + 1);
            }}
            value={value}
          />
      },
      {
        label: t('createUpdateDiscussion.description-field'), component: <NewKeyIssueStepThree
          isEdit={isEdit} onAfterBack={() => setActiveStep(activeStep - 1)} onAfterSubmit={(values) => {
            setValue(Object.assign(value, values));
            setActiveStep(activeStep + 1);
          }} value={value} />
      },
      {
        label: t('createUpdateDiscussion.relevant-decisions-workinggroup'), component: <NewKeyIssueStepTwo
          isEdit={isEdit} onAfterBack={() => setActiveStep(activeStep - 1)} onAfterSubmit={(values) => {
            setValue(Object.assign(value, values));
            setActiveStep(activeStep + 1);
          }} value={value} />
      },
      {
        label: t('createUpdateDiscussion.relevant-decisions-plenary'), component: <NewKeyIssueStepFour
          isEdit={isEdit} onAfterBack={() => setActiveStep(activeStep - 1)} onAfterSubmit={async (values) => {
            setShowSpinner(true);
            const data: KeyIssue = Object.assign(value, values);
            data.elementCode = data.auxEl.id;
            setValue(data);
            let resp;
            if (isEdit) {
              resp = await discussionService.updateKeyIssue(appLanguage, discussionId, data.id, Object.assign({}, new KeyIssue(data), { lang }));
            } else {
              resp = await discussionService.createKeyIssue(appLanguage, discussionId, Object.assign({}, new KeyIssue(data), { lang }));
            }
            if (resp) {
              onClose(undefined, 'confirm')
            }
            setShowSpinner(false);
          }} value={value} />
      },
    ];

    setSteps(auxSteps);

  }, [activeStep, assesments, t, issueTypeList]);
  // }, [ distributionCentres, reserveTypes, stockMovements, distributionList, activeStep, value]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    // className={'dialog-lg'}
    >
      <Box sx={style}>
        <div>
          <IconButton onClick={onClose} className={classes.closeIconBtn}>
            <CloseIcon className={classes.closeIconBtn} />
          </IconButton>
          {/* <DialogContent sx={styleT} className={`${classes.dialogContainer} nwsn-scroll`}> */}

          {
            showSpinner ?
              <LoadingComponent styles={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', background: 'transparent', zIndex: '1001' }} />
              : null
          }


          <CustomWizard steps={steps} activeStep={activeStep} title='' cssClass={`${classes.stepFull}`}>
            {
              steps.length > 0 ?
                steps.at(activeStep)!.component
                : null
            }
          </CustomWizard>
          {/* </DialogContent> */}
        </div>
      </Box>
    </Modal >
  );
};

export default NewKeyIssue;
