import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgressBar from '../../../../../components/common/progress-bar/progress-bar';
import { ReactComponent as CurrentData } from '../../../../../assets/icons/current-data.svg'
import { BarChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import classes from './graphic-of-report.module.css'
import ImageIcon from '@mui/icons-material/Image';

export default function GraphicOfReport() {
  const ref = useRef()
  const location = useLocation();
  const { t } = useTranslation('global');
  const { dataShow, graphType }: { dataShow: { id: number, value: number, label: string, color: string }[], graphType: string } = location.state || {};
  const navigate = useNavigate();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (dataShow) {
      const sum = dataShow.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
      setTotal(sum);
    }
  }, [dataShow]);

  const calculatePercentage = (value: number) => {
    return total !== 0 ? parseFloat(((value / total) * 100).toFixed(1)) : 0;
  };


  const getDefs = () => {
    const styles = getStyles()

    return `<defs><style type="text/css"><![CDATA[${styles}}]]></style></defs>`
  }

  const stringifyStylesheet = (stylesheet) => {
    try {
      const pStringifyStylesheet = stylesheet.rules
        ? Array.from(stylesheet.rules)
          .map((rule: any) => rule.cssText || '')
          .join('\n')
        : ''

      return pStringifyStylesheet;
    } catch (e) {
      console.warn(e);
      return '';
    }

  }

  const getStyles = () =>
    Array.from(document.styleSheets)
      .map(s => stringifyStylesheet(s))
      .join('\n')

  const downloadAsPng = useCallback(() => {
    if (ref && ref.current) {
      const svgElems = (ref.current as any).querySelectorAll('svg')

      if (svgElems.length === 0) {
        console.log('No svg chart found in container')
        return;
      }

      const svgElem = svgElems[0]
      // adding styles
      const defs = getDefs()
      svgElem.insertAdjacentHTML('afterbegin', defs)

      const output = { 'name': 'chart.png', 'width': svgElem.clientWidth, 'height': svgElem.clientHeight }
      // const uriData = `data:image/svg+xml;base64,${btoa(svgElem.outerHTML)}` // it may fail.
      const uriData = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svgElem))))}`
      const img = new Image()
      img.src = uriData
      img.onload = () => {
        const canvas = document.createElement('canvas');
        [canvas.width, canvas.height] = [output.width, output.height]
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, output.width, output.height)

        // download
        const a = document.createElement('a')
        const quality = 1.0 // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/imageSmoothingQuality
        a.href = canvas.toDataURL('image/png', quality)
        a.download = output.name
        a.append(canvas)
        a.click()
        a.remove()
      }
    }
  }, [ref]);

  if (dataShow == undefined || graphType == undefined) navigate('*')
  return (
    <>
      <ProgressBar title={t('reportAndStatistics.graphicsData.title')} icon={CurrentData} >
        <>
        </>
      </ProgressBar>
      <div className='main-content' style={{ display: 'inline', textAlign: 'center' }}>
        <div ref={ref} className={classes.boxContainer}>

          {(graphType == 'donut' || graphType == 'pie') && <PieChart
            series={[
              {
                data: dataShow,
                innerRadius: graphType == 'donut' ? 100 : null,
                arcLabel: (params) => `${params.value}` + (calculatePercentage(params.value) < 5 ? '' : `(%${calculatePercentage(params.value)})` ?? '')
              },
            ]}
            sx={{
              fontSize: '18px'
            }}
            width={700}
            height={550}
          />}
          {(graphType == 'row' || graphType == 'column') &&
            <BarChart
              dataset={dataShow}
              series={[{ dataKey: 'value' }]}
              xAxis={graphType == 'column' ? [{
                scaleType: 'band', dataKey: 'label', colorMap: {
                  type: 'ordinal',
                  colors: dataShow.map(({ color }) => (color))
                }
              }] : null}
              yAxis={graphType == 'row' ? [{
                scaleType: 'band', dataKey: 'label', colorMap: {
                  type: 'ordinal',
                  colors: dataShow.map(({ color }) => (color))
                }
              }] : null}
              width={1050}
              height={550}
              layout={graphType == 'row' ? 'horizontal' : null}
              barLabel='value'
            />
          }
        </div>
      </div>
      <div className='buttons-section d-flex-center'>
        <button type='button' className='basic-button blue-button lato mr-25' onClick={() => { downloadAsPng() }}>
          <div className='d-flex' style={{ alignItems: 'center' }}>
            <ImageIcon sx={{ color: '#5ECDE9', fontSize: 35, marginRight: '15px' }} />
            <span>{t('buttons.downloadPng')}</span>
          </div>

        </button>
        <button type='button' className='basic-button blue-button lato' onClick={() => { navigate(-1) }}> Return</button>
      </div>
    </>
  );
}
