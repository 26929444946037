import { useEffect, useMemo, useState } from 'react';
import styles from './../mer-fur.module.css'
import backgroundImage from './../../../assets/images/step2-image.png'
import { ReactComponent as FurIcon } from './../../../assets/icons/new-fur-icon.svg'
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import CustomFlag from '../../../components/common/flag/flag';
import { useData } from '../../../components/hooks/useData';
import { useLanguage } from '../../../contexts/language-context';
import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Assessment } from '../../../model/models-module';
import { ReactComponent as DeleteIcon } from './../../../assets/icons/delete.svg';
import FollowUpReportService from '../../../services/follow-up-report.service';
import LoadingComponent from '../../../components/common/loading/loading';
import './fur-table.css';
import UpdateFileFurModal from './update-fur-file-modal';
import { handleFile } from '../../../utils/CommonUtils';
import DiscussionService from '../../../services/discussion.service';
import { useErrorContext } from '../../../contexts/error-context';


export default function UpdateFUR() {
  const { t } = useTranslation('global')
  const { id: furId, lang: furLanguage } = useParams()
  const { language: appLanguage } = useLanguage()
  const [loadingFurData, furError, furData] = useData<any>(`${appLanguage}/fur/${furId}?lang=${appLanguage}`, 'GET')
  const [aLoading, aError, aOptions] = useData<any>(`${appLanguage}/default/assessment-reco-io`, 'GET')
  const [openModal, setOpenModal] = useState(false)
  const [isDownloadPdf, setIsDownloadPdf] = useState(false)
  const { setError: setContextError } = useErrorContext()
  const service = FollowUpReportService.getInstance(setContextError)
  const [discussionService] = useState(DiscussionService.getInstance(setContextError))
  const [loading, setLoading] = useState(false)
  const [recommendationsList, setRecommendationList] = useState([]);
  const [recommendationCode, setRecommendationCode] = useState<string>('')
  const [assessmentId, setAssessmentId] = useState<string>('')
  const [rows, setRows] = useState<{ recommendation: { id: number, code: string, description: string }, assessment: Assessment, id?: number }[]>([])

  const [emptyRecommendation, setEmptyRecommendation] = useState<boolean>(false)

  const [emptyAssessment, setEmptyAssessment] = useState<boolean>(false)

  const menuProps = {
    PaperProps: {
      style: {
        transform: 'scale(0.7)',
        transformOrigin: 'top left',
      },
    },
  }

  const findElementList = async (element: string = 'recommendation') => {
    const list = await discussionService.findElementList(appLanguage, element);
    setRecommendationList(list.map(l => { return { id: l.id, code: l.code, description: l.description } }));
  }

  useEffect(() => {
    findElementList('recommendation');
  }, [appLanguage])


  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const downloadPdf = async () => {
    try {
      setIsDownloadPdf(true)
      const result = await service.downloadFurPDF(appLanguage, +furId, furLanguage)
      const filename = `FUR-${furData?.countryDesc}-${furData?.period}-${furData?.roundDesc}-${furLanguage.toUpperCase()}`
      handleFile(result.data, `${filename}.pdf`)
    } catch (error) {
      // setContextError(error)
      console.log(error)
    } finally {
      setIsDownloadPdf(false)
    }
  }

  const onDeleteItem = async (id: number, index: number) => {

    if (id) {
      try {
        setLoading(true)
        const result = await service.deleteReRating(appLanguage, +furId, id)
        if (result.status === 'OK') {
          const newValue = [...rows]
          newValue.splice(index, 1)
          setRows(newValue)
        }
      }
      catch (e) {
        // setContextError(e)
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
  }
  const onAddItem = async () => {

    if (!recommendationCode) {
      setEmptyRecommendation(true)
      return
    } else {
      setEmptyRecommendation(false)
    }
    if (!assessmentId) {
      setEmptyAssessment(true)
      return
    } else {
      setEmptyAssessment(false)
    }


    if (rows.find(el => el.recommendation.code === recommendationCode)) {
      setContextError(t('updateFur.messages.duplicate'))
      return
    }
    const body = {
      furId: +furId,
      reRatings: [
        {
          element: 'recommendation',
          elementCode: recommendationCode,
          assessmentId: assessmentId
        }
      ]
    }
    try {
      setLoading(true)
      const result = await service.addReRating(appLanguage, +furId, body)
      if (result.id) {

        const newValue = [...rows]
        const assessment = aOptions.recommendations.find(el => el.id === assessmentId)
        const recommendation = recommendationsList.find(el => el.code === recommendationCode)
        const reRatingId = result.reRatings.find(r => r.elementCode === recommendationCode)?.id ?? -1
        newValue.push({ assessment, recommendation, id: reRatingId })
        setRows(newValue)
      }
    } catch (e) {
      // setContextError(e)
      console.log(e)
    } finally {
      setLoading(false)
    }

  }
  useEffect(() => {
    if (!loading) {
      const newValue = []
      furData?.reRatings.map(el => {
        const reRatingId = el.reRatingId
        const assessment = aOptions?.recommendations.find(r => r.id === el.assessment.assessmentId)
        const recommendation = recommendationsList.find(r => r.code === el.elementCode)
        newValue.push({ assessment, recommendation, id: reRatingId })
      })
      setRows(newValue)
    }
  }, [furData, aOptions, appLanguage])

  useEffect(() => {
    setLoading(aLoading && loadingFurData)
  }, [loadingFurData, aLoading])


  return (

    <>
      {openModal && <UpdateFileFurModal open={openModal} setOpen={setOpenModal} />}
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <FurIcon />
          <div>
            <span>{t('fur.description')}</span>
          </div>
        </div>
        {!loading &&
          <div className='mer-info right'>
            <CustomFlag code={furData?.countryCode} />
            <span className='pl-15'>{furData?.countryDesc} - {furData?.period} - {furData?.roundDesc} - {furLanguage.toUpperCase()}</span>
          </div>
        }
      </div>
      <div className='main-content'>
        <div className='row' style={{ width: '100%', marginLeft: '20px' }}>
          <div style={{ width: '35%' }}>
            <div className={`pt-64 ${styles.updateFurContainer}`}>
              <img className={styles.introImage} src={backgroundImage}></img>
              <h1 className='primary-title'>{t('fur.description')}</h1>
              <div className={styles.furInfo}  >
                <div className={styles.furPrimaryDescription}>{t('updateFur.welcome')}</div>
                {t('updateFur.description')}
                <p className={styles.hereDescription}>{t('updateFur.description-here')}</p>
                <p>{t('updateFur.description-1')}</p>
                <p>{t('updateFur.description-2')}</p>
                <p>{t('updateFur.description-3')}</p>
                <div className='secondary-description' style={{ marginTop: '100px' }}>
                  {!isDownloadPdf && <p className={styles.modifyFile}
                    onClick={downloadPdf}
                  >{t('updateFur.download')}</p>
                  }
                  {isDownloadPdf && <CircularProgress disableShrink />}
                  <p className={styles.modifyFile} onClick={handleOpenModal} >{t('updateFur.update')}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '65%', minHeight: '780px', marginRight: '20px', paddingLeft: '30px' }}>
            <div className={`${styles.card} row`}>
              <div className="col-3">
                <FormControl fullWidth error={emptyRecommendation}>
                  <Autocomplete
                    className={styles.select}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    disablePortal
                    id="combo-box"
                    onChange={(event: any, newValue) => {
                      setRecommendationCode(newValue ? newValue.id : '')
                    }}
                    options={recommendationsList?.map(r => {
                      return { label: r.description, id: r.code }
                    }) ?? []}
                    renderInput={(params) =>
                      <TextField {...params}
                        label={t('updateFur.select.recommendations')}
                        className={!emptyRecommendation ? styles.autocompleteLabel : styles.hasError}
                        error={emptyRecommendation}
                      />}
                  />
                </FormControl>
              </div>
              <div className="col-3 pl-15">
                <FormControl error={emptyAssessment} fullWidth>
                  <InputLabel id="fur-select-label"> {t('updateFur.select.assessments')}</InputLabel>
                  <Select
                    className={styles.select}
                    labelId="assessment-label"
                    id="assessment-select"
                    label="Assessments"
                    value={assessmentId}
                    MenuProps={menuProps}
                    onChange={(event: SelectChangeEvent) => setAssessmentId(event.target.value)}
                  >
                    {aOptions?.recommendations?.map((option, j) => {
                      return <MenuItem
                        key={j}
                        value={option.id}>
                        {`${option.shortDescription} (${option.description})`}
                      </MenuItem>
                    })
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-3 pl-15">
                <div className={`${styles.merButtons}`} style={{ float: 'right', marginRight: '40px' }}>
                  <button className={`${styles.addButton}`} onClick={onAddItem}>
                    {t('buttons.add')}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ paddingLeft: 0 }}>
                <div className='furTable'>
                  {!loading ?
                    <TableContainer component={Paper} >
                      <Table aria-label="simple table">
                        <TableHead >
                          <TableRow>
                            <TableCell className='furHeaderTable'>
                              <span style={{ borderRadius: ' 10px 0px 0px 10px' }}>{t('updateFur.table.recommendation')}</span>
                            </TableCell>
                            <TableCell className='furHeaderTable'>
                              <span>{t('updateFur.table.assessment')}</span>
                            </TableCell>
                            <TableCell className='furHeaderTable'>
                              <span style={{ borderRadius: ' 0px 10px 10px 0px' }} > {t('updateFur.table.action')}</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow
                              key={i}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row" style={{ width: '150px' }}>
                                {row.recommendation.description}
                              </TableCell>
                              <TableCell style={{ width: '100px' }}>{row.assessment.shortDescription} ({row.assessment.description})
                              </TableCell>
                              <TableCell style={{ width: '100px' }}>
                                <span onClick={() => { onDeleteItem(row.id, i) }}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#003479',
                                    cursor: 'pointer'
                                  }}>
                                  <SvgIcon component={DeleteIcon} />{t('buttons.delete')}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    : <LoadingComponent />}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div >
    </>
  )
}
