import './step-two.css'

import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { ChildrenTypeEnum } from '../../../configs/data'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as CollapseIcon } from './../../../assets/icons/minus-icon.svg'
import ComparisonToolFiltersService from '../../../services/comparison-tool.service'
import CustomAccordion from '../../../components/common/accordion/accordion'
import ExpandIcon from '../../../components/common/icon/plus-icon/plus-icon'
import MainLeftMenu from '../../../components/layout/left-menu/left-menu'
import backgroundImage from './../../../assets/images/step2-image.png'
import { useLanguage } from '../../../contexts/language-context'
import { useTranslation } from 'react-i18next'
import LoadingComponent from '../../../components/common/loading/loading'
import { useErrorContext } from '../../../contexts/error-context'

interface Props {
  canChangeStep: any
  setCanChangeStep: any
  selectedRows: any
  selectedComparables: any
  setSelectedComparables: any
  setStep: any
  handleContinue: any
}

const ComparisonStepTwoRoundFour: React.FC<Props> = ({
  canChangeStep,
  setCanChangeStep,
  selectedRows,
  selectedComparables,
  setSelectedComparables,
  setStep,
  handleContinue,
}: Props) => {
  const [compare, setCompare] = useState(false)
  const { language } = useLanguage()
  const { t } = useTranslation('global')
  const [comparisonToolStepTwoMenu, setComparisonToolStepTwoMenu] = useState<any[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [changeLanguage, setChangeLanguage] = useState<boolean>(false)

  // Menu filters
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [filtersData, setFiltersData] = useState<Map<string, any>>(new Map<string, any>())
  const [graphMenu, setGraphMenu] = useState<any[]>()
  const [dataSelected, setDataSelected] = useState<Map<string, any>>(new Map());
  const { setError: setContextError } = useErrorContext()
  const comparisonToolFiltersService = ComparisonToolFiltersService.getInstance(setContextError)
  const initGraphMenu = (menu: any[]) => {
    if (filtersData.size === 0) return
    const gMenu = []

    menu.forEach((m) => {
      const o = { ...m }
      delete o.expandIcon
      delete o.collapseIcon
      delete o.elementType
      delete o.summary
      if (o.customKey === 'riskAndContextFactors') {
        o.level = 1
        o.label = m.summary
        o.visible = (o.selected ?? []).length > 0
        o.parentKey = null
        o.grandParentKey = null
        delete o.detailList
        o.childs = []
        filtersData.get('riskAndContextFactors').forEach((value) => {
          const child = { ...value }
          delete child.id
          delete child.code
          delete child.description
          child.customKey = value.code
          child.label = value.description
          child.visible = (o.selected ?? []).includes(value.description)
          child.level = 2
          child.parentKey = o.customKey
          child.grandParentKey = null
          o.childs.push(child)
        })
        delete o.selected
      } else if (o.customKey === 'technicalCompliance' || o.customKey === 'effectiveness') {
        o.level = 1
        o.label = m.summary
        o.visible = (o.selected ?? []).length > 0
        o.parentKey = null
        o.grandParentKey = null

        o.childs = []
        let i = 0
        o.detailList.forEach((item, idx) => {
          const child = {
            customKey: item.customKey,
            label: item.summary,
            visible: (item.selected ?? []).length > 0,
            level: 2,
            parentKey: o.customKey,
            grandParentKey: null,
          }

          i = o.childs.length
          o.childs.push(child)
          filtersData
            .get(o.customKey)
            .get(item.customKey)
            .forEach((value) => {
              const child = { ...value }
              delete child.id
              delete child.code
              delete child.description
              child.customKey = value.code
              child.label = value.description
              child.visible = (item.detailList.selected ?? []).includes(value.description)
              child.level = 3
              child.parentKey = o.childs[i].customKey
              child.grandParentKey = o.customKey
              child.parentIndex = i
              o.childs.push(child)
            })
        })
        delete o.detailList
      }

      gMenu.push(o)
    })

    setGraphMenu(gMenu)
  }

  useLayoutEffect(() => {
    if (selectedComparables) {
      handleGraphMenu()
    }
  }, [selectedComparables])

  const handleGraphMenu = () => {
    if (!graphMenu) return
    let found = false
    const newGraphMenu = [...graphMenu]

    // Limpiamos los visibles
    for (const item of newGraphMenu) {
      item.visible = false
      item.childs.forEach((child) => {
        child.visible = false
      })
    }

    for (const comparable of selectedComparables) {
      for (const gMenu of newGraphMenu) {
        const child = gMenu['childs'].find((c) => c.customKey === comparable)
        if (child) {
          found = true
          child.visible = !child.visible
          if (child.level === 3) {
            const grandpa = newGraphMenu.find((p) => p.customKey === child.grandParentKey)
            const dad = grandpa.childs[child.parentIndex]
            dad.visible = grandpa.childs
              .filter((s) => s.parentKey === dad.customKey)
              .some((c) => c.visible)
            grandpa.visible = dad.visible
          } else if (child.level === 2) {
            const parent = newGraphMenu.find((p) => p.customKey === child.parentKey)
            parent.visible = parent.childs.some((c) => c.visible)
          }
          break
        }
      }
    }

    setCompare(selectedComparables.length > 0)
    if (found) {
      setGraphMenu(newGraphMenu)
    }
  }

  useEffect(() => {
    setFiltersLoaded(false)

    const lsFilters = localStorage.getItem('CTStep2Round4Comparables')
    let filtersObj = null
    if (lsFilters) {
      filtersObj = JSON.parse(lsFilters)
      if (!filtersObj['lang'] || filtersObj['lang'] !== language) {
        filtersObj = { lang: language }
        localStorage.setItem('CTStep2Round4Comparables', JSON.stringify(filtersObj))
        setChangeLanguage(true)
      }
    }
    const auxDataSel = new Map();
    dataSelected.forEach((v, k) => auxDataSel.set(k, v));

    comparisonToolFiltersService.step2MenuRound4(language)
      .then((result) => {
        if (!result || result.size === 0) return

        setFiltersData((prevState) => {
          prevState.clear()
          result.forEach((value, key) => {
            if (key !== 'menu') prevState.set(key, value)
          })
          return prevState
        })

        const menu = [...result.get('menu')]

        // RiskAndContextFactors
        menu[0] = {
          ...menu[0],
          selected: filtersObj ? filtersObj[menu[0].customKey] : [],
        }
        auxDataSel.set('riskAndContextFactors', filtersObj ? filtersObj['riskAndContextFactors'] : []);
        auxDataSel.set('effectiveness', filtersObj ? filtersObj['effectiveness'] : []);
        auxDataSel.set('technicalCompliance', filtersObj ? filtersObj['technicalCompliance'] : []);

        // TechnicalCompliance / Effectiveness
        for (let i = 1; i < 3; i++) {
          menu[i].detailList.forEach((obj, key) => {
            const o = {
              ...obj,
              selected:
                filtersObj && filtersObj[menu[i].customKey]
                  ? filtersObj[menu[i].customKey][obj.key]
                  : [],
            }
            menu[i].detailList[key] = o
          })
        }

        menu.forEach((e) => {
          if (e.elementType === `${ChildrenTypeEnum.ACCORDION}`) {
            e.expandIcon = ExpandIcon
            e.collapseIcon = CollapseIcon
            e.detailList.forEach((child) => {
              child.expandIcon = ExpandIcon
              child.collapseIcon = CollapseIcon
            })
          }
        })
        const auxMenu = [menu[0], menu[2], menu[1]]
        setComparisonToolStepTwoMenu((_) => {
          initGraphMenu(auxMenu)
          return auxMenu
        })

        if (filtersObj) {
          setCanChangeStep(true)
          handleComparables()
        }
        setDataSelected(auxDataSel);
      })
      .catch((e) => {
        console.error(e)
        setComparisonToolStepTwoMenu([])
        setCompare(false)
        setGraphMenu(null)
      })
      .finally(() => {
        setFiltersLoaded(true)
      })
  }, [language])

  useEffect(() => {
    setShowSpinner(!filtersLoaded)
  }, [filtersLoaded])

  const handleAccordionSelectedData = useCallback((
    parentMenu: number | string,
    menu: number | string,
    selectedItems: Map<number, any[]>,
  ) => {
    const selected = []
    const selectedCode = []
    const dataSel = new Map();
    dataSelected.forEach((v, k) => dataSel.set(k, v));

    const lsFilters = localStorage.getItem('CTStep2Round4Comparables')
    let filtersObj = null
    if (!lsFilters) {
      filtersObj = { lang: language }
      localStorage.setItem('CTStep2Round4Comparables', JSON.stringify(filtersObj))
    } else {
      filtersObj = JSON.parse(lsFilters)
    }

    if (parentMenu) {
      if (selectedItems.size > 0) {
        selectedItems.forEach((_, key) => {
          selected.push(filtersData.get(`${parentMenu}`).get(menu)[key].description)
          selectedCode.push(filtersData.get(`${parentMenu}`).get(menu)[key].code)
        })
      }
      if (!filtersObj[parentMenu]) {
        filtersObj[parentMenu] = {}
      }
      filtersObj[parentMenu][menu] = selected

      dataSel.set(String(parentMenu), filtersObj[parentMenu]);


    } else {
      // Cabecera
      if (!parentMenu && (menu === 'technicalCompliance' || menu === 'effectiveness')) return false

      if (selectedItems.size > 0) {
        selectedItems.forEach((_, key) => {
          selected.push(filtersData.get(`${menu}`)[key].description)
          selectedCode.push(filtersData.get(`${menu}`)[key].code)
        })
      }
      filtersObj[menu] = selected
      dataSel.set(String(menu), filtersObj[menu]);

    }

    const oldData = localStorage.getItem('CTStep2Round4Comparables')
    const newData = JSON.stringify(filtersObj)
    localStorage.setItem('CTStep2Round4Comparables', newData)

    setDataSelected(dataSel);

    return oldData !== newData
  }, [dataSelected]);


  const removeOpt = useCallback(({ key, newVal, parentKey, level }: { key: string, newVal: any, parentKey?: string, level: number }) => {

    if (level === 1) {
      const dataSel = new Map();
      dataSelected.forEach((v, k) => dataSel.set(k, v));
      const lsFilters = localStorage.getItem('CTStep2Round4Comparables');
      const filtersObj = JSON.parse(lsFilters);

      filtersObj[key] = {};
      dataSel.set(String(key), new Map());
      const newData = JSON.stringify(filtersObj)
      localStorage.setItem('CTStep2Round4Comparables', newData)
      setDataSelected(dataSel);
    }

    handleAccordionSelectedData(parentKey, key, newVal);
    handleComparables();
    handleGraphMenu();


  }, [dataSelected]);

  const handleComparables = (toExclude?: string[]) => {
    const data = JSON.parse(localStorage.getItem('CTStep2Round4Comparables'))

    const comparables = []
    if (data['riskAndContextFactors']) {
      data['riskAndContextFactors'].forEach((val) => {
        comparables.push(
          filtersData.get('riskAndContextFactors').find((d) => d.description === val).code,
        )
      })
    }
    if (data['technicalCompliance']) {
      for (const prop in data['technicalCompliance']) {
        data['technicalCompliance'][prop].forEach((val) => {
          comparables.push(
            filtersData
              .get('technicalCompliance')
              .get(prop)
              .find((d) => d.description === val).code,
          )
        })
      }
    }
    if (data['effectiveness']) {
      for (const prop in data['effectiveness']) {
        data['effectiveness'][prop].forEach((val) => {
          comparables.push(
            filtersData
              .get('effectiveness')
              .get(prop)
              .find((d) => d.description === val).code,
          )
        })
      }
    }

    if (toExclude && toExclude.length > 0) {
      for (const exclude of toExclude) {
        if (comparables.includes(exclude)) {
          const idx = comparables.indexOf(exclude)
          comparables.splice(idx, 1)
        }
      }
    }

    setSelectedComparables(comparables)
    setCanChangeStep(comparables.length > 0)
  }

  const onReturnStepOne = () => {
    if (changeLanguage) {
      selectedRows.clear()
      setChangeLanguage(false)
    }
    setStep(1)
  }

  const handleSelectChange = useCallback((menu, selectedItems, parentMenu) => {
    if (selectedComparables) {
      const changed = handleAccordionSelectedData(parentMenu, menu, selectedItems)
      if (changed && graphMenu) {
        handleComparables()
      }
    }
  }, [dataSelected]);

  return (
    <>
      <MainLeftMenu>
        {filtersLoaded && dataSelected ?
          comparisonToolStepTwoMenu.map((item, i) => {
            return (
              <CustomAccordion
                customKey={item.customKey || i}
                key={item.key || i}
                expandIcon={item.expandIcon}
                collapseIcon={item.collapseIcon}
                summary={item.summary}
                detailList={item.detailList}
                // selected={item.selected}
                selected={dataSelected.get(item.customKey) ?? []}
                filterElementType={item.elementType}
                onSelectableChange={handleSelectChange}
              />
            )
          })
          : <LoadingComponent />}
      </MainLeftMenu>
      <div className='page-content'>
        <div className='step-two-container'>
          <div className={compare ? 'pre-selection pt-34' : 'pre-selection pt-64'}>
            <img src={backgroundImage}></img>
            <h1 className={compare ? 'smaller pt-34 primary-title' : 'primary-title'}>
              {t('comparison-tool.step-2.compare.title')}
            </h1>
            {!compare && (
              <div className='primary-description'>
                {t('comparison-tool.step-2.compare.description')}
              </div>
            )}
            {compare && dataSelected && (
              <div className='resume-container'>
                <div className='resume'>
                  {graphMenu.map((item, i) => {
                    if (item.visible && item.level === 1)
                      return (
                        <div key={i} className='resume-item'>
                          <div className='resume-first-level'>
                            <div className='content'>
                              <div className='resume-level-text'>{item.label}</div>
                              <div>
                                <CloseIcon onClick={() => {
                                  removeOpt({ key: item.customKey, newVal: new Map(), level: item.level });
                                }} />
                              </div>
                            </div>
                          </div>
                          <div className='resume-wrap'>
                            {item.childs &&
                              item.childs.map((child, j) => {
                                if (child.visible && child.level === 2) {
                                  return (
                                    <div key={j} className='resume-second-level'>
                                      <div className='separator'></div>
                                      <div className='content'>
                                        <div className='resume-level-text'>{child.label}</div>
                                        <div>
                                          <CloseIcon onClick={() => {
                                            const opt = comparisonToolStepTwoMenu.find(ct => ct.customKey === child.parentKey);

                                            if (opt.elementType === ChildrenTypeEnum.CHECKBOX) {
                                              const newSelection = dataSelected.get(item.customKey).filter(s => s !== child.label);
                                              const selected = new Map()
                                              opt.detailList.forEach((dl, i) => {
                                                const exists = newSelection.find(ns => ns === dl);
                                                if (exists) {
                                                  selected.set(i, new Map([[i, true]]));
                                                }

                                              });
                                              removeOpt({ key: child.parentKey, newVal: selected, level: child.level });
                                            } else {
                                              removeOpt({ key: child.customKey, newVal: new Map(), parentKey: child.parentKey, level: child.level });
                                            }
                                          }} />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                } else if (child.visible && child.level === 3) {
                                  return (
                                    <div key={j} className='resume-third-level'>
                                      <div className='content'>
                                        <div className='resume-level-text'>{child.label}</div>
                                        <div>
                                          <CloseIcon onClick={() => {
                                            const opt = comparisonToolStepTwoMenu.find(ct => ct.customKey === child.grandParentKey).detailList.find(ct => ct.customKey === child.parentKey);
                                            const newSelection = dataSelected.get(child.grandParentKey)[child.parentKey].filter(s => s !== child.label);
                                            const selected = new Map()
                                            opt.detailList.forEach((dl, i) => {
                                              const exists = newSelection.find(ns => ns === dl);
                                              if (exists) {
                                                selected.set(i, new Map([[i, true]]));
                                              }

                                            });
                                            removeOpt({ key: child.parentKey, newVal: selected, parentKey: child.grandParentKey, level: child.level });
                                          }} />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                                return null
                              })}
                          </div>
                        </div>
                      )
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='buttons-section'>
            <button className='mr-20 basic-button blue-button lato' onClick={onReturnStepOne}>
              {t('buttons.return')}
            </button>
            <button
              onClick={handleContinue}
              className={`basic-button ${canChangeStep ? 'blue-button' : 'disabled-button'} lato`}
            >
              {t('buttons.continue')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ComparisonStepTwoRoundFour
