import { MERInformation, effectivenessMER, technicalComplianceMER } from './data';

export const newMerMenu = [
  {
    summary: 'merInformation',
    detailList: MERInformation
  },
  {
    summary: 'effectiveness',
    detailList: effectivenessMER
  },
  {
    summary: 'technicalCompliance',
    detailList: technicalComplianceMER
  },

]
