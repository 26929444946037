import BaseService, { FindAllType } from './BaseService'


export default class KeyIssueService extends BaseService {
  private static _instance: KeyIssueService
  baseEndpoint = 'key-issues'

  public static getInstance(setError: (message: string | null) => void): KeyIssueService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async findAll({ lang, args = '', limit = 10, offset, sort }: FindAllType) {
    try {
      const url = `${lang}/discussions/${this.baseEndpoint}/grid`
      return await this.baseFindAll({ lang, args, limit, offset, sort }, url)

    } catch (err: any) {
      throw await this.handleError(err)
    }
  }
  async comparisonStep(id: number[], appLanguage: string) {
    try {
      const ids = id.join(',')
      const result = await this.client({ url: `${appLanguage}/discussions/${this.baseEndpoint}/detail?ids=${ids}`, method: 'GET' })
      return result.data
    } catch (e) {
      throw await this.handleError(e)
    }
  }

}
