/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService, { FindAllType } from './BaseService';
import { MutualEvaluation } from '../model/models-module';
import { UpdateChapterOneRequest } from '../requests/chapter-one.request';
import { UpdateImmediateOutcome } from '../requests/immediate-outcome.request';
export default class MutualEvaluationService extends BaseService {

  private static _instance: MutualEvaluationService;

  public static getInstance(setError: (message: string | null) => void): MutualEvaluationService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }
  async findByQuery({ lang, args = '', limit = 10, offset, sort }: FindAllType) {
    try {
      const url = `${lang}/mer/mers-furs`
      return await this.baseFindAll({ lang, args, limit, offset, sort }, url)

    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

  async saveMutualEvaluation(appLanguage: string, data: any): Promise<MutualEvaluation> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer`, method: 'POST', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateMutualEvaluation(appLanguage: string, merId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/file`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async downloadMutualEvaluationPDF(appLanguage: string, merId: number, language: string): Promise<any> {
    try {
      const result = await this.client({ url: `${appLanguage}/mer/${merId}/file?lang=${language}`, method: 'GET', responseType: 'blob' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }



  async updateMerPart(appLang: string, merId: number, merPart: string, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLang}/mer/${merId}/update-part/${merPart}`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateExecutiveSummary(appLang: string, merId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLang}/mer/${merId}/executive-summary`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async publishMer(appLanguage: string, merId: number, language: string): Promise<any> {

    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/publish?lang=${language}`, method: 'PUT' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async unPublishMer(appLanguage: string, merId: number, language: string): Promise<any> {

    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/unpublish?lang=${language}`, method: 'PUT' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateSpecificsCharacteristics(appLang: string, merId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLang}/mer/${merId}/specific-characteristics`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateChapterOne(appLanguage: string, merId: number, data: UpdateChapterOneRequest): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/chapter1`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateImmediateOutcome(appLanguage: string, merId: number, code: string, data: UpdateImmediateOutcome): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `/${appLanguage}/mer/${merId}/immediate-outcome/${code}`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateRecommendation(appLanguage: string, merId: number, code: string, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/recommendation/${code}`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateKeyDeficiency(appLanguage: string, merId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}/key-deficiencies`, method: 'PUT', data })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    }
    catch (err: any) {
      console.log(err)
      throw await this.handleError(err)
    }
  }

  async deleteMer(appLanguage: string, merId: number, language: string): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/mer/${merId}?lang=${language}`, method: 'DELETE' })
      if (result.data.errorCode || result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    }
    catch (err) {
      throw await this.handleError(err)
    }
  }


}
