/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useCallback, useMemo, useState } from 'react'
import classes from '../NewKeyIssue.module.css'
import { Autocomplete, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import WizardStep from '../../../../../components/common/wizard/WizardStep';
import { CodeDescription } from '../../../../../model/models-module';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { KeyIssue } from '../dto/key-issue-dto';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import DiscussionService from '../../../../../services/discussion.service';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../../contexts/error-context';

type Props = {
  value: KeyIssue;
  isEdit: boolean;
  lang?: string;
  issueTypeList?: any[];
  updateIssueTypeList: any;
  // hasErrors: boolean;
  // setHasErrors: any;
  onAfterSubmit(values: any): void;
  onAfterBack(): void;
}

const menuProps = {
  PaperProps: {
    style: {
      transform: 'scale(0.8)',
      transformOrigin: 'top left',
    },
  },
}
const NewKeyIssueStepOne = ({ value, lang, issueTypeList, updateIssueTypeList, onAfterSubmit, onAfterBack }: Props) => {
  value.element = value.element ?? 'recommendation';
  value.auxEl = value.auxEl ?? null;
  const { setError: setContextError } = useErrorContext()
  const [elementList, setElementList] = useState<any[]>([]);
  const refSubmit = createRef<HTMLButtonElement>();
  const [formValue, setFormValue] = useState<KeyIssue>(value);
  const { t } = useTranslation('global');
  const [discussionService] = useState<DiscussionService>(DiscussionService.getInstance(setContextError));

  useMemo(() => {
    if (issueTypeList && issueTypeList.length) {
      setElementList(issueTypeList);
    }
  }, [issueTypeList]);

  const findElementList = useCallback(async (element: string = 'recommendation') => {
    const list = await discussionService.findElementList(lang, element);
    if (value.elementCode!) {
      const aux = list.map(l => { return { label: l.description, id: l.code } }).find(l => l.id === value.elementCode);
      value.auxEl = aux;
      setFormValue(value);
    }
    updateIssueTypeList(list.map(l => new CodeDescription(l.id, l.code, l.description)));

  }, []);

  useMemo(() => {
    if (!issueTypeList! || issueTypeList.length === 0) {
      findElementList(value.element);
    }
  }, [])


  return (
    elementList.length === 0 ?
      <LoadingComponent></LoadingComponent>
      :
      <WizardStep btnCancelDisabled={false} cancelBackBtnLbl={t('wizard.cancel')} onNext={() => { if (refSubmit && refSubmit.current) refSubmit.current.click() }} onBack={() => { onAfterBack() }} btnBackDisabled={true} btnNextDisabled={false} btnEndDisabled={true}>


        <Formik

          initialValues={formValue}
          validate={values => {
            const errors: any = {};

            if (!values.element!) {
              errors.element = t('validations.required');
            }

            if (!values.auxEl!) {
              errors.elementCode = t('validations.required');
            }

            if (!values.keyIssue! || values.keyIssue === '<p><br></p>') {
              errors.keyIssue = t('validations.required');
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            onAfterSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
            values,
            errors,
            touched,
            /* and other goodies */
          }) => (

            <form onSubmit={handleSubmit} style={{ marginTop: '25px', overflow: 'hidden' }} >
              <div className={`${classes.formContainer} nwsn-scroll`}>
                <div className={classes.webContainer}>

                  <div className="col-6">
                    <FormControl error={errors.element !== undefined} fullWidth>
                      <InputLabel id="fur-select-label"> {t('createUpdateDiscussion.select.issueType')}</InputLabel>
                      <Select
                        className={classes.select}
                        labelId="assessment-label"
                        id="element"
                        label={t('createUpdateDiscussion.select.recommendations')}
                        defaultValue={'recommendation'}
                        value={values.element}
                        MenuProps={menuProps}
                        onChange={(e) => {
                          setFieldValue('element', e.target.value);
                          setFieldValue('auxEl', null);
                          findElementList(e.target.value)
                        }
                        }
                      >
                        {
                          [{ code: 'recommendation', description: t('createUpdateDiscussion.recommendation') }, { code: 'immediateOutcome', description: t('createUpdateDiscussion.immediateOutcome') }]?.map((option, j) => {
                            return <MenuItem
                              key={j}
                              value={option.code}>
                              {`${option.description}`}
                            </MenuItem>
                          })
                        }
                      </Select>
                      <FormHelperText>{errors && errors.element && errors.element}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className="col-6">
                    <FormControl error={errors.elementCode !== undefined} fullWidth>
                      <Autocomplete
                        className={classes.select}
                        isOptionEqualToValue={(option, value) => { return option.id === value.id }}
                        value={values.auxEl ?? null}
                        disablePortal
                        id="combo-box"
                        onChange={(event: any, newValue) => {
                          setFieldValue('auxEl', newValue);
                        }}
                        options={elementList?.map(r => {
                          return { label: r.description, id: r.code }
                        }) ?? []}
                        renderInput={(params) =>
                          <TextField {...params}
                            label={t(`createUpdateDiscussion.select.${values.element ?? 'recommendation'}s`)}
                            className={errors.elementCode ? classes.hasError : classes.autocompleteLabel}
                            error={errors.elementCode !== undefined}
                          />}
                      />
                      <FormHelperText>{errors && errors.elementCode && errors.elementCode}</FormHelperText>
                    </FormControl>
                  </div>
                  <br></br>
                  <div className={`${classes.segment} col-12`}>
                    <FormControl error={errors.keyIssue !== undefined} fullWidth>
                      <div className={classes.elementTitle}>{t('createUpdateDiscussion.key-issue')}</div>
                      <FormHelperText>{errors && errors.keyIssue && errors.keyIssue}</FormHelperText>
                      <RichTextArea
                        className={errors && errors.keyIssue ? `${classes.textAreaError} ${classes.step1}` : `${classes.textArea} ${classes.step1}`}
                        value={values.keyIssue}
                        onChange={(value) => { setFieldValue('keyIssue', value) }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              {/* <button onClick={()=> {setHasErrors(Boolean(Object.keys(errors).length))}} type='button'>setHasErrors</button> */}
              <button type="submit" ref={refSubmit} disabled={isSubmitting} style={{ display: 'none' }}>
                Submit
              </button>
            </form>
          )}
        </Formik>
      </WizardStep>
  )
}


export default NewKeyIssueStepOne;
