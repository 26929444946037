import { CodeDescription } from './models-module'
import moment from 'moment'

class ComapisonToolDataTableDTO {
  mertId?: number
  merId?: number
  merRound?: string
  merDate?: string
  merLanguage?: string
  countryName?: string
  countryCode?: string
  assessmentBodyName?: string

  static fromData(language: string, data: any): ComapisonToolDataTableDTO {
    const o = new ComapisonToolDataTableDTO()
    const {
      mertId,
      merId,
      merRound,
      merDate,
      merLanguage,
      countryName,
      countryCode,
      assessmentBodyName,
    } = data
    o.mertId = mertId
    o.merId = merId
    o.merRound = merRound

    if (merDate) {
      const now = new Date(merDate)
      const mask = language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
      o.merDate = moment(now).format(mask)
    }
    o.merLanguage = merLanguage
    o.countryName = countryName
    o.countryCode = countryCode
    o.assessmentBodyName = assessmentBodyName

    return o
  }
}

class Step3ComparisonDTO {
  countryCode: string
  countryName: string
  merDate: string
  merRound: string
  description: string

  constructor(language: string, data: any) {
    Object.assign(this, data)

    const now = new Date(this.merDate)
    const mask = language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
    this.merDate = moment(now).format(mask)
  }
}

class Step3ComparableDTO {
  code: string
  title: string
  comparison: Step3ComparisonDTO[]

  constructor(language: string, data: any) {
    Object.assign(this, data)
    if (this.comparison && Array.isArray(this.comparison)) {
      this.comparison = this.comparison.map((item: any) => new Step3ComparisonDTO(language, item))
    }
  }
}

class Step3CompareResponseDTO {
  comparable: Step3ComparableDTO

  constructor(language: string, data: any) {
    this.comparable = new Step3ComparableDTO(language, data.comparable)
  }

  static fromData(language: string, data: any): Step3CompareResponseDTO[] {
    return data.map((item: any) => new Step3CompareResponseDTO(language, item))
  }
}

class RoundDTO {
  static fromData(data: any): CodeDescription {
    const o = new CodeDescription()
    const { id, roundCode, roundValue } = data
    o.id = id
    o.code = `${roundCode}`
    o.description = roundValue

    return o
  }
}

class CountryDTO {
  static fromData(data: any): CodeDescription {
    const o = new CodeDescription()
    const { id, countryName, countryCode } = data
    o.id = id
    o.code = countryCode
    o.description = countryName

    return o
  }
}
class AssessmentBodyDTO {
  static fromData(data: any): CodeDescription {
    const o = new CodeDescription()
    const { id, assessmentBodyName } = data

    o.id = id
    o.code = `${id}`
    o.description = assessmentBodyName

    return o
  }
}

class MerYearDTO {
  static fromData(data: any): CodeDescription {
    const o = new CodeDescription()
    const { year } = data
    o.id = year
    o.code = `${year}`
    o.description = `${year}`

    return o
  }
}

class SpecificCharacteristicsOptionsDTO {
  static fromData(data: any): Map<string, CodeDescription[]> {
    const map = new Map()
    for (const property in data) {
      const values: CodeDescription[] = []
      data[property].forEach(({ optionCode, optionValue }) => {
        const o = new CodeDescription()
        o.code = optionCode
        o.description = optionValue
        values.push(o)
      })
      map.set(property, values)
    }

    return map
  }
}

class GridDTO<T> {
  results: T[]
  count: number

  constructor({ results, count }: GridDTO<T>) {
    this.results = results ?? []
    this.count = count ?? 0
  }
}

class WrappedObj<T> {
  attributes?: T
  id?: number

  constructor({ attributes, id }: WrappedObj<T>) {
    this.attributes = attributes
    this.id = id
  }
}
export interface MerFurTableDTO {
  id: number
  mertId: number
  round: string
  date: string,
  year: string
  month: string
  language: string
  countryName: string
  countryCode: string
  assessmentBodyName: string
  type: string
  status: string
  statusCode: string

}

export {
  AssessmentBodyDTO,
  CountryDTO,
  ComapisonToolDataTableDTO,
  Step3CompareResponseDTO,
  GridDTO,
  MerYearDTO,
  RoundDTO,
  SpecificCharacteristicsOptionsDTO,
  WrappedObj,

}
