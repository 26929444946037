import './App.css'
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import ComparisonTool from './pages/comparison-tool/comparison-tool'
import ConsolidateRatings from './pages/consolidate-ratings/consolidate-ratings'
import Discussion from './pages/discussion/discussion'
import GraphicOfReport from './pages/reports/pages/graphics-data/graphic-of-report/graphic-of-report'
import GraphicsData from './pages/reports/pages/graphics-data/graphics-data'
import MainLayout from './components/layout/main/main-layout'
import Menu from './components/layout/menu/menu'
import MerFur from './pages/mer-fur/mer-fur'
import PivotTablePage from './pages/reports/pages/pivot-table/pivot-table'
import KeyIssues from './pages/key-issue/key-issues'
import MERPreviewFile from './pages/mer-fur/mer/preview-file/preview-file'
import Reports from './pages/reports/reports'
import TableOfReport from './pages/reports/pages/graphics-data/table-of-report/table-of-report'
import { ToastContainer } from 'react-toastify'
import UpdateFUR from './pages/mer-fur/fur/update-fur'
import UpdateMER from './pages/mer-fur/mer/update/update-mer'
import CustomizedReports from './pages/reports/pages/customized-report/customized-reports'
import FURPreviewFile from './pages/mer-fur/fur/preview-file/preview-file'
import CreateEditDiscussion from './pages/discussion/create-edit-discussion/create-edit-discussion'
import DiscussionsMenu from './pages/discussion-menu/discussion-menu'
import RenderOnRole from './components/auth/RenderOnRole'
import { EDITOR_ROLE, USER_ADMIN_ROLE } from './components/common/constant/constants'
import ViewMER from './pages/mer-fur/mer/view/view-mer'

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path='/main' element={<MainLayout />}>
          <Route path='comparison-tool' element={<ComparisonTool />} />
          <Route path='discussions' element={
            <RenderOnRole roles={[USER_ADMIN_ROLE]}>
              <Discussion />
            </RenderOnRole>
          } />
          <Route path='update-discussions/:id/:lang' element={
            <RenderOnRole roles={[USER_ADMIN_ROLE]}>
              <CreateEditDiscussion />
            </RenderOnRole>
          } />
          <Route path='mer-fur' element={<MerFur />} />
          <Route path='fur/update/:id/:lang' element={

            <RenderOnRole roles={[USER_ADMIN_ROLE, EDITOR_ROLE]}>
              <UpdateFUR />
            </RenderOnRole>
          } />
          <Route path='mer/update/:id/:lang' element={
            <RenderOnRole roles={[USER_ADMIN_ROLE, EDITOR_ROLE]}>
              <UpdateMER />
            </RenderOnRole>
          } />
          <Route path='mer/view/:id/:lang'
            element={
              <RenderOnRole roles={[USER_ADMIN_ROLE]}>
                <ViewMER />
              </RenderOnRole>
            }
          />

          <Route path='mer/view-file/:id/:lang' element={<MERPreviewFile />} />
          <Route path='fur/view-file/:id/:lang' element={<FURPreviewFile />} />
          <Route path='consolidate-ratings' element={<ConsolidateRatings />} />
          <Route path='reports' element={<Reports />} />
          <Route path='reports/graphics' element={<GraphicsData />} />
          <Route path='reports/pivot-table' element={<PivotTablePage />} />
          <Route path='reports/graphics/table-of-report' element={<TableOfReport />} />
          <Route path='reports/graphics/graph-of-report' element={<GraphicOfReport />} />
          <Route path='reports/customized-reports' element={<CustomizedReports />} />
          <Route path='key-issues' element={<KeyIssues />} />
          <Route path='discussions-menu' element={<DiscussionsMenu />} />
          <Route path='' element={<Menu />} />
          <Route path='*' element={<Navigate to='/main' replace />} />
        </Route>
        <Route path='*' element={<Navigate to='/main' replace />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
