import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MERInformation } from '../../../../../configs/data'
import { useDefaultData } from '../../../../../contexts/default-data-context'
import styles from './../../../mer-fur.module.css'

interface ViewerProps {
  data: any
  methodology: string
}

export function SpecificCharacteristicViewer({ data, methodology }: ViewerProps) {
  const { t } = useTranslation('global')
  const [viewData, setViewData] = useState(null)
  const { specificCharacteristics: scOptions } = useDefaultData()
  const sourceData: string = 'specificCharacteristics'


  useEffect(() => {
    if (data) {
      const list = MERInformation.find(r => r.code === sourceData)
      const elements = list.items.filter(r => r.visibleWithMethodology.find(x => x === methodology))
      setViewData(elements.filter(x => !x.code.includes('Source')))
    }
  }, [])

  return (
    <div className={styles.merViewerContent} id={sourceData}>
      <div className={styles.sectionTitle} >{t(`update-mer.menu.merInformation.${sourceData}.title`)}</div>
      <div style={{ paddingTop: '40px' }}>
        <div className={styles.specificCharViewer}>
          <div className={styles.tableTitle}>Characteristic</div>
          <div className={styles.tableTitle}>Value</div>
          <div className={styles.tableTitle}>Source</div>
        </div>
        {
          viewData?.map(s => {
            const value = data[s.code]
            const optionValue = value ? scOptions[s.code]?.find(el => el.optionCode === value)?.optionValue ?? '' : null
            const sourceData = data[`${s.code}Source`]
            return (
              <div key={s.code} className={styles.specificCharViewer}>
                <div className={styles.header}>{t(`specificCharacteristics.${methodology}.${s.code}`)}</div>
                <div className={styles.item}>{optionValue}</div>
                <div className={styles.item}>{sourceData ?? ''}</div>
              </div>

            )
          })
        }
      </div>
    </div >)
}
