import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../contexts/language-context';
import styles from './not-allowed.module.css'
import backgroundImage from './../../assets/images/step2-image.png'


export default function NotAllowed() {
  const { t } = useTranslation('global')
  const { language } = useLanguage()

  return (
    <>
      <div className='progress-bar lato'>
      </div>
      <div className='main-content'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className={`pt-64 ${styles.notAllowedContainer}`}>
                <img className={styles.introImage} src={backgroundImage}></img>

                <h1 className={styles.primaryTitle}>{t('notAllowed.text')}</h1>
                
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}
