import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../contexts/language-context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDefaultData } from '../../../contexts/default-data-context'
import { SpecificCharacteristicsOptionsDTO } from '../../../model/dto-module'
import { CodeDescription, TableDetailCell, TableHeaderInterface } from '../../../model/models-module'
import CustomFlag from '../../../components/common/flag/flag'
import { ChildrenTypeEnum } from '../../../configs/data'
import DataTable from '../../../components/common/datatable/Datatable'
import LoadingComponent from '../../../components/common/loading/loading'

import CustomAccordionFilter, { AccordionGroupData } from '../../../components/common/accordionV2/accordion-v2'
import { ReactComponent as ExpandIconDefault } from '../../../assets/icons/expand-icon.svg'
import { ReactComponent as CollapseIconDefault } from '../../../assets/icons/collapse-icon.svg'
import KeyIssueService from '../../../services/key-issue.service'
import DiscussionService from '../../../services/discussion.service'
import { useErrorContext } from '../../../contexts/error-context'
export interface KeyIssueStepInterface {
  step?: any,
  setStep: any,
  plenaries?: any[],
  workingGroups?: any[],
  comparisonId?: number[],
  setComparisonId?: any
}

export default function KeyIssueStepOne(
  { setStep, workingGroups, plenaries, setComparisonId }: KeyIssueStepInterface
) {
  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const { setError: setContextError } = useErrorContext()
  const [filtersData, setFiltersData] = useState<Map<string, any>>(new Map<string, any>())
  const [initialValues, setInitialValues] = useState<Map<string, any>>(undefined)
  const { countries, assessmentBodies, error: filterErrors, specificCharacteristics, rounds, loading: defaultDataLoading } = useDefaultData()
  const [keyIssuesAccordion, setKeyIssuesAccordion] = useState<AccordionGroupData[]>()
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [dataHeader, setDataHeader] = useState<TableHeaderInterface[]>([])
  const [dataTo, setDataTo] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [sortParam, setSortParam] = useState<any>({})
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const keyIssueService = KeyIssueService.getInstance(setContextError)
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingDataGrid, setLoadingDataGrid] = useState<boolean>(true);
  const [enabledButton, setEnabledButton] = useState<boolean>(true);
  const [recommendationsList, setRecommendationList] = useState([]);
  const [immediateOutcomeList, setImmediateOutcomeList] = useState([]);
  const [discussionService] = useState(DiscussionService.getInstance(setContextError));
  const [rowSelection, setRowSelection] = useState<Map<any, any>>(new Map());
  const [initialDataChecked, setInitialDataChecked] = useState<Map<any, any>>();



  const findElementList = useCallback(async (element: string) => {
    return await discussionService.findElementList(appLanguage, element);
  }, []);

  useEffect(() => {
    Promise.all(
      [discussionService.findElementList(appLanguage, 'recommendation'),
      discussionService.findElementList(appLanguage, 'immediateOutcome')
      ]).then(([recommendations, immediateOutcomeList]) => {
        setRecommendationList(recommendations.map(l => { return { id: l.id, code: l.code, description: l.description } }));
        setImmediateOutcomeList(immediateOutcomeList.map(l => { return { id: l.id, code: l.code, description: l.description } }));
      })
  }, [appLanguage])

  const onSort = (key: string, order: string) => {
    const oldValue = { ...sortParam }
    setSortParam(Object.assign(oldValue, { [key]: order }))
  }

  const getDataTable = useCallback((
    { params, lang, limit, offset, sort }: { params?: Map<string, string[]>, sort, lang?, limit?, offset?} =
      { params: filtersData, limit: dataTo, offset: dataOffset, sort: sortParam }) => {

    localStorage.setItem('keyIssueFilters', JSON.stringify({ params: Object.fromEntries(params), limit, offset, sort }));

    setLoadingDataGrid(true);
    let args = ''
    params.forEach((value, key) => {
      if (value && value.length > 0) {
        if (args != '') {
          args += `,${key}:${value.join(':')}`;
        } else {
          args += `${key}:${value.join(':')}`;
        }
      }
    });
    let sortArg = ''
    for (const key in sort) {
      if (sort[key] && sort[key] !== '') {
        if (sortArg === '') {
          sortArg += `${key}:${sort[key]}`
        }
        else {
          sortArg += `,${key}:${sort[key]}`
        }
      }
    }

    keyIssueService.findAll({ args, lang: lang ?? appLanguage, limit, offset, sort: sortArg }).then((data) => {
      const cells = data.results.map((r, i) => {
        const date = new Date(r.plenary);
        return new TableDetailCell(r, undefined, new Map(
          [
            ['countryName', <CustomFlag key={i} code={r.countryCode} flagName={r.countryName} />],
            ['plenary', <span key={i}>
              {`${t(`months.${date.getMonth() + 1}`)}  ${date.getFullYear()}`}
            </span>],
          ]
        ))
      });
      setCount(data.count);
      setDataShow(cells);

      const checked = new Map();
      const selection = [];
      cells.forEach((ds, idx) => {
        if (rowSelection.has(ds.data.kitId)) {
          checked.set(idx, ds);
          selection.push(ds.data.kitId)
        }
      });
      setInitialDataChecked(checked);
      setEnabledButton(rowSelection.size !== 0);
    }).finally(() => { setLoadingDataGrid(false) });

  }, [appLanguage]);

  const headerStyle = {
    minWidth: '150px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    textAlign: 'left',
    paddingTop: '15px',
    paddingBottom: '15px',
  }

  const parseData = () => {
    const map = new Map()

    map.set('immediateOutcome', immediateOutcomeList)
    map.set('recommendation', recommendationsList)
    return map
  }
  const handleCheckboxSelection = (row: Map<number, any>) => {
    const aux: number[] = []
    row.forEach(el => {
      const id = el.data.kitId
      aux.push(id)
    })
    setComparisonId(aux)
  }

  useMemo(() => {
    // valido alguno de los filtros para ver que esten en el contexto
    if (!defaultDataLoading && assessmentBodies !== null && recommendationsList.length > 0) {
      setKeyIssuesAccordion([
        {
          customKey: 'assessment_body',
          parentDictionaryKey: 'keyIssueList.menu.assessmentBody',
          summary: t('keyIssueList.menu.assessmentBody'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: assessmentBodies.map(ab => new CodeDescription(ab.id, ab.id, ab.assessmentBodyName))
        },
        {
          customKey: 'country',
          parentDictionaryKey: 'keyIssueList.menu.country',
          summary: t('keyIssueList.menu.country'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: countries.map(c => new CodeDescription(c.id, c.countryCode, c.countryName))
        },
        {
          customKey: 'workingGroup',
          parentDictionaryKey: 'keyIssueList.menu.workingGroup',
          summary: t('keyIssueList.menu.workingGroup'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: workingGroups.map((wg, i) => {

            return new CodeDescription(wg.id, wg.id.toString(), wg.groupName.toString());
          })
        },
        {
          customKey: 'plenary',
          parentDictionaryKey: 'keyIssueList.menu.plenary',
          summary: t('keyIssueList.menu.plenary'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: plenaries.map((pl, i) => {
            const date = new Date(pl);
            const month = `0${(date.getMonth() + 1).toString()}`;
            const day = `0${(date.getDate()).toString()}`;
            return new CodeDescription(i, `${day.length > 2 ? day.substring(1, 3) : day}/${month.length > 2 ? month.substring(1, 3) : month}/${date.getFullYear()}`, `${t(`months.${date.getMonth() + 1}`)}  ${date.getFullYear()}`);
          })
        },
        {
          customKey: 'keyIssue',
          parentDictionaryKey: 'keyIssueList.menu.keyIssue',
          summary: t('keyIssueList.menu.keyIssue.title'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: parseData()
        },
        {
          customKey: 'specificCharacteristics',
          parentDictionaryKey: 'keyIssueList.menu.specificCharacteristics',
          summary: t('keyIssueList.menu.specificCharacteristics.title'),
          elementType: ChildrenTypeEnum.CHECKBOX,
          expandIcon: ExpandIconDefault,
          collapseIcon: CollapseIconDefault,
          data: SpecificCharacteristicsOptionsDTO.fromData(specificCharacteristics)
        }
      ]);
      setLoadingData(false);
    }
  }, [recommendationsList, defaultDataLoading, appLanguage])

  useMemo(() => {
    const { params: auxParams, limit, offset, sort } = JSON.parse(localStorage.getItem('keyIssueFilters')) ?? {};
    const params = new Map();
    for (const prop in auxParams) {
      params.set(prop, auxParams[prop]);
    }
    setInitialValues(params ?? new Map())
    setFiltersData(params ?? new Map());
    setDataOffset(offset ?? 0);
    setDataTo(limit ?? 10);
    const auxOffset: number = offset ? offset + 1 : 1
    setPage(auxOffset);
    setSortParam(sort ?? new Map());
    getDataTable({ params: params ?? new Map(), limit: limit ?? 10, offset: offset ?? 0, sort: sort ?? new Map() });
  }, [appLanguage])

  useMemo(() => {
    // Inicializo la cabecera 1 vez, solo se refresca si cambia el idioma.
    setDataHeader([
      {
        label: t('keyIssueList.table.columns.country'),
        key: 'countryName',
        sorteable: true,
        headerStyle,
      },
      {
        label: t('keyIssueList.table.columns.assessmentBody'),
        key: 'assessmentBodyName',
        sorteable: true,
        headerStyle,
      },
      {
        label: t('keyIssueList.table.columns.language'),
        key: 'discussionLanguage',
        sorteable: true,
        headerStyle,
      },
      {
        label: t('keyIssueList.table.columns.workingGroup'),
        key: 'workingGroup',
        sorteable: true,
        headerStyle,
      },
      {
        label: t('keyIssueList.table.columns.plenary'),
        key: 'plenary',
        sorteable: true,
        headerStyle: {
          ...headerStyle,
          minWidth: '130px',
        },
      },
      {
        label: t('keyIssueList.table.columns.recIo'),
        key: 'recIo',
        sorteable: true,
        headerStyle,
      },
    ])
  }, [appLanguage]);

  useEffect(() => {
    if (sortParam)
      getDataTable({ params: filtersData, sort: sortParam });
  }, [sortParam])

  const handleRowSelected = useCallback((row: TableDetailCell) => {
    const auxMap = rowSelection;
    if (auxMap.has(row.data.kitId)) {
      auxMap.delete(row.data.kitId);
    } else {
      auxMap.set(row.data.kitId, row);
    }
    const selection = [];
    auxMap.forEach((val) => {
      selection.push(val.data.kitId);
    })
    setComparisonId(selection)
    setRowSelection(auxMap);
    setEnabledButton(auxMap.size !== 0);
  }, [rowSelection])

  const handleAllRowsSelected = useCallback((rows: TableDetailCell[]) => {
    const auxMap = rowSelection;
    if (rows.length > 0) {
      rows.forEach((row) => {
        auxMap.set(row.data.kitId, row);
      })
    } else {
      dataShow.forEach((ds) => {
        if (auxMap.has(ds.data.kitId)) {
          auxMap.delete(ds.data.kitId);
        }
      })
    }

    const selection = [];
    auxMap.forEach((val) => {
      selection.push(val.data.kitId);
    })
    setComparisonId(selection)
    setRowSelection(auxMap);
    setEnabledButton(auxMap.size !== 0);
  }, [rowSelection, dataShow])


  return (
    <>
      <CustomAccordionFilter
        data={keyIssuesAccordion}
        initialValues={initialValues}
        loadingData={loadingData || initialValues === undefined}
        onSelectableChange={(dataSelected) => {
          setFiltersData(dataSelected);
          setDataOffset(0);
          setPage(1);
          getDataTable({ params: dataSelected, sort: sortParam });
        }}
        withChips={true}
      />
      {/* sort {JSON.stringify(sortParam)} */}
      <div className='page-content'>

        <div className='table'>
          {loadingDataGrid ? (
            <LoadingComponent />
          ) :
            <>
              <div className={'container-abm-page'}>
                <div className={'table-templates-container'}>
                  <DataTable
                    dataCheckedList={initialDataChecked}
                    headers={dataHeader}
                    rows={dataShow}
                    countItems={count}
                    rowsPerPage={dataTo}
                    hasActions={false}
                    appendOnScrollToBottom={false}
                    onPageSizeChange={(size) => {
                      setDataTo(size)
                      setDataOffset(0)
                      getDataTable({ params: filtersData, limit: size, offset: 0, sort: sortParam });
                    }}
                    onPageChange={(newPage: number) => {

                      if (newPage !== page) {
                        setPage(newPage)
                        setDataOffset(newPage - 1);
                        getDataTable({ params: filtersData, offset: newPage - 1, limit: dataTo, sort: sortParam });
                      }
                    }}
                    onSortChange={onSort}
                    pageSelected={page}
                    pageable={true}
                    selectable={true}
                    onSelectAllRows={handleAllRowsSelected}
                    onSelectRow={handleRowSelected}
                    // onSelectableChange={(selectedRows) => {
                    //   setEnabledButton(selectedRows.size !== 0)
                    //   handleCheckboxSelection(selectedRows)
                    // }}
                    className=' thinner'
                  ></DataTable>

                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='buttons-section d-flex-center' >

                    <button className={`${!enabledButton ? 'disabled-button' : 'blue-button '} basic-button mr-20  lato`}
                      onClick={() => { setStep(2) }}
                      disabled={!enabledButton}
                    >
                      {t('buttons.continue')}
                    </button>
                  </div>
                </div>
              </div>
            </>}
        </div>

      </div>

    </>
  )
}
