import './step-two.css'

import { useEffect, useState } from 'react'

import { ChildrenTypeEnum } from '../../../../../configs/data'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-icon.svg'
import { ReactComponent as CollapseIcon } from '../../../../../assets/icons/minus-icon.svg'
import CustomAccordion from '../../../../../components/common/accordion/accordion'
import ExpandIcon from '../../../../../components/common/icon/plus-icon/plus-icon'
import MainLeftMenu from '../../../../../components/layout/left-menu/left-menu'
import backgroundImage from '../../../../../assets/images/step2-image.png'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../../../contexts/language-context'
import { LanguagesEnum } from '../../../../../enums/mer-status.enum'

interface Props {
  canChangeStep: any
  setCanChangeStep: any
  setSelectedHeaders: any
  setStep: any
  handleContinue: any
}

export default function CustomizedReportStepTwo({
  canChangeStep,
  setCanChangeStep,
  setSelectedHeaders,
  setStep,
  handleContinue,
}: Props) {
  const { t } = useTranslation('global')
  const [filtersData, setFiltersData] = useState<{ key: string, values: any[] }[]>([])
  const { language: appLanguage } = useLanguage()
  const [menu, setMenu] = useState<any[]>(null)

  useEffect(() => {
    const iOLangCode = appLanguage === LanguagesEnum.EN ? 'IO' : 'RI'

    const techComplianceOptions = [];
    for (let index = 1; index <= 40; index++) {
      techComplianceOptions.push({
        label: `${t('recommendation.title')} ${index}`,
        value: `R.${index}`,
      })
    }

    const effectivenessOptions = []
    for (let index = 1; index <= 11; index++) {
      effectivenessOptions.push({
        label: `${t('immediateOutcome.title')} ${index}`,
        value: `${iOLangCode}.${index}`,
      })
    }

    setMenu([
      {
        expandIcon: ExpandIcon,
        collapseIcon: CollapseIcon,
        elementType: ChildrenTypeEnum.CHECKBOX,
        summary: t('reportAndStatistics.customizedReports.report.effectiveness'),
        detailList: effectivenessOptions,
        customKey: 'effectiveness',
        selected: undefined,
      },
      {
        expandIcon: ExpandIcon,
        collapseIcon: CollapseIcon,
        elementType: ChildrenTypeEnum.CHECKBOX,
        summary: t('reportAndStatistics.customizedReports.report.technicalCompliance'),
        detailList: techComplianceOptions,
        customKey: 'technicalCompliance',
        selected: undefined,
      }
    ])
  }, [appLanguage])

  const onReturnStepOne = () => {
    setSelectedHeaders()
    setStep(1)
  }

  const findChildLabel = (key: string, valueFind: string) => {
    const el = menu.find(r => r.customKey === key)
    return el.detailList.find(r => r.value === valueFind).label
  }

  const selectedFields = (key: string, values: string[] | undefined) => {
    const selected = []
    values?.forEach((value) => {
      selected.push(findChildLabel(key, value))
    })
    return selected
  }

  useEffect(() => {
    setCanChangeStep(false)
    filtersData.forEach((filter) => {
      if (filter.values.length >= 1) setCanChangeStep(true)
    })

  }, [filtersData])


  return (
    <div className='main-content'>
      <MainLeftMenu>
        {
          menu && menu?.map((item, i) => {
            return (
              <CustomAccordion
                customKey={i}
                key={i}
                summary={t(item.summary)}
                detailList={item.detailList?.map(r => r.label)}
                selected={selectedFields(item.customKey, filtersData.find(r => r.key === item.customKey)?.values) ?? []}
                filterElementType={item.elementType}
                onClickChildren={(selected: { index: number, checked: boolean }) => {
                  const key = item.customKey
                  const value = item.detailList[selected.index].value
                  const checked = selected.checked
                  setFiltersData((oldFilters) => {
                    const newFilters = [...oldFilters]
                    if (checked) {
                      const el = oldFilters.find(r => r.key === key)
                      if (el) {
                        if (!el.values.includes(value)) el.values.push(value)
                      } else {
                        newFilters.push({ key, values: [value] })
                      }
                    } else {
                      const el = oldFilters.find(r => r.key === key)
                      el.values = el.values.filter(r => r !== value)
                    }
                    return newFilters
                  })
                }}
              />
            )
          })
        }
      </MainLeftMenu>
      <div className='page-content'>
        <div className='step-two-container'>
          <div className={filtersData.length >= 1 ? 'pre-selection pt-34' : 'pre-selection pt-64'}>
            <img src={backgroundImage}></img>
            <h1 className={filtersData.length >= 1 ? 'smaller pt-34 primary-title' : 'primary-title'}>
              {t('reportAndStatistics.customizedReports.report.titleReport')}
            </h1>
            {filtersData.length == 0 && (
              <div className='primary-description'>
                {t('reportAndStatistics.customizedReports.report.subtitleReport')}
              </div>
            )}
            {filtersData.length >= 1 && (
              <div className='resume-container'>
                <div className='resume'>
                  {filtersData.map((item, i) => {
                    if (item?.values.length != 0)
                      return (
                        <div key={i} className='resume-item'>
                          <div className='resume-first-level'>
                            <div className='content'>
                              <div className='resume-level-text'>{t(`reportAndStatistics.customizedReports.report.${item.key}`)}</div>
                              <div>
                                <CloseIcon onClick={() => {
                                  setFiltersData((oldFilters) => {
                                    return oldFilters.filter(r => r.key !== item.key)
                                  })
                                }} />
                              </div>
                            </div>
                          </div>
                          <div className='resume-wrap'>
                            {item?.values &&
                              item.values.map((child, j) => {
                                return (
                                  <div key={j} className='resume-second-level'>
                                    <div className='separator'></div>
                                    <div className='content'>
                                      <div className='resume-level-text'>{findChildLabel(item.key, child)}</div>
                                      <div>
                                        <CloseIcon onClick={() => {
                                          setFiltersData((prevState) => {
                                            const el = prevState.find(r => r.key === item.key)
                                            const newList = prevState.filter(r => r.key !== item.key)
                                            el.values = el.values.filter(r => r !== child)
                                            newList.push(el)
                                            return newList
                                          })
                                        }} />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      )
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='buttons-section'>
            <button className='mr-20 basic-button blue-button lato' onClick={onReturnStepOne}>
              {t('buttons.return')}
            </button>
            <button
              onClick={() => {
                handleContinue()
                setSelectedHeaders(filtersData)
              }}
              className={`basic-button ${canChangeStep ? 'blue-button' : 'disabled-button'} lato`}
            >
              {t('buttons.continue')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
