export class KeyIssue {
    id: number;
    auxEl?: any;
    elementCode?: string;
    element?: string;
    keyIssue?: string;
    relevantDecisionsWorkingGroup?: string;
    keyIssueDescription?: string;
    relevantDecisionsPlenary?: string;

    constructor({id, element, elementCode, keyIssue, relevantDecisionsWorkingGroup, keyIssueDescription, relevantDecisionsPlenary}: KeyIssue = {id: undefined, element: ''}) {
        this.id = id;
        this.element = element;
        this.elementCode = elementCode;
        this.keyIssue = keyIssue ?? '';
        this.relevantDecisionsWorkingGroup = relevantDecisionsWorkingGroup ?? '';
        this.keyIssueDescription = keyIssueDescription ?? '';
        this.relevantDecisionsPlenary = relevantDecisionsPlenary ?? '';
    }
}