import './navbar.css'

import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as EngLanguage } from '../../../assets/icons/eng-flag.svg'
import { ReactComponent as EsLanguage } from '../../../assets/icons/es-flag.svg'
import UserService from '../../../services/UserService'
import logo from '../../../assets/images/layout/logo-navbar.png'
import { useLanguage } from '../../../contexts/language-context'
import { useTranslation } from 'react-i18next'
import userImage from '../../../assets/images/layout/user-img.png'
import { ReactComponent as DownArrowIcon } from './../../../assets/icons/up-arrow.svg'
import { ReactComponent as UpArrowIcon } from './../../../assets/icons/down-arrow.svg'
import { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Menu } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { USER_ADMIN_ROLE } from '../../common/constant/constants'
import { ReactComponent as InfoIcon } from './../../../assets/icons/info-icon.svg'
import InfoModal from '../../../pages/info/infoModal'


export default function Navbar() {
  const { language, changeLang } = useLanguage()
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [showAllLanguages, setShowAllLanguages] = useState<boolean>(false)
  const [username] = useState<string>(UserService.getUsername());
  const [email] = useState<string>(UserService.getEmail());
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [canEdit] = useState<boolean>(UserService.hasRole([USER_ADMIN_ROLE]));
  const divRef = useRef<HTMLDivElement>(null)

  const handleNavigate = (route: string) => {
    localStorage.setItem('lastPath', pathname + search)
    navigate(route)
  }
  const selectedLanguage = language === 'es' ? 'ESP' : 'ENG'

  const changeLanguage = (lang: 'es' | 'en') => {
    setShowAllLanguages(false)
    changeLang(lang)
  }

  const handleCloseUserMenu = (setting: string) => {
    setAnchorElUser(null)
    switch (setting) {
      case 'Logout':
        UserService.doLogout()
        break
      default:
        break
    }
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleClickOutside = (event: MouseEvent) => {
    if ((divRef.current && !divRef.current.contains(event.target as Node))) {
      setShowAllLanguages(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleInfoModal = () => {
    setOpenInfoModal(true)
  }
  return (
    <>
      <InfoModal open={openInfoModal} setOpen={setOpenInfoModal} />
      <div className='navbar'>
        <img
          alt='logo'
          className='navbar-logo'
          src={logo}
          onClick={() => handleNavigate('/main')}
        ></img>
        <ul className='menu'>
          <li className='first-item'>
            <a onClick={() => handleNavigate('/main/mer-fur')}>{t('navbar.mer-fur')}</a>
          </li>
          <li>
            <a onClick={() => handleNavigate('/main/comparison-tool')}>
              {t('navbar.comparison-tool')}{' '}
            </a>
          </li>
          <li>
            <a onClick={() => canEdit ? handleNavigate('/main/discussions-menu') : handleNavigate('/main/key-issues')}>
              {t('navbar.key-issues-antecedents')}
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigate('/main/consolidate-ratings')}>
              {t('navbar.consolidated-ratings')}
            </a>
          </li>
          <li>
            <a onClick={() => handleNavigate('/main/reports')}>{t('navbar.reports')}</a>
          </li>
        </ul>
        <div className='user-info'>
          <div className='info-section' onClick={handleInfoModal}>
            <InfoIcon />
          </div>
          <div className='language-section-wrapper'>
            {/* {!showAllLanguages ? */}

            <div className={`${showAllLanguages && 'hidden'} show-selected-language`} onClick={() => { setShowAllLanguages(true) }}>
              <span className='lang-img' />
              {language === 'es' ? <EsLanguage className='lang-img ' /> : <EngLanguage className='lang-img ' />}
              {selectedLanguage}
              <UpArrowIcon className='pl-15' />
            </div>
            <div className={`${!showAllLanguages && 'hidden'} language`} ref={divRef}>
              <div className='language-wrapper'>
                <div className='selected-language' onClick={() => { changeLanguage('en') }} >
                  <EngLanguage className='lang-img ' /> ENG
                  <DownArrowIcon className='pl-15' />
                </div>
                <div className='selected-language mt-20' onClick={() => changeLanguage('es')}>
                  <EsLanguage className='lang-img ' /> ESP
                </div>
              </div>

            </div>
            {/* } */}
          </div>
          <div className='user-name c-pointer'>
            <img src={userImage} alt='user-img' onClick={handleOpenUserMenu} />
            <span>{t('navbar.welcome', { name: username.split(' ')[0] })}</span>
          </div>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            className='menu-user'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            <div className='user-info'>
              <div className='profile-pic-container'>
                <Avatar className='profile-pic' style={{ borderRadius: '10px' }} alt={username} src={userImage} />
              </div>
              <div className='profile-detail-container'>
                <span className='profile-detail-name'>{username}</span>
                <span className='profile-detail-email'>{email}</span>
              </div>
            </div>

            <div className='menu-user-buttons-container'>
              <Button variant="contained"
                className='lato'
                sx={{ backgroundColor: '#426BBA' }}
                onClick={() => handleCloseUserMenu('Logout')}>
                <PowerSettingsNewIcon />{t('navbar.logOut')}
              </Button>
            </div>
          </Menu>
        </div>
      </div>
    </>
  )
}
