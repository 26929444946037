import {
  ChildrenTypeEnum,
} from './data'

export const merMenuConfig:
  { summary: string, elementType: ChildrenTypeEnum, detailList?: any[], customKey: string, selected?: any }[] = [
    {
      summary: 'merFurList.menu.assessmentBody',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'assessment_body'
    },
    {
      summary: 'merFurList.menu.round',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'round'
    },
    {
      summary: 'merFurList.menu.date',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'date'
    },
    {
      summary: 'merFurList.menu.country',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'countryCode'
    },
    {
      summary: 'merFurList.menu.reportType.title',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'type'
    },
    {
      summary: 'merFurList.menu.language.title',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'language'
    },
    {
      summary: 'merFurList.menu.status.title',
      elementType: ChildrenTypeEnum.CHECKBOX,
      customKey: 'status'
    },

  ]
