/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useCallback, useEffect, useState } from 'react'
import classes from '../NewKeyIssue.module.css'
import { FormControl, FormHelperText, Input, InputLabel, MenuItem, Select } from '@mui/material';
import WizardStep from '../../../../../components/common/wizard/WizardStep';
import { CodeDescription } from '../../../../../model/models-module';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { KeyIssue } from '../dto/key-issue-dto';
import { Controller } from 'react-hook-form';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';

type Props = {
  value: KeyIssue;
  isEdit: boolean;
  onAfterSubmit(values: any): void;
  onAfterBack(): void;
}

const NewKeyIssueStepTwo = ({ value, isEdit, onAfterSubmit, onAfterBack }: Props) => {
  const refSubmit = createRef<HTMLButtonElement>();
  const [formValue, setFormValue] = useState<KeyIssue>(value);
  const { t } = useTranslation('global');



  return (
    <WizardStep btnCancelDisabled={false} onNext={() => { if (refSubmit && refSubmit.current) refSubmit.current.click() }} onBack={() => { onAfterBack() }} btnBackDisabled={true} btnNextDisabled={false} btnEndDisabled={true}>


      <Formik
        initialValues={formValue}
        validate={values => {
          const errors: any = {};

          if (!values.relevantDecisionsWorkingGroup! || values.relevantDecisionsWorkingGroup === '<p><br></p>') {
            errors.relevantDecisionsWorkingGroup = t('validations.required');
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onAfterSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          errors,
          touched,
          /* and other goodies */
        }) => (

          <form onSubmit={handleSubmit} style={{ marginTop: '25px' }}>
            <div className={`${classes.formContainer} nwsn-scroll`}>
              <div className={classes.webContainer}>
                <div className={`${classes.segment} col-12`}>
                  <FormControl error={errors.relevantDecisionsWorkingGroup !== undefined} fullWidth>
                    <div className={classes.elementTitle}>{t('createUpdateDiscussion.relevant-decisions-workinggroup')}</div>
                    <FormHelperText>{errors && errors.relevantDecisionsWorkingGroup && errors.relevantDecisionsWorkingGroup}</FormHelperText>
                    <RichTextArea
                      className={errors && errors.relevantDecisionsWorkingGroup ? classes.textAreaError : classes.textArea}
                      value={values.relevantDecisionsWorkingGroup}
                      onChange={(value) => { setFieldValue('relevantDecisionsWorkingGroup', value) }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <button type="submit" ref={refSubmit} disabled={isSubmitting} style={{ display: 'none' }}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </WizardStep>
  )
}


export default NewKeyIssueStepTwo;
