import React, { createContext, useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'

interface LanguageContextType {
  language: string
  changeLang: (newLang: 'es' | 'en') => void
}

interface LanguageContextProps {
  children: React.ReactNode
}

const defaultLang = 'en'
const LanguageContext = createContext<LanguageContextType>({
  language: defaultLang,
  changeLang: (newLang: 'es' | 'en') => { }
})

export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider = ({ children }: LanguageContextProps) => {
  const [language, setLanguage] = useState(defaultLang)
  const { i18n } = useTranslation('global')

  const changeLang = (newLang: 'es' | 'en') => {
    setLanguage(newLang)
    i18n.changeLanguage(newLang)
  }

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLang,
      }}>
      {children}
    </LanguageContext.Provider>
  )
}
