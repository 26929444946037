/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';
export default class DefaultDataService extends BaseService {

  private static _instance: DefaultDataService;
  baseEndpoint = 'default'

  public static getInstance(setError: (message: string | null) => void): DefaultDataService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async findAll(language: string, entity: string): Promise<any[]> {
    try {
      const { data: result } = await this.client({ url: `${language}/${this.baseEndpoint}/${entity}`, method: 'GET' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }
  async getInfoPDF(language: string): Promise<any> {
    try {
      const result = await this.client({ url: `${language}/${this.baseEndpoint}/info-pdf`, method: 'GET', responseType: 'blob' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async getMerYears(lang: string): Promise<any[]> {
    try {
      const { data: result } = await this.client({ url: `${lang}/mer/mer-years`, method: 'GET' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

}
