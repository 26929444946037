import './menu.css'

import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as ComparisonIcon } from '../../../assets/icons/icono-comparison.svg'
import { ReactComponent as ConsolidatedIcon } from '../../../assets/icons/icono-rating.svg'
import { ReactComponent as KeyIcon } from '../../../assets/icons/icono-key.svg'
import { ReactComponent as MerIcon } from '../../../assets/icons/icono-mer.svg'
import Navbar from '../navbar/navbar'
import { ReactComponent as ReportIcon } from '../../../assets/icons/icono-report.svg'
import { useTranslation } from 'react-i18next'
import UserService from '../../../services/UserService'
import { USER_ADMIN_ROLE } from '../../common/constant/constants'
import { useState } from 'react'

export default function Menu() {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [isAdmin] = useState<boolean>(UserService.hasRole([USER_ADMIN_ROLE]));

  const onNavigate = (route: string) => {
    localStorage.setItem('lastPath', pathname + search)
    navigate(route)
  }

  return (
    <>
      <div className='main-menu'>
        <div className='menu-item' onClick={() => onNavigate('/main/mer-fur')}>
          <div className='wrapper'>
            <MerIcon />
            <p>{t('navbar.mer-fur')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => onNavigate('/main/comparison-tool')}>
          <div className='wrapper'>
            <ComparisonIcon />
            <p>{t('navbar.comparison-tool')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => isAdmin ? onNavigate('/main/discussions-menu') : onNavigate('/main/key-issues')}>
          <div className='wrapper'>
            <KeyIcon />
            <p>{t('navbar.key-issues-antecedents')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => onNavigate('/main/consolidate-ratings')}>
          <div className='wrapper'>
            <ConsolidatedIcon />
            <p>{t('navbar.consolidated-ratings')}</p>
          </div>
        </div>
        <div className='menu-item' onClick={() => onNavigate('/main/reports')}>
          <div className='wrapper'>
            <ReportIcon />
            <p>{t('navbar.reports')}</p>
          </div>
        </div>
      </div>
    </>
  )
}
