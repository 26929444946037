import classes from './step-three.module.css'

import React, { useEffect, useState } from 'react'
import CalificationTable from '../../../../../components/common/calificationTable/Calificationtable'
import { ConsolidatedRatingsResponse, TableDetailCell, TableHeaderCell } from '../../../../../model/models-module';
import MainLeftMenu from '../../../../../components/layout/left-menu/left-menu';
import { ReactComponent as ColumnGraphIcon } from '../../../../../assets/icons/column-graph-icon.svg'
import { ReactComponent as RowGraphIcon } from '../../../../../assets/icons/row-graph-icon.svg'
import { ReactComponent as CakeGraphIcon } from '../../../../../assets/icons/cake-graph-icon.svg'
import { ReactComponent as DonutGraphIcon } from '../../../../../assets/icons/donut-graph-icon.svg'
import { addParam, handleFile } from '../../../../../utils/CommonUtils';
import { useLanguage } from '../../../../../contexts/language-context';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { ReactComponent as XlsIcon } from '../../../../../assets/icons/xls-icon.svg';
import { useTranslation } from 'react-i18next';
import { LanguagesEnum } from '../../../../../enums/mer-status.enum';
import ConsolidateRatingService from '../../../../../services/consolidate-ratings.service';
import { useData } from '../../../../../components/hooks/useData';
import { useErrorContext } from '../../../../../contexts/error-context';

interface Props {
  selectedHeaders: any
  selectedCountries: any
  selectedRound: any
  setSelectedTypeGraph: any
  setSelectedCalifications: any,
  setStep: any,
  handleContinue: any
}

export default function CustomizedReportsStepThree({
  selectedCountries,
  selectedHeaders,
  selectedRound,
  setSelectedTypeGraph,
  setSelectedCalifications,
  setStep,
  handleContinue,
}: Props) {
  const { language: appLanguage } = useLanguage()
  const { setError: setContextError } = useErrorContext()
  const { t } = useTranslation('global')
  const [dataHeaders, setDataHeaders] = useState<TableHeaderCell[]>([])
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
  const [roundFilter] = useState<string>(selectedRound);
  const [countries, setCountries] = useState<string[]>([]);
  const [baseEndpoint] = useState<string>(`consolidate/${roundFilter}`);
  const [endpoint, setEndpoint] = useState<string>(null);
  const [loading, error, dataGrid] = useData<ConsolidatedRatingsResponse[]>(endpoint, 'GET')

  const [params] = useState<Map<string, string>>(new Map())
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const consolidateRatingsService = ConsolidateRatingService.getInstance(setContextError)

  const colStyle = {
    maxWidth: '230px',
    display: 'flex',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    alignItems: 'center',
    color: '#003479',
    background: '#F1F1F1',
    borderRadius: '6px',
    paddingTop: '15px',
    paddingBottom: '15px',
  }

  const headerStyle = {
    minWidth: '230px',
    maxWidth: '230px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    textAlign: 'left',
    paddingTop: '15px',
    paddingBottom: '15px',
    background: '#5ECDE9',
    borderRadius: '6px'
  }

  const calificationRow = {
    minWidth: '105px',
    maxWidth: '105px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    paddingTop: '15px',
    paddingBottom: '15px',
    background: '#5ECDE9',
    borderRadius: '6px'
  }

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])



  useEffect(() => {
    const newCountries = []
    selectedCountries.forEach((countriesCell) => {
      newCountries.push(countriesCell.data.countryCode)
    })
    setCountries(newCountries)
  }, [selectedCountries])


  useEffect(() => {
    const newHeaders: TableHeaderCell[] = []
    newHeaders.push({
      label: t('consolidateRatings.table.country'),
      key: 'countryName',
      sorteable: false,
      headerStyle,
      colStyle
    })

    const iOCode = appLanguage === LanguagesEnum.EN ? 'IO' : 'RI'

    selectedHeaders.forEach((header) => {
      header.values.forEach((value) => {
        let label = value
        if (value.includes('RI') || value.includes('IO')) {
          const code = value.split('.')[1]
          label = `${iOCode}.${code}`
        }
        newHeaders.push({
          label,
          key: label,
          sorteable: false,
          headerStyle: calificationRow,
          colStyle
        })
      })
    })
    setDataHeaders(newHeaders)
  }, [selectedHeaders, appLanguage])


  useEffect(() => {
    if (baseEndpoint) {
      addParam(appLanguage, 'lang', params, baseEndpoint, setEndpoint)
    }
  }, [baseEndpoint, appLanguage])

  useEffect(() => {
    if (baseEndpoint && countries?.length != 0) {
      addParam(countries, 'countries', params, baseEndpoint, setEndpoint)
    } else {
      addParam(undefined, 'countries', params, baseEndpoint, setEndpoint)
    }
  }, [baseEndpoint, countries])

  const renderResultsTable = (list: ConsolidatedRatingsResponse[]) => {
    return list?.map((el, index) => {
      const newItems = []
      dataHeaders?.forEach((headers, i) => {
        const rating = el.technicalCompliances.find(tech => tech.title == headers.label) ?? el.effectiveness.find(tech => tech.title == headers.label)
        if (rating) {
          const label = rating.title
          const color = rating.furId ? rating.lastAssessmentColor : rating.originalAssessmentColor
          const element = <span key={headers.label} className={classes.ratingTableCell} style={{ background: color, border: rating.furId != null ? '3px solid #4D4B4B' : null }} >{rating.furId != null ? rating.lastAssessmentShortDesc : rating.originalAssessmentShortDesc}</span>
          newItems.push([label, element])
        }
      })
      return new TableDetailCell({ countryName: el.countryDesc }, null, new Map<string, React.ReactNode>(newItems),)
    })
  }

  useEffect(() => {
    if (dataGrid) {
      setDataShow(renderResultsTable(dataGrid))
    }
  }, [dataGrid])

  const findCalifications = () => {
    const califications: { calificationName: string, counter: number, color: string }[] = [];

    dataShow.forEach((country) => {
      Array.from(country.customDefinitions.values()).forEach((component) => {
        if (React.isValidElement(component)) {
          const calificationName = component.props.children;
          const existingCalification = califications.find(calification => calification.calificationName === calificationName);

          if (existingCalification) {
            existingCalification.counter += 1;
          } else {
            califications.push({ calificationName, counter: 1, color: component.props.style.background });
          }
        }
      });
    });

    const result = califications.map((calification, i) => ({
      id: i,
      value: calification.counter,
      label: calification.calificationName,
      color: calification.color
    }));

    return result;
  };


  const onReturnStepOne = () => {
    setStep(2)
  }
  const downloadXLS = async () => {

    try {
      setIsDownload(true)
      const countries = 'countries=' + dataGrid.map(el => el.countryCode).join(',')
      const aux = [...dataHeaders]
      aux.shift()
      const columns = 'columns=' + aux.map(el => el.label).join(',')
      const lang = 'lang=' + appLanguage
      const worksheet = 'worksheet-name=sheet'
      const result = await consolidateRatingsService.downloadXLS(`${lang}&${countries}&${columns}&${worksheet}`, +roundFilter)
      handleFile(result?.data, 'custom-report.xlsx')
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setIsDownload(false)
    }
  }

  return (
    <>
      {loading ? <div className={classes['loadingContainer']}><LoadingComponent /></div> : <div className='main-content'>
        <MainLeftMenu >
          <div className={classes['graphMenuContainer']}>
            <div className={classes['title-menu']}>{t('reportAndStatistics.graphicsData.table.graphs')}</div>
            <DonutGraphIcon className={classes['icon-menu']} onClick={() => {
              setSelectedCalifications(findCalifications())
              setSelectedTypeGraph('donut')
              handleContinue()
            }} />
            <ColumnGraphIcon className={classes['icon-menu']} onClick={() => {
              setSelectedCalifications(findCalifications())
              setSelectedTypeGraph('column')
              handleContinue()
            }} />
            <CakeGraphIcon className={classes['icon-menu']} onClick={() => {
              setSelectedCalifications(findCalifications())
              setSelectedTypeGraph('pie')
              handleContinue()
            }} />
            <RowGraphIcon className={classes['icon-menu']} onClick={() => {
              setSelectedCalifications(findCalifications())
              setSelectedTypeGraph('row')
              handleContinue()
            }} />
          </div>
        </MainLeftMenu>
        <div className={classes['ratingsTable']}>
          {!isDownload ?
            <div className={classes['container-table-page']}>
              <CalificationTable
                headers={dataHeaders}
                rows={dataShow}
                countItems={dataShow.length}
                rowsPerPage={dataShow.length}
                hasActions={false}
                appendOnScrollToBottom={false}
              />
            </div>
            : <LoadingComponent />
          }
          <div className='buttons-section d-flex-center'>
            {!isDownload &&
              <button
                className='mr-20 basic-button-icon blue-button lato svg-button'
                onClick={downloadXLS}
                disabled={!dataGrid}>
                <XlsIcon /> {t('buttons.download')} XLS
              </button>
            }
            <button className=' basic-button blue-button lato' onClick={onReturnStepOne}>
              {t('buttons.return')}
            </button>
          </div>
        </div>
      </div >}
    </>
  )
}
