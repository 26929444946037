import UserService from '../UserService';
import axios from 'axios';

// El que corrija el bug de TS tiene un premio (por eso esta como .js)

const addInterceptor = (baseURL, timeout = null) => {
    const client = axios.create({ baseURL, timeout });
    client.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
            config.headers['Content-Type'] = 'application/json';
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    });
    return client;
}

const AxiosInterceptor = {
    addInterceptor,
}

export default AxiosInterceptor;
