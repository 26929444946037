import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLanguage } from '../../../contexts/language-context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SvgIcon } from '@mui/material'
import LoadingComponent from '../../../components/common/loading/loading'
import backgroundImage from './../../../assets/images/step2-image.png'
import styles from './create-edit-discussion.module.css'
import { TableDetailCell, TableHeaderCell } from '../../../model/models-module'
import { ReactComponent as DeleteIcon } from './../../../assets/icons/delete.svg';
import CustomFlag from '../../../components/common/flag/flag'
import { ReactComponent as FurIcon } from './../../../assets/icons/new-fur-icon.svg'
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg'
import NewKeyIssue from './new-key-issue/NewKeyIssue'
import DataTable from '../../../components/common/datatable/Datatable'
import DiscussionService from '../../../services/discussion.service'
import { handleFile } from '../../../utils/CommonUtils'
import UpdateDiscussionFileModal from './modify-file/update-file-modal'
import { useErrorContext } from '../../../contexts/error-context'


export default function CreateEditDiscussion() {
  const { t } = useTranslation('global')
  const { id, lang } = useParams();
  const { language: appLanguage } = useLanguage()
  const { setError: setContextError } = useErrorContext()
  const [isDownloadPdf, setIsDownloadPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataEdit, setDataEdit] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [dataTo, setDataTo] = useState<number>(5)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [dataGrid, setDataGrid] = useState([]);
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [page, setPage] = useState<number>(1)
  const [discuss, setDiscuss] = useState<any>();
  const [loadingDataGrid, setLoadingDataGrid] = useState<boolean>(true);
  const [discussionService] = useState<DiscussionService>(DiscussionService.getInstance(setContextError));
  const [plenary, setPlenary] = useState<string>('');
  const [editWgFile, setEditWgFile] = useState<boolean>(false);
  const [editCcFile, setEditCcFile] = useState<boolean>(false);
  const [editPlFile, setEditPlFile] = useState<boolean>(false);

  const handleDeleteKI = async (discussionId: number, keyIssueId: number) => {
    try {
      setLoadingDataGrid(true)
      await discussionService.deleteKeyIssue(appLanguage, discussionId, keyIssueId)
      setDataShow((oldValue) => {
        const data = [...oldValue]
        return data.filter(r => r.data.id !== keyIssueId)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingDataGrid(false)
    }
  }
  const mapDataResult = useCallback(({ data = dataGrid, to = dataTo, from = dataOffset }: any) => {
    const regex = /(<([^>]+)>)/gi;
    // Valido el paginado, teniendo en cuenta que tiene que ser mayor que el maximo de la pagina anterior (offset * to) y menor que el hasta mas el max de la pagina anterior.
    setDataShow(data.filter((d, i) => i < ((from * to) + to) && i >= (from * to)).map((r, idx) => new TableDetailCell(r, undefined, new Map([
      ['element', <>{r.keyIssueDesc}</>],
      ['description', <span key={`desc-${r.keyIssueId}`} className={styles.descField}>{(r.keyIssue ?? '').replaceAll(regex, '')}</span>],
      ['actions',
        <div key={`actions-${idx}`}>
          <span style={{ display: 'inline-block', paddingRight: '15px' }}>
            <span key={`action-delete-${idx}`}
              onClick={() => { handleDeleteKI(+id, r.id) }}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#003479',
                cursor: 'pointer'
              }}>
              <SvgIcon component={DeleteIcon} />{t('buttons.delete')}
            </span>
          </span>
          <span style={{ display: 'inline-block' }}>
            <span key={`action-edit-${idx}`}
              onClick={() => { setDataEdit(Object.assign({}, r)); }}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#003479',
                cursor: 'pointer'
              }}>
              <SvgIcon component={EditIcon} />{<span style={{ marginLeft: '5px' }}>
                {t('buttons.edit')}
              </span>
              }
            </span>
          </span>
        </div>
      ],
    ]))));
  }, [dataTo, dataOffset, dataGrid])

  const findAllKeyIssues = useCallback(async () => {
    setLoadingDataGrid(true);
    const auxList = await discussionService.findKeyIssuesByDiscussionId({ lang: lang, discussionTransId: Number(id), appLanguage });
    setCount(auxList.length);

    setDataGrid(auxList);
    mapDataResult({ data: auxList });
    setLoadingDataGrid(false);
  }, [mapDataResult, lang, appLanguage]);

  useMemo(async () => {
    if (id) {
      const auxEl = await discussionService.findDiscussionTranslateById({ lang, discussionTransId: Number(id), appLanguage });
      setDiscuss(auxEl);
      const auxDate = new Date(auxEl.discussion.plenary)
      const userTimezoneOffset = auxDate.getTimezoneOffset() * 60000;
      const date = new Date(auxDate.getTime() + userTimezoneOffset);
      const month = `keyIssueList.table.rows.months.${(date.getMonth() + 1)}`
      const year = date.getFullYear();
      setPlenary(`${t(month)} ${year}`);
      findAllKeyIssues();
    }

  }, [appLanguage]);

  // useEffect(() => {
  //   if (refreshData) {
  //     discussionService.findDiscussionTranslateById({ lang, discussionTransId: Number(id) })
  //       .then(result => {
  //         setDiscuss(result)
  //       })
  //       .catch(err => console.log(err))
  //       .finally(() => setRefreshData(false))
  //   }
  // }, [refreshData])

  const refreshData = useCallback(() => {
    discussionService.findDiscussionTranslateById({ lang, discussionTransId: Number(id), appLanguage })
      .then(result => {
        setDiscuss(result)
      })
      .catch(err => console.log(err))

  }, [appLanguage]);

  useMemo(() => {
    // Inicializo la cabecera 1 vez, solo se refresca si cambia el idioma.
    setDataHeader([
      new TableHeaderCell({ key: 'element', label: t('createUpdateDiscussion.table.element') }),
      new TableHeaderCell({ key: 'description', label: t('createUpdateDiscussion.table.keyIssue') }),
      new TableHeaderCell({ key: 'actions', label: t('createUpdateDiscussion.table.actions'), headerStyle: { minWidth: '180px' } }),
    ]);
  }, [appLanguage]);

  const downloadRelevanWGPdf = useCallback(async () => {
    if (!discuss.urlWorkingGroupFile) return
    try {
      setIsDownloadPdf(true)
      const result = await discussionService.downloadPDF(appLanguage, +id, lang, discuss.urlWorkingGroupFile)
      const round = discuss.discussion.mer.round.round;
      const countryCode = discuss.discussion.mer.country.countryCode;
      const plenary = discuss.discussion.plenary.replaceAll('-', '');
      const filename = `round${round}/${countryCode}/KI-WG-R${round}-${countryCode}-${lang}-${plenary}.pdf`
      handleFile(result.data, filename)
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      // setContextError(error)
    }
    finally {
      setIsDownloadPdf(false)
    }
  }, [discuss]);

  const downloadRelevanPlPdf = useCallback(async () => {
    if (!discuss.urlPlenaryile) return
    try {
      setIsDownloadPdf(true)
      const round = discuss.discussion.mer.round.round;
      const countryCode = discuss.discussion.mer.country.countryCode;
      const plenary = discuss.discussion.plenary.replaceAll('-', '');
      const result = await discussionService.downloadPDF(appLanguage, +id, lang, discuss.urlPlenaryile)
      const filename = `round${round}/${countryCode}/KI-PL-R${round}-${countryCode}-${lang}-${plenary}.pdf`
      handleFile(result.data, filename)
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      // setContextError(error)
    }
    finally {
      setIsDownloadPdf(false)
    }
  }, [discuss]);

  const downloadRelevanCcPdf = useCallback(async () => {
    if (!discuss.urlCoChairsFile) return
    try {
      setIsDownloadPdf(true)
      const round = discuss.discussion.mer.round.round;
      const countryCode = discuss.discussion.mer.country.countryCode;
      const plenary = discuss.discussion.plenary.replaceAll('-', '');
      const result = await discussionService.downloadPDF(appLanguage, +id, lang, discuss.urlCoChairsFile)
      const filename = `round${round}/${countryCode}/KI-CC-R${round}-${countryCode}-${lang}-${plenary}.pdf`
      handleFile(result.data, filename)
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      // setContextError(error)
    }
    finally {
      setIsDownloadPdf(false)
    }
  }, [discuss]);

  useMemo(() => {
    if (dataEdit) {
      setShowModal(true);
    }
  }, [dataEdit])

  return (

    <>

      {
        isDownloadPdf &&
        <LoadingComponent styles={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', background: 'transparent', zIndex: '1001' }} />
      }
      {
        editWgFile &&
        <UpdateDiscussionFileModal
          discussionType={t('createUpdateDiscussion.wg-file')}
          open={editWgFile}
          onConfirm={async (fileBase64) => {
            try {
              const result = await discussionService.updatePDF(appLanguage, +id, lang, { workingGroupFile: fileBase64 });
              refreshData()
              return result
            } catch (e) {
              console.log(e)
              // setRefreshData(false)
            }
          }}

          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick')
              return;

            if (reason && reason === 'confirm') {
              setDataEdit(undefined);
              findAllKeyIssues();
            }
            setEditWgFile(false);
          }}
        />
      }
      {
        editCcFile &&
        <UpdateDiscussionFileModal
          discussionType={t('createUpdateDiscussion.cc-file')}
          open={editCcFile}
          onConfirm={async (fileBase64) => {
            try {
              const result = await discussionService.updatePDF(appLanguage, +id, lang, { coChairsFile: fileBase64 });
              refreshData()
              return result
            } catch (e) {
              console.log(e)
              // setRefreshData(false)
            }
          }}
          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick')
              return;
            setEditCcFile(false);
          }}
        />
      }
      {
        editPlFile &&
        <UpdateDiscussionFileModal
          discussionType={t('createUpdateDiscussion.pl-file')}
          open={editPlFile}
          onConfirm={async (fileBase64) => {
            try {
              const result = await discussionService.updatePDF(appLanguage, +id, lang, { plenaryFile: fileBase64 });
              refreshData()
              return result
            } catch (e) {
              console.log(e)
              // setRefreshData(false)
            }
          }}
          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick')
              return;
            setEditPlFile(false);
          }}
        />
      }
      {
        showModal &&
        <NewKeyIssue
          open={showModal}
          data={dataEdit}
          lang={lang}
          discussionId={Number(id)}
          editMode={dataEdit.keyIssueTransId !== undefined && dataEdit.keyIssueTransId !== null}
          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick')
              return;

            if (reason && reason === 'confirm') {
              setDataEdit(undefined);
              findAllKeyIssues();
            }
            setShowModal(false);
          }}
        />
      }
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <FurIcon />
          <div>
            <span>{t('createUpdateDiscussion.key-issues')}</span>
          </div>
        </div>
        {!loading && discuss &&
          <div className='mer-info right'>
            <CustomFlag code={discuss?.discussion.mer.country.countryCode} />
            <span className='pl-15'>{discuss?.discussion.mer.country.countryDesc} - {discuss?.discussion.workingGroup.groupName} - {plenary} - {discuss.lang.toUpperCase()}</span>
          </div>
        }
      </div>
      <div className='main-content'>
        <div className='row' style={{ width: '100%', marginLeft: '20px' }}>
          <div style={{ width: '35%' }}>
            <div className={`pt-64 ${styles.updateFurContainer}`}>
              <img className={styles.introImage} src={backgroundImage}></img>
              <h1 className='primary-title'>{t('createUpdateDiscussion.key-issues')}</h1>
              <div className='secondary-description' style={{ marginTop: '30px', color: '#5ECDE9', fontWeight: '400', fontSize: '30px', marginBottom: '30px' }}>
                Welcome to the Key Issue section!
              </div>
              <div className={styles.fileSectContainer}>
                <span style={{ fontWeight: 600 }}>{t('createUpdateDiscussion.download')}</span>
                <ul>
                  <li>
                    <span className={discuss?.urlWorkingGroupFile && styles.linkDownload} onClick={downloadRelevanWGPdf} >{t('createUpdateDiscussion.wg-file')} </span>
                    ({t('createUpdateDiscussion.update')}
                    <span className={styles.linkDownload} onClick={() => setEditWgFile(true)}> <strong>{t('createUpdateDiscussion.hereLabel')}</strong></span>)
                  </li>
                  <li>
                    <span className={discuss?.urlCoChairsFile && styles.linkDownload} onClick={downloadRelevanCcPdf}>{t('createUpdateDiscussion.cc-file')} </span>
                    ({t('createUpdateDiscussion.update')} <span className={styles.linkDownload} onClick={() => setEditCcFile(true)}>
                      <strong>{t('createUpdateDiscussion.hereLabel')}</strong></span>)
                  </li>
                  <li>
                    <span className={discuss?.urlPlenaryile && styles.linkDownload} onClick={downloadRelevanPlPdf}>{t('createUpdateDiscussion.pl-file')} </span>
                    ({t('createUpdateDiscussion.update')} <span className={styles.linkDownload} onClick={() => setEditPlFile(true)}> <strong>{t('createUpdateDiscussion.hereLabel')}</strong></span>)
                  </li>
                </ul>
                <div className={`${styles.merButtons}`}>
                  <button className={`${styles.buttonBlue}`} onClick={() => { setDataEdit({}); }}>
                    {t('buttons.add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '65%' }}>
            {!loadingDataGrid ?
              <div className={`table ${styles.tableDiscussion}`}>
                <div className={'container-abm-page'}>
                  <div className={'table-templates-container'}>
                    <DataTable
                      showEmptyItems={false}
                      headers={dataHeader}
                      rows={dataShow}
                      countItems={count}
                      rowsPerPage={dataTo}
                      hasActions={false}
                      appendOnScrollToBottom={false}
                      onPageSizeChange={(size) => {
                        setDataTo(size)
                        setDataOffset(0)
                        mapDataResult({ data: dataGrid, to: size })
                      }}
                      onPageChange={(newPage: number) => {
                        if (newPage !== page) {
                          setPage(newPage)
                          setDataOffset(newPage - 1);
                          mapDataResult({ data: dataGrid, from: (newPage - 1) })
                        }
                      }}
                      pageSelected={page}
                      pageable={true}
                      selectable={false}
                      className=' thinner'
                    ></DataTable>
                  </div>
                </div>
              </div>
              : <LoadingComponent />}
          </div>
        </div>
      </div >
    </>
  )
}
