import { useTranslation } from 'react-i18next'
import styles from './NotResultTable.module.css'
import backgroundImage from './../../../assets/images/step2-image.png'
export function NotResultTable() {
  const { t } = useTranslation('global')

  return <>
    <div className={`pt-64 ${styles.container}`}>
      <img className={styles.introImage} src={backgroundImage}></img>
      <h1 className='primary-title'>{t('dataTable.notResults.title')}</h1>
      <div className='primary-description'>{t('dataTable.notResults.content')}</div>
    </div>
  </>
}
