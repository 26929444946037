import styles from './../../../mer-fur.module.css';
import { Controller, useForm } from 'react-hook-form';
import { getTechnicalComplianceForm } from '../../../../../configs/data';
import { useEffect, useState } from 'react';
import { useData } from '../../../../../components/hooks/useData';
import MutualEvaluationService from '../../../../../services/mutual-evaluation.service';
import { useTranslation } from 'react-i18next';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../../contexts/error-context';
import 'react-quill/dist/quill.snow.css';
import { useLanguage } from '../../../../../contexts/language-context';
interface KeyDeficienciesInterface {
  merId: number
  merLanguage: string,
  methodology: string,
  readOnly?: boolean
}

export default function KeyDeficiencies({ merId, merLanguage, methodology, readOnly = false }: KeyDeficienciesInterface) {

  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const { setError: setContextError } = useErrorContext()
  const { keyDeficiencies } = getTechnicalComplianceForm(methodology).technicalCompliance
  const service = MutualEvaluationService.getInstance(setContextError)
  const [readyForm, setReadyForm] = useState(false)
  const { register, watch, setValue, control, handleSubmit, getValues } = useForm()
  const onSubmit = handleSubmit(async (value) => {
    await service.updateKeyDeficiency(appLanguage, merId, value)
  })
  const [loading, error, data] = useData<any>(`${appLanguage}/mer/${merId}/key-deficiencies?lang=${merLanguage}`, 'GET')
  const getKeyDeficiencyTitle = (code: string) => {
    const key = code.slice(0, 14)
    const recommendation = +code.slice(14, 16)
    return { key, recommendation }
  }

  useEffect(() => {
    setReadyForm(false)
    if (data) {
      for (const prop in data) {
        setValue(`${prop}`, data[prop] ?? '')
      }
      setReadyForm(true)
    }
  }, [data])

  return (<>

    {readyForm ?
      <div className={styles.merContent}>
        {
          data?.recommendations?.map((el) => {
            const { recommendation } = getKeyDeficiencyTitle(el.recommendationCode)
            const index = getValues().recommendations.findIndex(it => it.recommendationCode === el.recommendationCode)
            return (
              <div key={index}>
                <div className={styles.elementTitle}>
                  {`${t(`recommendation.${methodology}.title`)} ${recommendation}`} - {`${t(`recommendations.${methodology}.${el.recommendationCode}`)}`}
                </div>
                {!readOnly ?
                  <Controller
                    name={`recommendations.${index}.keyDeficiencies`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RichTextArea
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={onSubmit}
                      />
                    )}
                  /> :
                  <div className='ql-editor'
                    dangerouslySetInnerHTML={{
                      __html: data?.recommendations?.[index]?.keyDeficiencies
                    }}
                  ></div>
                }
              </div>)
          })
        }
        {!readOnly &&
          <div className='row pb-30'>
            <div className='col-12 center'>
              <button className='basic-button blue-button' onClick={onSubmit}>{t('buttons.save')}</button>
            </div>
          </div>
        }
      </div>
      : <LoadingComponent />}
  </>)

}
