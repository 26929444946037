/* eslint-disable react/prop-types */
import styles from './left-menu.module.css';
interface MainLeftMenuInterface {
  children:any
}
export default function MainLeftMenu({children}:MainLeftMenuInterface){
  return (
    <div className={styles.leftMenu}>
      {children}
  </div>
  )
}
