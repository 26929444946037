import { ReactElement, ReactNode } from 'react'
import { technicalCompliance } from '../configs/data'

class CodeDescription {
  id!: number
  code?: string
  description?: string

  constructor(id?: number, code?: string, description?: string) {
    this.id = id ?? this.id
    this.code = code ?? this.code
    this.description = description ?? this.description
  }

  static fromData(data: any): CodeDescription {
    const o = new CodeDescription()
    const { code, description } = data
    o.code = code
    o.description = description

    return o
  }
}

class TableAction {
  icon?: ReactElement
  label: string
  action: (row: any, index: number) => void

  constructor(label: string, action: (row: any, index: number) => void, icon?: ReactElement) {
    this.label = label
    this.action = action
    this.icon = icon
  }
}


export interface TableHeaderInterface {
  label: string
  key: string
  sorteable?: boolean
  sort?: string
  align?: string
  minWidth?: number
  filter?: FilterTable
  onSort?: () => void
  onSelect?: (row: any) => void
  headerStyle?: any
  colStyle?: any
}
class TableHeaderCell {
  label: string // Nombre a mostrar
  key: string
  sorteable?: boolean
  sort?: string
  align?: string
  minWidth?: number
  filter?: FilterTable
  onSort?: () => void
  onSelect?: (row: any) => void
  headerStyle?: any
  colStyle?: any

  constructor({
    label,
    key,
    sorteable,
    sort,
    onSort,
    onSelect,
    align = 'left',
    headerStyle,
    colStyle,
    filter,
  }: TableHeaderCell) {
    this.label = label
    this.key = key
    this.sorteable = sorteable
    this.sort = sort
    this.onSort = onSort
    this.onSelect = onSelect
    this.align = align
    this.headerStyle = headerStyle ?? {}
    this.colStyle = colStyle ?? {}
    this.filter = filter
  }
}

class TableDetailCell {
  data: any
  customDefinitions?: Map<string, ReactNode>
  actions?: TableAction[]

  constructor(data: any, actions?: TableAction[], customDefinitions?: Map<string, ReactNode>) {
    this.data = data
    this.actions = actions
    this.customDefinitions = customDefinitions
  }
}
enum FilterTableType {
  TEXT,
  COMBO,
  MULTIPLE,
}

class FilterTable {
  value?: any
  type?: FilterTableType
  placeholder?: string
  data?: CodeDescription[]
  useCodeField?: boolean
  returnEntity?: boolean
  onChange: (newVal: any) => void

  constructor({
    value = '',
    type = FilterTableType.TEXT,
    placeholder = '',
    data,
    onChange,
    useCodeField = false,
    returnEntity = false,
  }: FilterTable) {
    this.value = value
    this.type = type
    this.placeholder = placeholder
    this.data = data
    this.useCodeField = useCodeField
    this.returnEntity = returnEntity
    this.onChange = onChange
  }
}
class Country {
  id!: number
  countryName: string
  countryCode: string
}

class Round {
  id!: number
  roundCode: string
  roundValue: string
}

class MutualEvaluation {
  id!: number
  countryId: number
  round: number
  language: string
  period: string
}

class ConsolidatedRatingsResponse {
  countryDesc: string
  countryShortDesc: string
  countryCode: string
  merId: number
  technicalCompliances: {
    title: string
    recommendationCode: string
    originalAssessmentDesc: string
    originalAssessmentShortDesc: string
    originalAssessmentColor: string
    lastAssessmentDesc: string
    lastAssessmentShortDesc: string
    lastAssessmentColor: string
    furId: number
  }[]
  effectiveness: {
    title: string
    immediateOutcomeCode: string
    originalAssessmentDesc: string
    originalAssessmentShortDesc: string
    originalAssessmentColor: string
    lastAssessmentDesc: string
    lastAssessmentShortDesc: string
    lastAssessmentColor: string
    furId: number
  }[]
}

class TechnicalComplianceConclusion {
  merId: number
  recommendationCode: string
  recommendationTranslations: {
    lang: string
    weightingAndConclusion: string
  }[]
}

class EffectivenessConclusion {
  merId: number
  immediateOutcomeCode: string
  immediateOutcomeTranslations: {
    lang: string
    conclusion: string
  }[]
}

class FollowUpReport {
  id!: number
  countryId: number
  round: number
  language: string
  period: string
}

class Assessment {
  id: number
  assessmentCode: string
  assessmentOrder: number
  description: string
  element: string
  shortDescription: string
}

export {
  TableAction,
  TableHeaderCell,
  TableDetailCell,
  CodeDescription,
  FilterTableType,
  FilterTable,
  Country,
  MutualEvaluation,
  Round,
  ConsolidatedRatingsResponse,
  TechnicalComplianceConclusion,
  EffectivenessConclusion,
  Assessment,
  FollowUpReport
}
