
interface IconInterface {
  fillColor?: string
}
export default function PlusIcon(
  { fillColor }: IconInterface) {
  return <>
    <svg width="16" height="17" viewBox="0 0 16 17" fill={fillColor ?? '#5ECDE9'}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5938 7.09375H9.40625V1.90625C9.40625 1.12959 8.77666 0.5 8 0.5C7.22334 0.5 6.59375 1.12959 6.59375 1.90625V7.09375H1.40625C0.629594 7.09375 0 7.72334 0 8.5C0 9.27666 0.629594 9.90625 1.40625 9.90625H6.59375V15.0938C6.59375 15.8704 7.22334 16.5 8 16.5C8.77666 16.5 9.40625 15.8704 9.40625 15.0938V9.90625H14.5938C15.3704 9.90625 16 9.27666 16 8.5C16 7.72334 15.3704 7.09375 14.5938 7.09375Z" />
    </svg>

  </>
}
