import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './drag-and-drop.module.css';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as PDFIcon } from '../../../assets/icons/pdf-icon.svg'
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface DragAndDropProp {
  pFile: File;
  fieldName: string;
  legend?: string;
  onDraggingLegend?: string;
  onDrop: (acceptedFiles: any) => void;
  onDelete?: () => void;
  errors: any;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
}

const DragAndDrop = ({ pFile, legend, onDraggingLegend, fieldName, onDrop, onDelete, errors, register, required = true }: DragAndDropProp) => {
  const [file, setFile] = useState<File>()
  const { t } = useTranslation('global');

  useMemo(() => {
    setFile(pFile);
  }, [pFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })


  return (
    <>
      {!file &&
        <div className={`${classes.dropZone} ${!!errors[fieldName] && required && classes.required}`}>
          <div {...getRootProps()} >
            {required ? <input {...Object.assign(getInputProps(), register(fieldName, { required: t('validations.required') }))} /> : <input {...getInputProps()} />}
            {
              <div className={classes.dragHere} >
                <div className='row'>
                  <div className='col-12 center'>
                    <PDFIcon />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 center'>
                    {isDragActive ?
                      <span>{(onDraggingLegend ??
                        t('dragAndDrop.onDragZone'))} ...</span>
                      : <span>{(legend ?? t('dragAndDrop.legend'))}</span>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }

      {file &&
        <div className={classes.deleteFile}>
          {file.name}
          <span
            onClick={() => { setFile(null); if (onDelete) onDelete() }}
          ><DeleteIcon /></span>
        </div>
      }
    </>)

}

export default DragAndDrop;
