import { Accordion, AccordionSummary } from '@mui/material';
import { useState } from 'react';
import styles from '../accordion-v2.module.css'



const CustomAccordionGroup = ({style, expandedInitVal, classes, expandIcon, ariaControls, id, summary, children} : any)=>{
  const [expanded, setExpanded] = useState<boolean>(expandedInitVal);
  const handleExpand = () => {
    setExpanded(!expanded)
  }

    return  <Accordion
      className={styles.content} expanded={expanded}>
      <AccordionSummary
                onClick={handleExpand}
                style={style}
                classes={classes}
                expandIcon={expandIcon}
                aria-controls={ariaControls}
                id={id}
              >
            {summary}
          </AccordionSummary>
          {children}

      </Accordion>
}


export default CustomAccordionGroup;