import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import AccordionDetails from '@mui/material/AccordionDetails'
import { ChildrenTypeEnum } from '../../../configs/data'
import { ReactComponent as DefaultExpandIcon } from './../../../assets/icons/expand-icon.svg'
import styles from './accordion-v2.module.css'
import { Chip } from '@mui/material'
import { ReactComponent as CollapseIconDefault } from './../../../assets/icons/collapse-icon.svg'
import { CodeDescription } from '../../../model/models-module'
import FilterCheckbox from './checkbox/checkbox'
import FilterRadioButton from './radio-button/radiobutton'
import CustomAccordionGroup from './accordion-summary/accordion-group'
import { useTranslation } from 'react-i18next'
import MainLeftMenu from '../../layout/left-menu/left-menu'
import LoadingComponent from '../loading/loading'
import { useLanguage } from '../../../contexts/language-context'

export class AccordionGroupData {
  customKey: string;
  parentDictionaryKey: string;
  summary: string;
  data: CodeDescription[] | Map<string, CodeDescription[]| Map<string, CodeDescription[]>>;
  elementType: ChildrenTypeEnum;
  expandIcon?: React.FunctionComponent<React.SVGProps<
    SVGSVGElement
  > & { title?: string }>;
  collapseIcon?: React.FunctionComponent<React.SVGProps<
    SVGSVGElement
  > & { title?: string }>;

  constructor({customKey, parentDictionaryKey, summary, data, elementType, expandIcon, collapseIcon}: AccordionGroupData = {customKey : 'key', parentDictionaryKey : 'dictionary.key', summary : 'summary title', data: undefined, elementType: ChildrenTypeEnum.ACCORDION}) {
  this.customKey = customKey;
  this.parentDictionaryKey = parentDictionaryKey;
  this.summary = summary;
  this.data = data;
  this.elementType = elementType;
  this.expandIcon = expandIcon ?? DefaultExpandIcon;
  this.collapseIcon = collapseIcon ?? CollapseIconDefault;
  }
}

interface CustomAccordionProps {
  data: AccordionGroupData[];
  loadingData?: boolean;
  withChips?: boolean;
  initialValues?: Map<string, string[]>;
  onClickChildren?: any
  onClick?: any
  onSelectableChange?(
    dataSelected: Map<string, string[]>
  ): void
}

export default function CustomAccordionFilter({
  data = [],
  initialValues = new Map(),
  onClickChildren,
  withChips = false,
  loadingData= false,
  onClick,
  onSelectableChange,
}: CustomAccordionProps) {
  const [dataChecked, setDataChecked] = useState<Map<string, string[]>>(initialValues);
  const { t } = useTranslation('global')
  const [loading, setLocading] = useState<boolean>(loadingData);
  const {language} = useLanguage();


  useEffect(()=>{
    setLocading(loadingData);
  }, [loadingData])

  const handleChange = useCallback((newVal, customKey)=>{
    const aux = new Map(); dataChecked.forEach((v,k)=>aux.set(k,v)); aux.set(customKey, newVal); setDataChecked(aux)
    onSelectableChange(aux);
  }, [dataChecked]);

  const handleCheck = useCallback((checked, customKey, el)=>{ 
      const dataSel = dataChecked.get(customKey) ?? [];
      if(checked) {
        dataSel.push(el.code);
      } else {
        const idx = dataSel.findIndex(d=> d === el.code);
        dataSel.splice(idx, 1);
      }
      handleChange(dataSel, customKey);
    }, [dataChecked, ]);

  const mapChildrenEl = useCallback((data: AccordionGroupData) => {
    if(Object.keys(data.data).length === 0) {
      return <></>;
    }
    let child = <></>;
    if (data.elementType && data.elementType === ChildrenTypeEnum.RADIO) {
      child = (
        <FilterRadioButton
        initValue={(initialValues.has(data.customKey) && initialValues.get(data.customKey)[0]) ?? ''}
        onChange={(newVal)=>{ handleChange([newVal], data.customKey); }}
        dataRadio={(data.data as CodeDescription[])}
        name= {data.customKey}
        />);

    } else if (
            data.elementType &&
            data.elementType === ChildrenTypeEnum.CHECKBOX
          ) {
            child = <>
              {
              (data.data as CodeDescription[]).map((el, idx) => {
                  return (
                    <div key={idx} className={styles.opt}>
                      {

                        <FilterCheckbox
                        label={el.description}
                        value={(dataChecked.has(data.customKey) && dataChecked.get(data.customKey).find(d=>d === el.code) !== undefined) ?? false}
                        onChange={(checked)=>handleCheck(checked, data.customKey, el)}
                        
                        ></FilterCheckbox>
                      }
                    </div>
                  )
              })}
            </>;
          } 
    else {

      child = <>
        {
        (data.data as CodeDescription[]).map((el, idx) => {
            return (
              <div key={idx} className={`${styles.opt} ${styles.item}`} onClick={() => onClickChildren(idx)}>
                {el.description}
              </div>
            )
        })}
      </>;
    }
    return child;
  }, [dataChecked]);

  const drawChild = useCallback((data : AccordionGroupData, idx: number): ReactElement  => {


    if(Array.isArray(data.data)) {
      return (
          <CustomAccordionGroup
                expandedInitVal={initialValues.has(data.customKey) && initialValues.get(data.customKey).length > 0}
                style={{ padding: 0, minHeight: 'auto' }}
                classes={{ content: styles.contentTitle }}
                expandIcon={data.expandIcon ? React.createElement(data.expandIcon) : <DefaultExpandIcon />}
                ariaControls={`panel${data.customKey}-content`}
                id={`panel${data.customKey}-header`}
                summary={data.summary}
          >
            <AccordionDetails key={`${data.customKey}`} style={{ padding: 0, marginTop: '20px' }}>
              {dataChecked! && mapChildrenEl(data)}
            </AccordionDetails>

          </CustomAccordionGroup>
        );
    } else {
      const children = [];
      data.data.forEach((v,k)=>{
        children.push(
            <AccordionDetails key={`${k}-${v}`} style={{ padding: 0, marginTop: '20px' }}>
              {drawChild(Object.assign({},data, {data: v, parentDictionaryKey : `${data.parentDictionaryKey}.items.${k}`, summary: t(`${data.parentDictionaryKey}.items.${k}`), customKey: k}), idx)}
            </AccordionDetails>
          );

      });
      return (
          <CustomAccordionGroup
                style={{ padding: 0, minHeight: 'auto' }}
                classes={{ content: styles.contentTitle }}
                expandIcon={data.expandIcon ? React.createElement(data.expandIcon) : <DefaultExpandIcon />}
                ariaControls={`panel${data.customKey}-content`}
                id={`panel${data.customKey}-header`}
                summary={data.summary}
          >
            {children}
          </CustomAccordionGroup>)
        ;
    }

  }, [dataChecked]);

  const findMenuOpt = useCallback((menu: string, childLvl?: AccordionGroupData[]): AccordionGroupData =>{
    let menuList;
    const nextLevel = []
    const currLvl = childLvl ?? data;
    for( const i in currLvl) {

      if(!Array.isArray(currLvl[i].data)) {
        currLvl[i].data.forEach((v, k)=>{
          nextLevel.push(Object.assign({}, currLvl[i], {data: v, customKey: k}));
        });
      }

      if(currLvl[i].customKey === menu) {
        menuList = currLvl[i];
        break;
      }
    }
    if(menuList === undefined && nextLevel.length > 0) {
      return findMenuOpt(menu,nextLevel);
    }

    return menuList;

  }, [data]);

  const drawChips = useCallback((dataSelected: Map<string, any[]>, language)=>{
    const chipsEl = [];

    const mapList = new Map<string, AccordionGroupData>();

    dataSelected.forEach((value, k)=>{
      if(!mapList.has(k)) {
        mapList.set(k, findMenuOpt(k));
      }
      if(Array.isArray(value)) {
        value.forEach((v)=>{
          const menuOpt = mapList.get(k);
         const desc = (menuOpt.data as CodeDescription[]).find((d)=>d.code === v).description;

            chipsEl.push(<Chip style={{ marginLeft: '4px', marginBottom: '4px' }} key={`${k}-${v}`} label={desc} variant='outlined' onDelete={menuOpt.elementType === ChildrenTypeEnum.CHECKBOX ? ()=>{
                    // removeChip(k, v, dataChecked);

                    const auxSel = new Map();
                    dataSelected.forEach((v,k ) => auxSel.set(k, v));
                    const selection =  dataSelected.get(k);
                    const idx = selection.findIndex(s=>s === v);
                    selection.splice(idx, 1);
                    if(selection.length > 0) {
                      auxSel.set(k, selection)
                    } else {
                      auxSel.delete(k);
                    }
                    handleChange(selection, k);
                  } : null}></Chip>)

        })
      }

    });

    return chipsEl;
  }, [data, dataChecked, language]);

  return (
      <MainLeftMenu>       
        {loading ? (
          <LoadingComponent />
        ) : 
        <>
          <div className={'chips-section'}>
            {withChips && dataChecked.size > 0 && (
              drawChips(dataChecked, language)
            )}
          </div>
          {
            data.map((d: AccordionGroupData, idx) =>{
              return (<div key={idx}>
              {drawChild(d, idx)}
              </div>)
            })
          }
        </>
      }
      </MainLeftMenu>
      
  )
}
