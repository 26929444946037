import Flag from 'react-world-flags';

export interface FlagProps {
  flagKey?: string
  code: string
  width?: number
  height?: number
  flagName?: string
}

const CustomFlag = ({ flagKey, code, width = 51, height = 33, flagName }: FlagProps) => {
  return (flagName! ?
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span style={{ marginRight: '5px', fontSize: '0' }}>
        <Flag key={flagKey || code} code={code} width={`${width}px`} height={`${height}px`} />
      </span>
      <span>
        {flagName}
      </span>
    </div> :
    <Flag key={flagKey || code} code={code} width={`${width}px`} height={`${height}px`} />)
}

export default CustomFlag
