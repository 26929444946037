import { Outlet } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import { useErrorContext } from '../../../contexts/error-context';
import ErrorDialog from '../../common/error-dialog/errorDialog';
import { useState } from 'react';

export default function MainLayout() {
  const { error, setError, cb, setCb } = useErrorContext()
  const [open, setOpen] = useState<boolean>(true)

  const handleClose = () => {
    setOpen(false)
    setError('')
    if (cb) {
      cb()
      setCb(null)
    }
  }
  return (
    <>
      <Navbar />
      <Outlet />
      {error &&
        <ErrorDialog
          content={error}
          open={!!error}
          onClose={handleClose}
        // cb={cb}
        />
      }
    </>
  )
}
