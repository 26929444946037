import './comparison-tool.css'

import { useEffect, useState } from 'react'

import { ReactComponent as ComparisonIcon } from '../../assets/icons/icono-comparison.svg'
import ComparisonStepOne from './step-one/step-one'
import ComparisonStepThree from './step-three/step-three'
import ComparisonStepTwoRoundFour from './step-two/step-two-round-four'
import { useTranslation } from 'react-i18next'

export default function ComparisonTool() {
  const { t } = useTranslation('global')
  const [canChangeStep, setCanChangeStep] = useState<boolean>(false)
  const [step, setStep] = useState(1)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [cleanSelectedFilters, setCleanSelectedFilters] = useState<boolean>(false)

  // Step 1
  const [round, setRound] = useState<number>()
  const [selectedRows, setSelectedRows] = useState<Map<number, any>>(new Map<number, any>())

  // Step 2
  const [selectedComparables, setSelectedComparables] = useState<string[]>([])

  useEffect(() => {
    const lastPath = localStorage.getItem('lastPath')

    if (lastPath && lastPath !== '/comparison-tool') {
      localStorage.setItem('lastPath', '/comparison-tool')
      setCleanSelectedFilters(true)
    }
    setInitialized(true)
  }, [])

  const changeStep = (selectedStep: number) => {
    if (canChangeStep) {
      setStep(selectedStep)
    }
  }


  return (
    <>
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <ComparisonIcon />
          {t('comparison-tool.title')}
        </div>
        <div className='stepper'>
          <div className='first-step' onClick={() => { setStep(1) }}>
            <div className={`button-step ${step === 1 ? 'button-step-active' : ''}`}>
              <span>1</span>
            </div>
            <p className={`step-name ${step === 1 ? 'step-name-active' : ''}`}>{t('comparison-tool.steps.one')}</p>
          </div>
          <div className='divider divider-step-1-completed'></div>
          <div className='second-step'>
            <div className={`button-step ${step === 2 ? 'button-step-active' : ''}`} onClick={() => { changeStep(2) }}>
              <span>2</span>
            </div>
            <p className={`step-name ${step === 2 ? 'step-name-active' : ''}`}>{t('comparison-tool.steps.two')}</p>
          </div>
          <div className='divider'></div>
          <div className='third-step' onClick={() => { changeStep(3) }}>
            <div className={`button-step ${step === 3 ? 'button-step-active' : ''}`}>
              <span>3</span>
            </div>
            <p className={`step-name ${step === 3 ? 'step-name-active' : ''}`}>{t('comparison-tool.steps.three')}</p>
          </div>
        </div>
      </div>
      <div className='main-content'>
        {initialized && (
          <>
            {step === 1 && (
              <ComparisonStepOne
                cleanSelectedFilters={cleanSelectedFilters}
                setCleanSelectedFilters={setCleanSelectedFilters}
                canChangeStep={canChangeStep}
                setCanChangeStep={setCanChangeStep}
                setRound={setRound}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleContinue={() => {
                  changeStep(2)
                  setCanChangeStep(false)
                }}
              />
            )}
            {step === 2 && round && round === 4 && (
              <ComparisonStepTwoRoundFour
                canChangeStep={canChangeStep}
                setCanChangeStep={setCanChangeStep}
                setStep={setStep}
                selectedRows={selectedRows}
                selectedComparables={selectedComparables}
                setSelectedComparables={setSelectedComparables}
                handleContinue={() => changeStep(3)}
              />
            )}
            {step === 3 && (
              <ComparisonStepThree
                setStep={setStep}
                data={{ round, mers: selectedRows, comparables: selectedComparables }}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}
