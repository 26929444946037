import './customized-reports.module.css'

import { useEffect, useState } from 'react'

import { ReactComponent as CustomizedReportsIcon } from '../../../../assets/icons/customized-reports.svg'
import CustomizedReportStepOne from './step-one/step-one'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../../../../components/common/progress-bar/progress-bar'
import CustomizedReportStepTwo from './step-two/step-two'
import CustomizedReportsStepThree from './step-three/step-three'
import CustomizedReportsStepFour from './step-four/step-four'

export default function CustomizedReports() {
  const { t } = useTranslation('global')
  const [canChangeStep, setCanChangeStep] = useState<boolean>(false)
  const [step, setStep] = useState(1)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [cleanSelectedFilters, setCleanSelectedFilters] = useState<boolean>(false)

  // Step 1
  const [round, setRound] = useState<number>()
  const [selectedRows, setSelectedRows] = useState<Map<number, any>>(new Map<number, any>())

  // Step 2
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([])

  // Step 3
  const [selectedTypeGraph, setSelectedTypeGraph] = useState<string>()
  const [selectedCalifications, setSelectedCalifications] = useState<{ calificationName: string, counter: number, color: string }[]>([])

  useEffect(() => {
    const lastPath = localStorage.getItem('lastPath')

    if (lastPath && lastPath !== '/reports/customized-reports') {
      localStorage.setItem('lastPath', '/reports/customized-reports')
      setCleanSelectedFilters(true)
    }
    setInitialized(true)
  }, [])

  const changeStep = (selectedStep: number) => {
    if (canChangeStep) {
      setStep(selectedStep)
    }
  }

  return (
    <>
      <ProgressBar title={t('reports.title')} subtitle={t('reportAndStatistics.customizedReports.title')} icon={CustomizedReportsIcon} >
        <div className='stepper'>
          <div className='first-step' onClick={(() => { setStep(1) })}>
            <div className={`button-step ${step === 1 ? 'button-step-active' : ''}`}>
              <span>1</span>
            </div>
            <p className={`step-name ${step === 1 ? 'step-name-active' : ''}`}>{t('reportAndStatistics.customizedReports.steps.select')}</p>
          </div>
          <div className='divider divider-step-1-completed'></div>
          <div className='second-step' onClick={() => { changeStep(2) }}>
            <div className={`button-step ${step === 2 ? 'button-step-active' : ''}`}>
              <span>2</span>
            </div>
            <p className={`step-name ${step === 2 ? 'step-name-active' : ''}`}>{t('reportAndStatistics.customizedReports.steps.selectCalification')}</p>
          </div>
          <div className='divider'></div>
          <div className='third-step' onClick={() => { changeStep(3) }}>
            <div className={`button-step ${step === 3 ? 'button-step-active' : ''}`}>
              <span>3</span>
            </div>
            <p className={`step-name ${step === 3 ? 'step-name-active' : ''}`}>{t('reportAndStatistics.customizedReports.steps.report')}</p>
          </div>
          <div className='divider'></div>
          <div className='third-step' onClick={() => { changeStep(4) }}>
            <div className={`button-step ${step === 4 ? 'button-step-active' : ''}`}>
              <span>4</span>
            </div>
            <p className={`step-name ${step === 4 ? 'step-name-active' : ''}`}>{t('reportAndStatistics.customizedReports.steps.graphic')}</p>
          </div>
        </div>
      </ProgressBar>
      {initialized && (
        <>
          {step === 1 && (
            <CustomizedReportStepOne
              cleanSelectedFilters={cleanSelectedFilters}
              setCleanSelectedFilters={setCleanSelectedFilters}
              canChangeStep={canChangeStep}
              setCanChangeStep={setCanChangeStep}
              setRound={setRound}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              handleContinue={() => {
                changeStep(2)
                setCanChangeStep(false)
              }}
            />
          )}
          {step === 2 && (
            <CustomizedReportStepTwo
              canChangeStep={canChangeStep}
              setCanChangeStep={setCanChangeStep}
              setStep={setStep}
              setSelectedHeaders={setSelectedHeaders}
              handleContinue={() => changeStep(3)}
            />
          )}
          {step === 3 && (
            <CustomizedReportsStepThree
              selectedRound={round}
              selectedCountries={selectedRows}
              selectedHeaders={selectedHeaders}
              setSelectedCalifications={setSelectedCalifications}
              setSelectedTypeGraph={setSelectedTypeGraph}
              setStep={setStep}
              handleContinue={() => changeStep(4)}
            />
          )}
          {step === 4 && (
            <CustomizedReportsStepFour
              selectedCalifications={selectedCalifications}
              selectedTypeGraph={selectedTypeGraph}
              setStep={setStep}
            />
          )}
        </>
      )}
    </>
  )
}
