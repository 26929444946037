import { useEffect, useState } from 'react'
import { effectivenessMER, MERInformation } from '../../../../../configs/data'
import { useTranslation } from 'react-i18next'
import styles from './../../../mer-fur.module.css'

interface ViewerProps {
  data: any[]
  methodology: string
}

export function RecommendationViewer({ data, methodology }: ViewerProps) {

  const [viewData, setViewData] = useState(null)
  const { t } = useTranslation('global')

  const getRecTitle = (code: string) => {
    const key = code.slice(0, 14)
    const recommendationNumber = +code.slice(14, 16)
    return { key, recommendationNumber }
  }

  const getCriterionTitle = (code: string) => {
    const key = code.slice(0, 9)
    const recommendation = +code.slice(9, 11)
    const criterion = +code.slice(11, 13)
    const additional = (code.slice(13) ?? '').toUpperCase()
    return { key, recommendation, criterion, additional }
  }

  return (
    <>
      <div className={styles.separator}>{t('update-mer.menu.technicalCompliance.title')}</div>
      <div className={styles.merViewerContent}>
        {
          data.map(el => {
            const { recommendationNumber } = getRecTitle(el.recommendationCode)
            return (
              <div id={el.recommendationCode} key={el.recommendationCode}>
                <div className={styles.sectionTitle}>  {t('recommendation.title')} {recommendationNumber} </div>
                <div className={styles.sectionSubtitle}>  {t(`recommendation.${methodology}.introduction`)}</div>
                <div className='ql-editor'
                  dangerouslySetInnerHTML={{ __html: el.introduction }}>
                </div>

                {
                  el.criterias.map(crit => {
                    const { additional, key, criterion, recommendation } = getCriterionTitle(crit.criterionCode)
                    return (
                      <div key={`${el.recommendationCode}${crit.criterionCode}`}>
                        <div className={styles.sectionSubtitle}> {t(`recommendation.${methodology}.` + key)} {recommendation}.{criterion} {additional}
                        </div >
                        <div className='ql-editor'
                          dangerouslySetInnerHTML={{ __html: crit.description }}>
                        </div>
                      </div >
                    )
                  })
                }
                <div className={styles.sectionSubtitle}>  {t(`recommendation.${methodology}.weightingAndConclusion`)}</div>
                <div className='ql-editor'
                  dangerouslySetInnerHTML={{ __html: el.weightingAndConclusion }}>
                </div>

              </div >
            )
          })
        }
      </div>
    </>
  )
}
