import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { ReactNode, useState, useRef } from 'react'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { TableAction } from '../../../model/models-module'

type Param = {
  actions: TableAction[]
  row: any
  index: number
  group: boolean
  children?: ReactNode
}

const ActionMenu = ({ actions, row, index, group }: Param) => {
  const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null)

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElActions !== event.currentTarget) {
      setAnchorElActions(event.currentTarget)
    }
  }

  const handleCloseActionMenu = () => {
    setAnchorElActions(null)
  }

  return group ? (
    <>
      <span className='action-table'>
        <Tooltip title={'Acciones'}>
          <IconButton onClick={handleOpenActionMenu} sx={{ p: 0 }}>
            <MoreVertOutlinedIcon />
          </IconButton>
        </Tooltip>
      </span>

      <Menu
        sx={{ mt: '45px' }}
        id={'menu-appbar'}
        className='actions-menu'
        anchorEl={anchorElActions}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElActions)}
        onClose={handleCloseActionMenu}
      >
        {actions.map((act: TableAction) => (
          <MenuItem
            key={`${act.label}${index}`}
            sx={{ paddingLeft: '10px' }}
            onClick={() => {
              handleCloseActionMenu()
              act.action(row.data, index)
            }}
          >
            <span style={{ width: '40px', textAlign: 'center', display: 'flex', justifyContent: 'center', marginRight: '10px' }}>{act.icon}</span>
            <Typography textAlign='center'>{act.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <>
      {actions.map((act: TableAction) => (
        <span
          className='actIcon'
          key={act.label}
          title={act.label}
          onClick={() => {
            act.action(row.data, index)
          }}
        >
          {act.icon}
        </span>
      ))}
    </>
  )
}

export default ActionMenu
