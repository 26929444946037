import { ReactElement } from 'react'
import UserService from '../../services/UserService';
import NotAllowed from '../../pages/not-allowed/not-allowed';

interface Props {
  roles: string[]
  children: ReactElement
}

const RenderOnRole = ({ roles, children }: Props) =>
 roles.length == 0  || UserService.hasRole(roles) ? children : <NotAllowed />;

export default RenderOnRole
