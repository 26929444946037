import { useTranslation } from 'react-i18next'
import styles from './../../../mer-fur.module.css'

interface ViewerProps {
  data: any[]
  methodology: string
}

export function KeyDeficiencyViewer({ data, methodology }: ViewerProps) {

  const { t } = useTranslation('global')

  const getRecTitle = (code: string) => {
    const key = code.slice(0, 14)
    const recommendationString = code.slice(14, 16)
    return { key, recommendationNumber: +recommendationString, recommendationString }
  }

  return (
    <>
      <div id='keyDeficiencies' className={styles.sectionTitle}>{t('update-mer.menu.technicalCompliance.keyDeficiencies.title')}</div>
      <div className={styles.merViewerContent}>
        {
          data.map(el => {
            const { recommendationNumber, recommendationString } = getRecTitle(el.recommendationCode)
            return (
              <div key={el.recommendationCode + 'keyDeficiencies'}>
                <div className={styles.sectionSubtitle}>  {t('recommendation.title')} {recommendationNumber} - {t(`recommendations.${methodology}.recommendation${recommendationString}`)} </div>
                <div className='ql-editor'
                  dangerouslySetInnerHTML={{ __html: el.keyDeficiencies }}>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}
