/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useState } from 'react'
import classes from '../NewKeyIssue.module.css'
import WizardStep from '../../../../../components/common/wizard/WizardStep';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { KeyIssue } from '../dto/key-issue-dto';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import { FormControl, FormHelperText } from '@mui/material';

type Props = {
  value: KeyIssue;
  isEdit: boolean;
  onAfterSubmit(values: any): void;
  onAfterBack(): void;
}

const NewKeyIssueStepFour = ({value, isEdit, onAfterSubmit, onAfterBack}: Props) =>{
  const refSubmit = createRef<HTMLButtonElement>();
  const [formValue, setFormValue] = useState<KeyIssue>(value);
  const { t } = useTranslation('global');

  return (
    <WizardStep btnCancelDisabled={false} onNext={()=> { if(refSubmit && refSubmit.current) refSubmit.current.click()}} onBack={()=>{onAfterBack()}} btnBackDisabled={true} btnNextDisabled={true} btnEndDisabled={false}>


    <Formik
      initialValues={formValue}
      validate={values => {
        const errors: any = {};
        if(!values.relevantDecisionsPlenary! || values.relevantDecisionsPlenary === '<p><br></p>') {
          errors.relevantDecisionsPlenary = t('validations.required');
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onAfterSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        values,
        errors,
        touched,
        /* and other goodies */
      }) => (

        <form onSubmit={handleSubmit} style={{marginTop:'25px'}}>
          <div className={`${classes.formContainer} nwsn-scroll`}>
              <div className={classes.webContainer}>
                <div className={`${classes.segment} col-12`}>
                    <FormControl error={errors.relevantDecisionsPlenary !== undefined} fullWidth>
                      <div className={classes.elementTitle}>{t('createUpdateDiscussion.relevant-decisions-plenary')}</div>
                      <FormHelperText>{errors && errors.relevantDecisionsPlenary && errors.relevantDecisionsPlenary}</FormHelperText>
                      <RichTextArea
                        className={errors && errors.relevantDecisionsPlenary ? classes.textAreaError : classes.textArea}
                        value={values.relevantDecisionsPlenary}
                        onChange={(value)=>{setFieldValue('relevantDecisionsPlenary', value)}}
                      />
                    </FormControl>
                </div>
              </div>
            </div>

        <button type="submit" ref={refSubmit} disabled={isSubmitting} style={{display:'none'}}>
          Submit
        </button>
        </form>
      )}
    </Formik>
    </WizardStep>
  )
}


export default NewKeyIssueStepFour;
