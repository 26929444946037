
import { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Select } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NewMerIcon } from './../../../assets/icons/new-mer-icon.svg'
import { ReactComponent as DeleteIcon } from './../../../assets/icons/delete.svg'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as PDFIcon } from '../../../assets/icons/pdf-icon.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import modalStyles from './../mer/new/mer-modal.module.css';
import { useLanguage } from '../../../contexts/language-context';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { FollowUpReport } from '../../../model/models-module';
import FollowUpReportService from '../../../services/follow-up-report.service';
import { useDefaultData } from '../../../contexts/default-data-context';
import { useErrorContext } from '../../../contexts/error-context';
interface MerModalInterface {
  open: boolean
  setOpen: any
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.7)',
  width: 855,
  bgcolor: '#F5F5F5',
  border: 'none',
  borderRadius: '15px',
  padding: '40px 73px',
}
const menuProps = {
  PaperProps: {
    style: {
      transform: 'scale(0.7)',
      transformOrigin: 'top left',
    },
  },
}

export default function FURModal({ open, setOpen }: MerModalInterface) {

  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()

  const navigate = useNavigate()
  const [fileBase64, setFileBase64] = useState(null)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const { register, watch, handleSubmit, formState, setValue, reset } = useForm()
  const { errors } = formState
  const { rounds: roundData, countries: countriesData, error } = useDefaultData()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { setError } = useErrorContext()
  const service = FollowUpReportService.getInstance(setError)

  const handleClose = () => {
    setOpen(false)
    setFile(null)
    setFileBase64(null)
    reset()
  }
  const goNewFUR = (id: number, lang: string) => {
    handleClose()
    navigate(`/main/fur/update/${id}/${lang}`)
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })

  const deleteFile = () => {
    setFile(null)
    setFileBase64(null)
  }

  const onSubmit = (handleSubmit(async (data) => {
    setLoading(true)
    data.file = fileBase64

    try {
      const result: FollowUpReport = await service.saveFUR(appLanguage, data)
      if (result?.id) {
        goNewFUR(result.id, data.language)
      } else {
        console.log('error', result)
      }
    } catch (e) {
      setError(e)
    }
    finally {
      setLoading(false)
    }
  }))

  // useEffect(() => {
  //   if (!initialized) {
  //     const isNotLoading = !cLoading
  //     const hasData = countriesData != null
  //     setInitialized(isNotLoading && hasData)
  //     // setDataTo(10)
  //   }
  // }, [cLoading])

  useEffect(() => {
    if (error) {
      console.log(error)
    }
  }, [error])


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className={modalStyles.modalContainer}>
            <div className='row'>
              <div className='col-12 center'>
                <NewMerIcon style={{ width: '55px', height: '67px' }} />
              </div>
            </div>
            <div className={modalStyles.title}>{t('newFur.modal.title')}</div>
            <div className={modalStyles.subtitle}>{t('newFur.modal.subTitle')}</div>

            {!loading &&
              <div className={modalStyles.content}>
                <div className='row pt-30 pb-30'>
                  <div className='col-6 pr-15'>
                    <FormControl fullWidth error={!!errors.round} >
                      <InputLabel id='demo-simple-select-label' className={modalStyles.label}>{t('newFur.modal.fields.round')}</InputLabel>
                      <Select
                        {...register('round', { required: t('validations.required') })}
                        labelId='labelRound'
                        id='label-round-id'
                        label='ROUND'
                        value={watch('round') || ''}
                        className={modalStyles.select}
                        MenuProps={menuProps}
                      >
                        {
                          roundData?.map((el, i) => {
                            return <MenuItem key={i} className={modalStyles.menuItem} value={+el.roundCode}>{el.roundValue}</MenuItem>
                          })
                        }
                      </Select>
                      <FormHelperText>{errors.round?.message.toString()}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className='col-6 pl-15'>
                    <FormControl fullWidth error={!!errors.countryId}>
                      <Autocomplete
                        {...register('countryId', { required: t('validations.required') })}
                        className={`${modalStyles.select}`}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        disablePortal
                        id="combo-box-demo"
                        onChange={(event: any, newValue) => {
                          (newValue)
                            ? setValue('countryId', newValue.id)
                            : setValue('countryId', '')
                        }}
                        options={countriesData?.map(r => {
                          return { label: r.countryName, id: r.id }
                        }) ?? []}
                        renderInput={(params) =>
                          <TextField {...params}
                            label={t('newFur.modal.fields.country')}
                            className={modalStyles.autocompleteLabel} error={!!errors.countryId}
                          />}
                      />
                      <FormHelperText>{errors.countryId?.message.toString()}</FormHelperText>
                    </FormControl>

                  </div>
                </div>
                <div className='row pb-30 '>
                  <div className='col-6 pr-15'>
                    <FormControl fullWidth error={!!errors.language} >
                      <InputLabel id='demo-simple-select-label' className={modalStyles.label}>
                        {t('newFur.modal.fields.language')}
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='LANGUAGE'
                        className={modalStyles.select}
                        {...register('language', { required: t('validations.required') })}
                        value={watch('language') || ''}
                        MenuProps={menuProps}
                      >
                        <MenuItem className={modalStyles.menuItem} value='es'>Spanish</MenuItem>
                        <MenuItem className={modalStyles.menuItem} value='en'>English</MenuItem>
                      </Select>
                      <FormHelperText>{errors.language?.message.toString()}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className='col-6 pl-15'>
                    <FormControl fullWidth error={!!errors.period}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register('period', { required: t('validations.required') })}
                          slotProps={{ textField: { error: !!errors.period } }}
                          className={`${modalStyles.datePicker}`}
                          format={appLanguage === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                          onChange={(newValue) => setValue('period', newValue.format('YYYY-MM-DD'))}

                        />
                      </LocalizationProvider>
                      <FormHelperText>{errors.period?.message.toString()}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className='row pb-30'>
                  {!file &&
                    <div className={`col-12 ${modalStyles.dropZone} ${!!errors.file && modalStyles.required}`}>
                      <div {...getRootProps()} >
                        <input {...Object.assign(getInputProps(), register('file', { required: t('validations.required') }))} />
                        {
                          <div className={modalStyles.dragHere} >
                            <div className='row'>
                              <div className='col-12 center'>
                                <PDFIcon />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-12 center'>
                                {isDragActive ? <p>{t('newFur.modal.fields.dropFiles')} ...</p> : <p>{t('newFur.modal.fields.dragAndDrop')}</p>}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  {file &&
                    <div className={modalStyles.deleteFile}>
                      {file.name}
                      <span onClick={deleteFile}><DeleteIcon /></span>
                    </div>
                  }
                </div>
              </div>
            }
            {loading && <div className='center pt-30'>
              <CircularProgress style={{ margin: 'auto' }} disableShrink />
            </div>}
            {!loading && <div className='row'>
              <div className='col-12 center lato'>
                <button type='button' className='basic-button mr-20 return-button lato' onClick={handleClose} >{t('buttons.return')}</button>
                <button type='submit' className='basic-button blue-button lato' onClick={onSubmit}>{t('buttons.continue')}</button>
              </div>
            </div>}
          </div>
        </Box>
      </Modal>
      {/* <DevTool control={control} /> */}
    </>
  )
}
