import * as FileSaver from 'file-saver'
import { DateFormatEnum } from '../enums/date-format.enum';
import moment from 'moment'
import { LanguagesEnum } from '../enums/mer-status.enum';

const extractHierachyValue = ({ data, props }: { data: any; props: string }): any => {
  let result = null
  if (props !== undefined && props !== null) {
    if (props.includes('.')) {
      const dotIndex = props.indexOf('.')
      result = data[props.substring(0, dotIndex)]
      return extractHierachyValue({ data: result, props: props.substring(dotIndex + 1) })
    } else {
      result = data ? data[props] : null
    }
  }

  return result
}

const appendStringParam = (value: string, paramCode: string) => {
  let param: string = ''

  if (value) {
    param += `${paramCode}=${value}`
  }

  return param
}

const addParam = (
  value: any,
  valueName: string,
  params: Map<string, string>,
  baseEndpoint: string,
  setEndPoint: any,
) => {
  if (value) {
    params.set(valueName, appendStringParam(value, valueName))
  } else {
    params.delete(valueName)
  }
  let aux = ''
  params.forEach((valueArg) => {
    if (aux) {
      aux += `&${valueArg}`
    } else {
      aux += `?${valueArg}`
    }
  })
  setEndPoint(baseEndpoint + aux)
}

const isArrayOfStrings = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string')
}
const handleFile = (response: Blob, filename: string) => {
  FileSaver.saveAs(response, filename)
}

const handleXlsxFile = (response: Blob, filename: string) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
}

const convertDate = (date: string, language: string): string => {
  const format = language === LanguagesEnum.EN ? DateFormatEnum.ENGLISH : DateFormatEnum.SPANISH
  return moment(date).format(format)
}

const parseParams = (params: Map<string,string[]>) => {

    let args = ''
    params.forEach((value, key) => {
      if (value && value.length > 0) {
        if (args != '') {
          args += `,${key}:${value.join(':')}`;
        } else {
          args += `${key}:${value.join(':')}`;
        }
      }
    });

    return args;
}

export { addParam, parseParams, extractHierachyValue, isArrayOfStrings, handleFile, convertDate, handleXlsxFile }
