import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ChildrenTypeEnum } from '../../../configs/data'
import { ReactComponent as DefaultExpandIcon } from './../../../assets/icons/expand-icon.svg'
import { isArrayOfStrings } from '../../../utils/CommonUtils'
import styles from './accordion.module.css'
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useLanguage } from '../../../contexts/language-context'

interface CustomAccordionProps {
  summary: string
  detailList?: any[]
  selected?: string[]
  children?: React.ReactNode
  collapseIcon?: React.FC<any>
  expandIcon?: React.FC<any>
  filterElementType?: ChildrenTypeEnum
  customKey: number | string
  onClickChildren?: any
  onClick?: any
  hasSubAccordion?: boolean,
  style?: any,
  className?: any
  onSelectableChange?(
    menu: number | string,
    selectedItems: Map<number, any>,
    parentMenu?: number | string,
  ): void
}

export default function CustomAccordion({
  summary,
  expandIcon,
  detailList,
  selected,
  children,
  filterElementType,
  customKey,
  onClickChildren,
  onClick,
  onSelectableChange,
  hasSubAccordion,
  style,
  className
}: CustomAccordionProps) {
  const [init, setInit] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>();
  const { language: appLanguage } = useLanguage()
  const handleExpand = () => {
    setExpanded(!expanded)
    if (onClick) onClick()
  }
  const [detail, setDetail] = useState<any[]>([]);
  const [dataChecked, setDataChecked] = useState<Map<number, boolean>>();

  useEffect(() => {
    const map = new Map<number, boolean>()
    if (selected && selected.length > 0 && detailList) {
      detailList.forEach((value, index) => {
        const isSelected = selected.includes(value)
        if (isSelected) map.set(index, selected.includes(value))
      })
    }
    setDataChecked(map);
    if (dataChecked && dataChecked.size != selected.length && Array.isArray(selected) && onSelectableChange) {
      onSelectableChange(customKey, map,);
    }

  }, [selected]);

  useMemo(() => {
    setDetail(detailList);
    const auxExp = selected != undefined && (Array.isArray(selected) && selected.length > 0 || (Object.keys(selected).length > 0 && selected[Object.keys(selected)[0]] != undefined && selected[Object.keys(selected)[0]].length > 0));
    setExpanded(auxExp)
  }, [appLanguage])


  useEffect(() => {
    const auxList = [];
    detailList?.forEach((d) => auxList.push(d));
    setDetail(auxList);
  }, [dataChecked])

  const handleCheckboxChange = useCallback((isChecked: boolean, idx: number) => {
    let mapAux = new Map<number, boolean>(dataChecked)

    if (dataChecked.has(idx) && !isChecked) {
      mapAux.delete(idx)
    } else {
      mapAux.set(idx, isChecked)
    }

    // Sort
    const arraySort: [number, boolean][] = Array.from(mapAux.entries()).sort(([a], [b]) => a - b)
    mapAux = new Map(arraySort)

    if (onClickChildren) onClickChildren({ index: idx, checked: isChecked })

    setDataChecked(mapAux);
    if (onSelectableChange)
      onSelectableChange(customKey, mapAux);
  }, [dataChecked]);

  const handleRadioChange = (isChecked: boolean, idx: number) => {
    const mapAux = new Map()
    mapAux.set(idx, true)

    if (onClickChildren) onClickChildren({ index: idx, checked: !isChecked })

    setDataChecked(mapAux);
    if (onSelectableChange)
      onSelectableChange(customKey, mapAux);
  }

  const handleInitialValue = useCallback((idx: number): boolean => {
    return dataChecked.get(idx) || false
  }, [dataChecked]);

  const mapChildrenEl = useCallback(() => {
    let child = <></>;
    if (filterElementType && filterElementType === ChildrenTypeEnum.RADIO) {
      child = (
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selected}
            name="radio-buttons-group"
          >
            {
              detail.map((el, idx) => {
                const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                  if (handleRadioChange) {
                    handleRadioChange(event.target.checked, idx);
                  }
                };

                if (
                  filterElementType &&
                  filterElementType === ChildrenTypeEnum.RADIO
                ) {
                  return (
                    <div key={idx} className={styles.opt}>
                      {(
                        <FormControlLabel value={el} control={<Radio checked={dataChecked.get(idx)}
                          onChange={handleChange}
                          name={`radio-${customKey}`}
                        />} label={el} />
                        // <CustomRadioButton
                        //   label={el}
                        //   name={`radio-${customKey}`}
                        //   checked={dataChecked.get(idx)}
                        //   onChange={(val: boolean) => handleRadioChange(val, idx)}
                        // />
                      )}
                    </div>
                  )
                } else if (!filterElementType) {
                  return (
                    <div key={idx} className={`${styles.opt} ${styles.item}`} onClick={() => onClickChildren(idx)}>
                      {el}
                    </div>
                  )
                }
              })
            }
          </RadioGroup>
        </FormControl>);

    } else {

      child = <>
        {detail.map((el, idx) => {
          if (
            filterElementType &&
            filterElementType === ChildrenTypeEnum.CHECKBOX
          ) {
            return (
              <div key={idx} className={styles.opt}>
                {
                  <FormControlLabel
                    label={el}
                    control={<Checkbox
                      size='medium'
                      checked={dataChecked.get(idx) ?? false}
                      // defaultChecked={handleInitialValue(idx)}
                      onChange={(e, val: boolean) => handleCheckboxChange(val, idx)}
                    />} />
                }
              </div>
            )
          } else if (!filterElementType) {
            return (
              <div key={idx} className={`${styles.opt} ${styles.item}`} onClick={() => onClickChildren(idx)}>
                {el}
              </div>
            )
          }
        })}
      </>;
    }
    return child;
  }, [detail]);

  const onSelectChange = useCallback((
    menu: number | string,
    selectedItems: Map<number, any[]>) => {
    onSelectableChange(menu, selectedItems, customKey)
  }, [selected, onSelectableChange]);


  return (
    dataChecked && expanded !== undefined ?
      <div>
        <Accordion className={className ? `${styles.content} ${className}` : styles.content} expanded={expanded}
        >
          <AccordionSummary
            onClick={handleExpand}
            style={{ padding: 0, minHeight: 'auto' }}
            classes={{ content: styles.contentTitle }}
            expandIcon={expandIcon ? React.createElement(expandIcon) : <DefaultExpandIcon />}
            aria-controls={`panel${customKey}-content`}
            id={`panel${customKey}-header`}
          >
            {summary}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, marginTop: '20px' }}>
            {children && !detail && children}
            {children && hasSubAccordion && children}
            {!children &&
              detail &&
              isArrayOfStrings(detail) &&
              mapChildrenEl()
            }
            {!children &&
              detail &&
              !isArrayOfStrings(detail) &&
              filterElementType &&
              filterElementType === ChildrenTypeEnum.ACCORDION &&
              detail.map((item, i) => {
                let aux: string[] = [];
                if (selected && Array.isArray(selected)) {
                  selected.forEach((s) => aux = aux.concat(s));
                } else {
                  if (selected && selected[item.customKey]!)
                    aux = Array.from(selected[item.customKey]);
                }

                return (
                  <CustomAccordion
                    customKey={item.customKey || i}
                    key={item.key || i}
                    expandIcon={item.expandIcon}
                    collapseIcon={item.collapseIcon}
                    summary={item.summary}
                    detailList={item.detailList}
                    selected={selected ? aux : item.selected}
                    // selected={aux}
                    filterElementType={item.elementType}
                    // onSelectableChange={onSelectableChange}
                    // onSelectableChange={onSelectChange}
                    onSelectableChange={(
                      menu: number | string,
                      selectedItems: Map<number, any[]>) => {
                      onSelectableChange(menu, selectedItems, customKey)
                    }}
                  />
                )
              })}
          </AccordionDetails>
        </Accordion>
      </div>
      : null
  )
}
