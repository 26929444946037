import { useEffect, useState } from 'react'
import { MERInformation } from '../../../../../configs/data'
import { useTranslation } from 'react-i18next'
import styles from './../../../mer-fur.module.css'

interface DefaultViewerProps {
  data: any
  sourceData: any
  methodology: string
  traductionSection: string
}

export function DefaultViewer({ data, sourceData, methodology }: DefaultViewerProps) {

  const { t } = useTranslation('global')
  const list = MERInformation.find(r => r.code === sourceData)
  const elements = list.items.filter(el => el.visibleWithMethodology.find(x => x === methodology))
  return (
    <div className={styles.merViewerContent} key={sourceData} id={sourceData}>
      <div className={styles.sectionTitle}>{t(`update-mer.menu.merInformation.${sourceData}.title`)}</div>
      <div>
        {
          elements.map(s => {
            return <div key={s.code} id={s.code}>
              <div className={styles.sectionSubtitle}>{t(`${sourceData}.${methodology}.${s.code}`)}</div>
              <div className='ql-editor'
                dangerouslySetInnerHTML={{ __html: data[s.code] }}>
              </div>
            </div>
          })
        }
      </div>
    </div>)
}
