/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './new-discussion-modal.module.css'
import { Autocomplete, Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as KeyIcon } from '../../../assets/icons/icono-key.svg'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useLanguage } from '../../../contexts/language-context';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDefaultData } from '../../../contexts/default-data-context';
import DefaultDataService from '../../../services/default-data.service';
import { CodeDescription } from '../../../model/models-module';
import DragAndDrop from '../../../components/common/dragAndDrop/drag-and-drop';
import DiscussionService from '../../../services/discussion.service';
import LoadingComponent from '../../../components/common/loading/loading';
import { LanguagesEnum } from '../../../enums/mer-status.enum';
import { useErrorContext } from '../../../contexts/error-context';
import { useNavigate } from 'react-router-dom';

interface NewDiscussionProps {
  open: boolean;
  // onClose: (e?: any, reason?: string) => void;
  setOpen: (value: boolean) => void;
  success?: boolean;
  error?: boolean;
  successMessage?: string | null;
  errorMessage?: string | null;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.8)',
  width: 855,
  bgcolor: '#F5F5F5',
  border: 'none',
  borderRadius: '15px',
  padding: '40px 73px',
}
const menuProps = {
  PaperProps: {
    style: {
      transform: 'scale(0.8)',
      transformOrigin: 'top left',
    },
  },
}
const NewDiscussion = ({ open, setOpen }: NewDiscussionProps) => {
  const { setError: setContextError } = useErrorContext()
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('global')
  const [workingGroupFileBase64, setWorkingGroupFileBase64] = useState(null)
  const [coChairsFileBase64, setCoChairsFileBase64] = useState(null)
  const [plenaryFileBase64, setPlenaryFileBase64] = useState(null)
  const [workingGroupFile, setWorkingGroupFile] = useState(null)
  const [coChairsFile, setCoChairsFile] = useState(null)
  const [plenaryFile, setPlenaryFile] = useState(null)
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const { language: appLanguage } = useLanguage();
  const { register, watch, handleSubmit, formState, setValue, reset } = useForm();
  const { errors } = formState
  const { countries, rounds: roundData } = useDefaultData();
  const [workingGroups, setWorkingGroups] = useState<CodeDescription[]>([]);
  const [defaultService] = useState<DefaultDataService>(DefaultDataService.getInstance(setContextError));
  const [discussionService] = useState<DiscussionService>(DiscussionService.getInstance(setContextError));
  const [spanishLang, setSpanishLang] = useState('')
  const [englishLang, setEnglishLang] = useState('')
  const navigate = useNavigate();

  const onDropWg = useCallback(acceptedFiles => {
    setWorkingGroupFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setWorkingGroupFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const onDropPl = useCallback(acceptedFiles => {
    setPlenaryFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setPlenaryFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const onDeleteWg = useCallback(() => {
    setWorkingGroupFile(null);
  }, []);

  const onDeletePl = useCallback(() => {
    setPlenaryFile(null);
  }, []);

  const onDeleteCc = useCallback(() => {
    setCoChairsFile(null);
  }, []);

  const onDropCc = useCallback(acceptedFiles => {
    setCoChairsFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setCoChairsFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  useMemo(async () => {
    const list = await defaultService.findAll(appLanguage, 'working-groups');
    setWorkingGroups(list.map(l => new CodeDescription(l.id, l.groupName, l.groupName)));
  }, [appLanguage])

  const submitDiscussion = useCallback(async (data) => {
    setShowSpinner(true);
    data.workingGroupFile = workingGroupFileBase64;
    data.coChairsFile = coChairsFileBase64;
    data.plenaryFile = plenaryFileBase64;

    const discussion = await discussionService.createDiscussion(appLanguage, data);
    if (discussion) {
      discussion.lang = data.language;
      setOpen(false)
      navigate(`/main/update-discussions/${discussion.id}/${discussion.lang}`);
    }

    setShowSpinner(false);
  }, [workingGroupFileBase64, coChairsFileBase64, plenaryFileBase64]);

  const onSubmit = (handleSubmit(submitDiscussion));

  const handleClose = () => {
    setOpen(false)
    reset()
  }

  useEffect(() => {
    if (appLanguage === LanguagesEnum.EN) {
      setEnglishLang('English')
      setSpanishLang('Spanish')
    }
    if (appLanguage === LanguagesEnum.ES) {
      setEnglishLang('Inglés')
      setSpanishLang('Español')
    }
  }, [appLanguage])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style} className={`${classes.dialogContainer}`}>

        <div className={classes.modalContainer}>
          {
            showSpinner ?
              <LoadingComponent styles={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', background: 'transparent', zIndex: '1001' }} />
              : null
          }
          <div className='row'>
            <div className='col-12 center'>
              <KeyIcon style={{ width: '53px', height: '53px' }} />
            </div>
          </div>
          <div className={classes.title}>{t('newDiscussion.modal.title')}</div>
          <div className={classes.subtitle}>{t('newDiscussion.modal.subTitle')}</div>

          {!loading &&
            <div className={classes.content}>
              <div className='row pt-30 pb-30'>
                <div className='col-3'>
                  <FormControl fullWidth error={!!errors.round} >
                    <InputLabel id='demo-simple-select-label' className={classes.label}>{t('newDiscussion.modal.fields.round')}</InputLabel>
                    <Select
                      {...register('round', { required: t('validations.required') })}
                      labelId='labelRound'
                      id='label-round-id'
                      label='ROUND'
                      value={watch('round') || ''}
                      className={classes.select}
                      MenuProps={menuProps}
                    >
                      {
                        roundData?.map((el, i) => {
                          return <MenuItem key={i} className={classes.menuItem} value={+el.roundCode}>{el.roundValue}</MenuItem>
                        })
                      }
                    </Select>
                    <FormHelperText>{errors.round?.message.toString()}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-3'>
                  <FormControl fullWidth error={!!errors.language} >
                    <InputLabel id='demo-simple-select-label' className={classes.label}>
                      {t('newDiscussion.modal.fields.language')}
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='LANGUAGE'
                      className={classes.select}
                      {...register('language', { required: t('validations.required') })}
                      value={watch('language') || ''}
                      MenuProps={menuProps}
                    >
                      <MenuItem className={classes.menuItem} value='es'>{spanishLang}</MenuItem>
                      <MenuItem className={classes.menuItem} value='en'>{englishLang}</MenuItem>
                    </Select>
                    <FormHelperText>{errors.language?.message.toString()}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-6 pl-15'>
                  <FormControl fullWidth error={!!errors.countryId}>
                    <Autocomplete
                      {...register('countryId', { required: t('validations.required') })}
                      className={`${classes.select}`}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disablePortal
                      id="combo-box-demo"
                      onChange={(event: any, newValue) => {
                        (newValue)
                          ? setValue('countryId', newValue.id)
                          : setValue('countryId', '')
                      }}
                      options={countries?.map(r => {
                        return { label: r.countryName, id: r.id }
                      }) ?? []}
                      renderInput={(params) =>
                        <TextField {...params}
                          label={t('newDiscussion.modal.fields.country')}
                          className={classes.autocompleteLabel} error={!!errors.countryId}
                        />}
                    />
                    <FormHelperText>{errors.countryId?.message.toString()}</FormHelperText>
                  </FormControl>

                </div>
              </div>
              <div className='row pb-30 '>
                <div className='col-6 pl-15'>
                  <FormControl fullWidth error={!!errors.workingGroupId}>
                    <Autocomplete
                      {...register('workingGroupId', { required: t('validations.required') })}
                      className={`${classes.select}`}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disablePortal
                      id="combo-box-demo"
                      onChange={(event: any, newValue) => {
                        (newValue)
                          ? setValue('workingGroupId', newValue.id)
                          : setValue('workingGroupId', '')
                      }}
                      options={workingGroups?.map(wg => {
                        return { label: wg.description, id: wg.id }
                      }) ?? []}
                      renderInput={(params) =>
                        <TextField {...params}
                          label={t('newDiscussion.modal.fields.workingGroup')}
                          className={classes.autocompleteLabel} error={!!errors.countryId}
                        />}
                    />
                    <FormHelperText>{errors.countryId?.message.toString()}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-6 pl-15'>
                  <FormControl fullWidth error={!!errors.plenary}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        {...register('plenary', { required: t('validations.required') })}
                        slotProps={{ textField: { error: !!errors.plenary } }}
                        className={`${classes.datePicker}`}
                        format={appLanguage === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                        onChange={(newValue) => setValue('plenary', newValue.format('YYYY-MM-DD'))}

                      />
                    </LocalizationProvider>
                    <FormHelperText>{errors.period?.message.toString()}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className='row pb-30'>
                <span className={classes.dragSection}>
                  <DragAndDrop
                    errors={errors}
                    legend={t('discussion.modal.upload.workingGroupFile')}
                    fieldName='workingGroupFile'
                    onDrop={onDropWg}
                    onDelete={onDeleteWg}
                    required={workingGroupFile === null && plenaryFile === null && coChairsFile === null}
                    pFile={workingGroupFile}
                    register={register} />
                </span>

                <span className={classes.dragSection}>
                  <DragAndDrop
                    errors={errors}
                    legend={t('discussion.modal.upload.plenaryFile')}
                    fieldName='plenaryFile'
                    onDrop={onDropPl}
                    onDelete={onDeletePl}
                    required={(workingGroupFile === null || workingGroupFile === undefined) && plenaryFile === null && coChairsFile === null}
                    pFile={plenaryFile}
                    register={register} />
                </span>

                <span className={classes.dragSection}>
                  <DragAndDrop
                    errors={errors}
                    legend={t('discussion.modal.upload.coChairsFile')}
                    fieldName='coChairsFile'
                    onDrop={onDropCc}
                    onDelete={onDeleteCc}
                    required={workingGroupFile === null && plenaryFile === null && coChairsFile === null}
                    pFile={coChairsFile}
                    register={register} />
                </span>

              </div>
            </div>
          }

          {loading && <LoadingComponent styles={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', background: 'transparent' }} />}
          {!loading && <div className={`row ${classes.buttonsSection}`}>
            <div className='col-12 center lato'>
              <button type='button' className='basic-button mr-20 return-button lato' onClick={handleClose} >{t('buttons.return')}</button>
              <button type='submit' className='basic-button blue-button lato' onClick={onSubmit}>{t('buttons.continue')}</button>
            </div>
          </div>}
        </div>
      </Box>
    </Modal>
  );
};

export default NewDiscussion;
