
import { useCallback, useState } from 'react'
import { Box, CircularProgress, Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ReactComponent as NewMerIcon } from './../../../../assets/icons/new-mer-icon.svg'
import { ReactComponent as DeleteIcon } from './../../../../assets/icons/delete.svg'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf-icon.svg'
import modalStyles from './../new/mer-modal.module.css'
import MutualEvaluationService from '../../../../services/mutual-evaluation.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from '../../../../contexts/error-context';
import { useLanguage } from '../../../../contexts/language-context';
interface MerModalInterface {
  open: boolean
  setOpen: any
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.7)',
  width: 855,
  bgcolor: '#F5F5F5',
  border: 'none',
  borderRadius: '15px',
  padding: '40px 73px',
  outline: 'none'
}
export default function UpdateFileMerModal({ open, setOpen }: MerModalInterface) {
  const { setError: setContextError } = useErrorContext()

  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const [fileBase64, setFileBase64] = useState(null)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const { id: merId, lang: merLanguage } = useParams()
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      merId: +merId,
      language: merLanguage,
      file: ''
    }
  })
  const { errors } = formState

  const service = MutualEvaluationService.getInstance(setContextError)

  const handleClose = () => {
    setOpen(false)
    setFile(null)
    setFileBase64(null)
    reset()
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        const binaryStr = reader.result
        setFileBase64(String(binaryStr).split(',')[1])
      }
      reader.readAsDataURL(file)
    })

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })

  const deleteFile = () => {
    setFile(null)
    setFileBase64(null)
  }

  const onSubmit = (handleSubmit(async (data) => {
    setLoading(true)
    data.file = fileBase64
    const result = await service.updateMutualEvaluation(appLanguage, +merId, data)
    if (result.status === 'OK') {
      toast.success('Ok')
      setOpen(false)
    }
    setLoading(false)
  }))

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className={modalStyles.modalContainer}>
            <div className='row'>
              <div className='col-12 center'>
                <NewMerIcon style={{ width: '55px', height: '67px' }} />
              </div>
            </div>
            <div className={modalStyles.subtitle} style={{ marginBottom: '60px' }} >{t('update-mer.modal.updateFile')}</div>

            <div className={modalStyles.content}>
              <div className='row pb-30'>
                {!file &&
                  <div className={`col-12 ${modalStyles.dropZone} ${!!errors.file && modalStyles.required}`}>
                    <div {...getRootProps()} >
                      <input {...Object.assign(getInputProps(), register('file', { required: t('validations.required') }))} />
                      {
                        <div className={modalStyles.dragHere} >
                          <div className='row'>
                            <div className='col-12 center'>
                              <PDFIcon />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12 center'>
                              {isDragActive ? <p>{t('newMer.modal.fields.dropFiles')} ...</p> : <p>{t('newMer.modal.fields.dragAndDrop')}</p>}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                {!loading && file &&
                  <div className={modalStyles.deleteFile}>
                    {file.name}
                    <span onClick={deleteFile}><DeleteIcon /></span>
                  </div>
                }
                {loading && <CircularProgress style={{ margin: 'auto' }} disableShrink />}
              </div>
            </div>

            {!loading &&
              <div className='row'>
                <div className='col-12 center lato'>
                  <button type='button' className='basic-button mr-20 return-button lato' onClick={handleClose} >{t('buttons.return')}</button>
                  <button type='submit' className='basic-button blue-button lato' onClick={onSubmit}>{t('buttons.continue')}</button>
                </div>
              </div>
            }
          </div>
        </Box>
      </Modal>
    </>
  )
}
