import { useNavigate, useParams } from 'react-router-dom';
import CustomFlag from '../../../../components/common/flag/flag'
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../contexts/language-context';
import { useData } from '../../../../components/hooks/useData';
import { ReactComponent as MerIcon } from './../../../../assets/icons/icono-mer.svg'
import { useEffect, useState } from 'react';
import MutualEvaluationService from '../../../../services/mutual-evaluation.service';
import LoadingComponent from '../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../contexts/error-context';

export default function PreviewFile() {

  const { t } = useTranslation('global')
  const { id: merId, lang: merLanguage } = useParams()
  const { language: appLanguage } = useLanguage()
  const [file, setFile] = useState(null)
  const [loadingMerData, error, merData] = useData<any>(`${appLanguage}/mer/${merId}/header-info?lang=${merLanguage}`, 'GET')
  const [loading, setLoading] = useState<boolean>(false)
  const { setError, setCb } = useErrorContext()
  const merService = MutualEvaluationService.getInstance(setError)
  const navigate = useNavigate()

  const handleCallback = () => {
    navigate('/main/mer-fur')
  }

  useEffect(() => {
    try {
      setLoading(true)
      merService.downloadMutualEvaluationPDF(appLanguage, +merId, merLanguage)
        .then((result) => {
          const objectURL = URL.createObjectURL(result.data) + '#view=FitH'
          setFile(objectURL)
        })
        .catch(e => {
          setLoading(false)
          setError(e)
          setCb(() => handleCallback)
        })
        .finally(() => setLoading(false))
    }
    catch (e) {
      setLoading(false)
      setError(e)
      setCb(() => handleCallback)
    }
    finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <MerIcon />
          <div>
            <span>{t('mer.description')}</span>
          </div>
        </div>
        {!loadingMerData && <div className='mer-info right'>
          <CustomFlag code={merData?.countryCode} />
          <span className='pl-15'>{merData?.countryDesc} - {merData?.period} - {merData?.roundDesc} - {merLanguage.toUpperCase()}</span>
        </div>}
      </div>
      <div className='main-content'>
        <div className='page-content'>

          <div className='center' style={{ height: 1000 }} >
            {!loading && file ?
              <embed
                src={file}
                height={'100%'}
                width={'90%'}
                type="application/pdf"
              ></embed>
              : <LoadingComponent />}
          </div>
        </div>
      </div>
    </>
  )
}
