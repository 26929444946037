import styles from './../../../mer-fur.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useData } from '../../../../../components/hooks/useData';
import { useEffect, useState } from 'react';
import { getEffectivenessForm } from '../../../../../configs/data';
import MutualEvaluationService from '../../../../../services/mutual-evaluation.service';
import { useLanguage } from '../../../../../contexts/language-context';
import { useTranslation } from 'react-i18next';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../../contexts/error-context';
import 'react-quill/dist/quill.snow.css';
export interface ImmediateOutcomeInterface {
  immediateOutcomeId: string,
  merId: number,
  merLanguage: string,
  methodology: string,
  readOnly?: boolean
}

export default function ImmediateOutcome({ immediateOutcomeId, merId, merLanguage, methodology, readOnly = false }: ImmediateOutcomeInterface) {
  const { t } = useTranslation('global')
  const { setError: setContextError } = useErrorContext()
  const effectivenessFormR = getEffectivenessForm(methodology)[immediateOutcomeId]
  const { register, control, watch, setValue, handleSubmit, getValues, reset } = useForm({
    defaultValues: effectivenessFormR
  })
  const { language: appLanguage } = useLanguage()
  const service = MutualEvaluationService.getInstance(setContextError)
  const [loading, error, data] = useData<any>(`${appLanguage}/mer/${merId}/immediate-outcome/${immediateOutcomeId}?lang=${merLanguage}`, 'GET')
  const [loadingOptions, errorOptions, assessmentOptions] = useData<any>(`${appLanguage}/default/assessment-reco-io`, 'GET')
  const [readyForm, setReadyForm] = useState(false)


  const getIOTitle = (code: string) => {
    const key = code.slice(0, 13)
    const immediateOutcome = +code.slice(9, 11)
    const coreIssueNumber = +code.slice(11, 13)
    return { key, immediateOutcome, coreIssueNumber }
  }

  const onSubmit = handleSubmit(async (value) => {
    delete value.immediateOutcomeCode
    await service.updateImmediateOutcome(appLanguage, +merId, immediateOutcomeId, value)
  })

  useEffect(() => {
    setReadyForm(false)
    reset()
  }, [immediateOutcomeId])

  useEffect(() => {
    if (error) {
      setContextError(JSON.stringify(error))
    }
  }, [error])

  useEffect(() => {
    setReadyForm(false)
    if (data) {
      for (const prop in data) {
        let value = data[prop]
        if (prop === 'assessmentId') value = value ? String(value) : null
        setValue(`${prop}`, value)
      }
      setReadyForm(true)
    }
  }, [data])

  return (<>
    {/* <pre> */}
    {/* {JSON.stringify(loadingOptions, null, 2)} */}
    {/* </pre> */}
    {readyForm && !loadingOptions ?
      <div className={styles.merContent}>
        {
          'keyFindings' in effectivenessFormR ?
            <div >
              <div className={styles.elementTitle}>{t(`immediateOutcome.${methodology}.keyFindings`)}</div>
              {!readOnly ? <Controller
                name='keyFindings'
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RichTextArea
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={onSubmit}
                  />
                )}
              /> :
                <div className='ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: data?.['keyFindings']
                  }}
                >
                </div>
              }
            </div>
            :
            null
        }
        {
          'recommendedActions' in effectivenessFormR ?
            <div >
              <div className={styles.elementTitle}>{t(`immediateOutcome.${methodology}.recommendedActions`)}</div>

              {!readOnly ? <Controller
                name='recommendedActions'
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RichTextArea
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={onSubmit}
                  />
                )}
              /> :
                <div className='ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: data?.['recommendedActions']
                  }}
                >
                </div>
              }
            </div>
            :
            null
        }
        {
          'otherRecommendedActions' in effectivenessFormR ?
            <div >
              <div className={styles.elementTitle}>{t(`immediateOutcome.${methodology}.otherRecommendedActions`)}</div>
              {!readOnly ?
                <Controller
                  name='otherRecommendedActions'
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RichTextArea
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={onSubmit}
                    />
                  )}
                />
                : <div className='ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: data?.['otherRecommendedActions']
                  }}
                >
                </div>
              }
            </div>
            :
            null
        }

        {
          data.coreIssues.map((el) => {
            const { coreIssueNumber } = getIOTitle(el.coreIssueCode)
            const index = getValues().coreIssues.findIndex(it => it.coreIssueCode === el.coreIssueCode)
            if (index == -1) return
            return (
              <div key={coreIssueNumber}>
                <div className={styles.elementTitle}>
                  {`${t(`immediateOutcome.${methodology}.coreIssue`)} ${coreIssueNumber}: ${t(`${immediateOutcomeId}.${methodology}.${el.coreIssueCode}`)}`}
                </div>
                {!readOnly ?
                  <Controller
                    name={`coreIssues.${index}.coreIssueDescription`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RichTextArea
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={onSubmit}
                      />
                    )}
                  />
                  : <div className='ql-editor'
                    dangerouslySetInnerHTML={{
                      __html: data?.coreIssues?.[index]?.coreIssueDescription ?? 'ERROR'
                    }}
                  >
                  </div>
                }
              </div>)
          })
        }
        {
          'conclusion' in effectivenessFormR ?
            <div>
              <div className={styles.elementTitle}>{t(`immediateOutcome.${methodology}.conclusion`)}</div>
              {!readOnly ?
                <Controller
                  name='conclusion'
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RichTextArea
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={onSubmit}
                    />
                  )}
                />
                : <div className='ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: data?.['conclusion']
                  }}
                >
                </div>
              }
            </div>
            :
            null
        }
        {
          'assessmentId' in effectivenessFormR ?
            <>
              <div className={styles.elementTitle}>{t(`immediateOutcome.${methodology}.assessmentId`)}</div>
              {!readOnly ?
                <Controller
                  name='assessmentId'
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      sx={{ display: 'inline-flex' }}
                    >
                      {assessmentOptions?.immediateOutcomes?.map((option, j) => {
                        return (
                          <FormControlLabel
                            key={j}
                            value={option.id}
                            control={<Radio onBlur={onSubmit} />}
                            label={`${option.shortDescription} (${option.description})`}
                          />
                        )
                      })}
                    </RadioGroup>
                  )}
                />
                :
                <div>{
                  `${assessmentOptions?.immediateOutcomes.find(r => r.id === data?.assessmentId)?.shortDescription} (${assessmentOptions?.immediateOutcomes.find(r => r.id === data?.assessmentId)?.description})`
                }
                </div>
              }
            </>
            :
            null
        }
        {!readOnly && <div className='row pb-30'>
          <div className='col-12 center'>
            <button className='basic-button blue-button' onClick={onSubmit}>{t('buttons.save')}</button>
          </div>
        </div>}
      </div >
      : <LoadingComponent />
    }
  </>)

}
