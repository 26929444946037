import styles from './../../../mer-fur.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useData } from '../../../../../components/hooks/useData';
import { useCallback, useEffect, useState } from 'react';
import { getTechnicalComplianceForm } from '../../../../../configs/data';
import MutualEvaluationService from '../../../../../services/mutual-evaluation.service';
import { useLanguage } from '../../../../../contexts/language-context';
import { useTranslation } from 'react-i18next';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../../contexts/error-context';
import 'react-quill/dist/quill.snow.css';
interface RecommendationInterface {
  recommendationId: string,
  merId: number,
  merLanguage: string,
  methodology: string,
  readOnly?: boolean
}
export default function Recommendation(
  { merId, merLanguage, recommendationId, methodology, readOnly = false }: RecommendationInterface) {
  const { setError: setContextError } = useErrorContext()
  const { t } = useTranslation('global')

  const { language: appLanguage } = useLanguage()
  const { recommendations } = getTechnicalComplianceForm(methodology).technicalCompliance
  const form = recommendations[recommendationId]
  const { register, setValue, watch, handleSubmit, control, reset, getValues } = useForm({
    defaultValues: form
  })
  const service = MutualEvaluationService.getInstance(setContextError)
  const [loading, error, data] = useData<any>(`${appLanguage}/mer/${merId}/recommendation/${recommendationId}?lang=${merLanguage}`, 'GET')
  const [loadingOptions, errorOptions, assessmentOptions] = useData<any>(`${appLanguage}/default/assessment-reco-io`, 'GET')
  const [readyForm, setReadyForm] = useState(false)

  const getCriterionTitle = (code: string) => {
    const key = code.slice(0, 9)
    const recommendation = +code.slice(9, 11)
    const criterion = +code.slice(11, 13)
    const additional = (code.slice(13) ?? '').toUpperCase()
    return { key, recommendation, criterion, additional }
  }

  const onSubmit = handleSubmit(async (value) => {
    delete value.recommendationCode
    delete value.keyDeficiencies
    value.assessmentId = value.assessmentId ? +value.assessmentId : null
    await service.updateRecommendation(appLanguage, merId, recommendationId, value)
  })

  useEffect(() => {
    setReadyForm(false)
    reset()
  }, [recommendationId])


  useEffect(() => {
    setReadyForm(false)
    if (data) {
      for (const prop in data) {
        let value = data[prop]
        if (prop === 'assessmentId') value = value ? String(value) : null
        setValue(`${prop}`, value)
      }
      setReadyForm(true)
    }
  }, [data, appLanguage])

  useEffect(() => {
    if (error) {
      setContextError(JSON.stringify(error))
    }
  }, [error])

  return (
    <>
      {
        readyForm && !loadingOptions ?
          <div className={styles.merContent}>
            <div className={styles.elementTitle}>
              {t(`recommendation.${methodology}.introduction`)}
            </div>
            {/* <pre>
              {JSON.stringify(watch(), null, 2)}
            </pre> */}
            {!readOnly ?
              <Controller
                name='introduction'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <RichTextArea
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={onSubmit}
                  />
                )}
              />
              : <div className='ql-editor'
                dangerouslySetInnerHTML={{
                  __html: data?.['introduction']
                }}
              >
              </div>
            }

            {data.criterias.map((el) => {
              const { additional, key, criterion, recommendation } = getCriterionTitle(el.criterionCode)
              const index = getValues().criterias.findIndex(it => it.criterionCode === el.criterionCode)
              if (index === -1) return
              return (
                <div key={index}>
                  <div className={styles.elementTitle}>
                    {t(`recommendation.${methodology}.` + key)} {recommendation}.{criterion} {additional}
                  </div>
                  {!readOnly ?
                    <Controller
                      name={`criterias.${index}.criterionDescription`}
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <RichTextArea
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={onSubmit}
                        />
                      )}
                    /> :
                    <div className='ql-editor'
                      dangerouslySetInnerHTML={{
                        __html: data?.criterias?.[index]?.criterionDescription ?? 'ERROR'
                      }}
                    ></div>
                  }
                </div>
              )
            })}

            <div className={styles.elementTitle}>{t(`recommendation.${methodology}.weightingAndConclusion`)}</div>
            {!readOnly ?
              <Controller
                name={'weightingAndConclusion'}
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <RichTextArea
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={onSubmit}
                  />
                )}
              />
              :
              <div className='ql-editor'
                dangerouslySetInnerHTML={{
                  __html: data?.['weightingAndConclusion']
                }}
              ></div>
            }

            <div className={styles.elementTitle}>{t(`recommendation.${methodology}.assessmentId`)}</div>
            {!readOnly ? <Controller
              name='assessmentId'
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  sx={{ display: 'inline-flex' }}
                >
                  {assessmentOptions?.recommendations?.map((option, j) => {
                    return (
                      <FormControlLabel
                        key={j}
                        value={option.id}
                        control={<Radio onBlur={onSubmit} />}
                        label={`${option.shortDescription} (${option.description})`}
                      />
                    )
                  })}
                </RadioGroup>
              )}
            /> :
              <div>{
                `${assessmentOptions?.recommendations.find(r => r.id === data?.assessmentId)?.shortDescription} (${assessmentOptions?.recommendations.find(r => r.id === data?.assessmentId)?.description})`
              }
              </div>
            }
            {!readOnly && <div className='row pb-30'>
              <div className='col-12 center'>
                <button className='basic-button blue-button' onClick={onSubmit}>{t('buttons.save')}</button>
              </div>
            </div>}
          </div>
          : <LoadingComponent />}
    </>
  )

}
