import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useLanguage } from '../../../contexts/language-context';
import { useTranslation } from 'react-i18next';
import './confirmDialog.css';
export interface ConfirmDialogInterface {
  open: boolean,
  setOpen: (value: boolean) => void,
  title: string,
  content: string,
  cb: () => void

}
const ConfirmDialog = ({ open, setOpen, title, content, cb }: ConfirmDialogInterface) => {
  const { t } = useTranslation('global')
  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccessClose = () => {
    setOpen(false)
    cb()
  };
  return (
    <>
      <Dialog
        // style={{ width: '20%' }}
        className='confirm-dialog'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='confirm-dialog-title' style={{ padding: 0, fontWeight: 700 }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ width: '200px' }}>
          <DialogContentText id="alert-dialog-description" style={{ color: '#003479', fontSize: '20px', fontWeight: 600 }}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='basicBtn cancelBtn' onClick={handleClose} style={{ marginRight: '30px' }}>{t('dialog.delete.cancel')}</Button>
          <Button className='basicBtn confirmBtn' onClick={handleSuccessClose}>
            {t('dialog.delete.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>)
}
export default ConfirmDialog;
