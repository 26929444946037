import { useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import CustomRadioButton from '../../../../../components/common/radio-button/radiobutton';
import styles from './../../../mer-fur.module.css';
import { useData } from '../../../../../components/hooks/useData';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RichTextArea from '../../../../../components/common/rich-text-area/rich-text-area';
import LoadingComponent from '../../../../../components/common/loading/loading';
import 'react-quill/dist/quill.snow.css';
import { useLanguage } from '../../../../../contexts/language-context';
export interface MerTextAreaListInterface {
  list: any[],
  formData: string
  methodology: string
  control: Control<any>,
  onSubmit: any,
  setValue?: any,
  urlFetchData?: string
  merLanguage: string,
  watch?: any,
  reset: any,
  readOnly?: boolean
}

export default function MerTextAreaList({
  list,
  control,
  formData,
  methodology,
  onSubmit,
  setValue,
  merLanguage,
  urlFetchData,
  reset,
  watch,
  readOnly = false

}: MerTextAreaListInterface) {

  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const { id: merId } = useParams()
  const [loading, error, data] = useData<any>(`${appLanguage}/mer/${+merId}/find-part/${urlFetchData}?lang=${merLanguage}`, 'GET')
  const [readyForm, setReadyForm] = useState(false)

  useEffect(() => {
    setReadyForm(false)
    reset()
  }, [urlFetchData])

  useEffect(() => {

    setReadyForm(false)
    if (data) {
      if (!readOnly) {
        for (const prop in data) {
          setValue(`${formData}.${prop}`, data[prop] ?? '')
        }
      }
      setReadyForm(true)
    }
  }, [data])

  return (
    <>
      {readyForm ?
        <div className={styles.merContent}>
          {/* <pre>{JSON.stringify(watch(formData), null, 2)}</pre> */}
          {list.map((el, i) => {
            return (
              <div key={i}>
                <div className={styles.elementTitle}>
                  {t(`${formData}.${methodology}.${el.code}`)}
                </div>
                {!readOnly && (el.elementType && !el.options) &&
                  <Controller
                    name={`${formData}.${el.code}`}
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <RichTextArea
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={onSubmit}
                      />
                    )}
                  />}
                {readOnly && (el.elementType && !el.options) &&
                  <div className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: data?.[el.code] }}>
                  </div>
                }

                {!readOnly && (!el.elementType && el.options?.length > 0) &&
                  <div>
                    {el.options
                      .map((options, i) => <div
                        key={i}
                        className={styles.radioButton}>
                        <CustomRadioButton
                          label={options.value}
                        />
                      </div>)
                    }
                  </div>
                }
                {readOnly && (!el.elementType && el.options?.length > 0) &&
                  <div>
                    {el.options
                      .map((options, i) => <div
                        key={i}
                        className={styles.radioButton}>
                        <CustomRadioButton
                          label={options.value}
                        />
                      </div>)
                    }
                  </div>
                }
              </div>)
          })
          }
          {!readOnly && <div className='row pb-30'>
            <div className='col-12 center'>
              <button className='basic-button blue-button'>{t('buttons.save')}</button>
            </div>
          </div>}
        </div >
        : <LoadingComponent />
      }
    </>
  )
}
