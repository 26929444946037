import './index.css'

import App from './App'
import { DefaultDataProvider } from './contexts/default-data-context'
import { I18nextProvider } from 'react-i18next'
import { LanguageProvider } from './contexts/language-context'
import UserService from './services/UserService'
import { createRoot } from 'react-dom/client'
import globalEn from './translations/en/globals.json'
import globalEs from './translations/es/globals.json'
import i18next from 'i18next'
import reportWebVitals from './reportWebVitals'
import { ErrorContext, ErrorProvider } from './contexts/error-context'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    es: {
      global: globalEs,
    },
    en: {
      global: globalEn,
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

const renderApp = () =>
  root.render(
    // <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <LanguageProvider>
        <DefaultDataProvider>
          <ErrorProvider>
            <App />
          </ErrorProvider>
        </DefaultDataProvider>
      </LanguageProvider>
    </I18nextProvider>,
    // </React.StrictMode>
  )

UserService.initKeycloak(renderApp)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'production') {
  reportWebVitals()
}
