import { Controller, useForm } from 'react-hook-form';
import { MERInformation, getMerInformationForm } from '../../../../../configs/data';
import styles from './../../../mer-fur.module.css';
import { useData } from '../../../../../components/hooks/useData';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../../../contexts/language-context';
import MutualEvaluationService from '../../../../../services/mutual-evaluation.service';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import LoadingComponent from '../../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../../contexts/error-context';
interface SpecificCharacteristicsInterface {
  methodology: string
  merLanguage: string
  merId: number,
  readOnly?: boolean
}

export default function SpecificCharacteristics({ methodology, merLanguage, merId, readOnly = false }: SpecificCharacteristicsInterface) {
  const { setError: setContextError } = useErrorContext()
  const { t } = useTranslation('global')
  const { language: appLanguage } = useLanguage()
  const [loading, error, data] = useData<any>(`${appLanguage}/mer/${merId}/specific-characteristics?lang=${merLanguage}`, 'GET')
  const [optionLoading, optionError, optionData] = useData<any>(`${appLanguage}/default/specific-characteristics-options`, 'GET')
  const { specificCharacteristics } = getMerInformationForm()
  const { register, setValue, handleSubmit, watch, control } = useForm({
    defaultValues: specificCharacteristics
  })
  const [renderList, setRenderList] = useState<any[]>(null)
  const [renderListSource, setRenderListSource] = useState<any[]>(null)
  const service = MutualEvaluationService.getInstance(setContextError)
  const onSubmit = handleSubmit(async (value) => {
    await service.updateSpecificsCharacteristics(appLanguage, merId, { merId, lang: merLanguage, ...value })
  })

  useEffect(() => {
    if (data) {
      const aux = []
      for (const prop in data) {
        setValue(`${prop}`, data[prop] ?? '')
        if (prop != 'merId')
          aux.push({ code: prop, value: data[prop] })
      }
      setRenderList(aux.filter(r => !r.code.includes('Source')))
      setRenderListSource(aux.filter(r => r.code.includes('Source')).map(r => r.code))
    }
  }, [data])


  return (
    <>
      {/* <pre>
        {JSON.stringify(watch(), null, 2)}
      </pre> */}
      {(!loading && !optionLoading) ?
        <div className={styles.merContent}>
          {renderList?.map((el) => {
            const sourceElement = el.code + 'Source'
            return (
              <div key={el.code}>
                <div className={styles.elementTitle}>{t(`specificCharacteristics.${methodology}.${el.code}`)}</div>
                {!readOnly ?
                  <Controller
                    name={el.code}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup {...field}
                        sx={{ display: 'inline-flex' }}>
                        {optionData?.[el.code]?.map((option: any, j: number) => {
                          return (
                            <FormControlLabel
                              key={j}
                              value={option.optionCode}
                              control={<Radio onBlur={onSubmit} />}
                              label={option.optionValue}
                              style={{ width: '100%' }}
                            />
                          )
                        })

                        }
                      </RadioGroup>
                    )}
                  /> :
                  <div> {optionData?.[el.code].find(r => r.optionCode === data?.[el.code])?.optionValue ?? 'not found'}  </div>
                }
                {
                  renderListSource.includes(sourceElement) && !readOnly &&
                  <div key={sourceElement} className='mb-40'>
                    <TextField
                      {...register(sourceElement)}
                      id="standard-basic"
                      label={t('inputs.placeholders.source')}
                      variant="standard"
                      style={{ width: '80%', fontWeight: '600', fontSize: '16px' }}
                      inputProps={{ onBlur: onSubmit }}
                    />
                  </div>
                }
                {
                  renderListSource.includes(sourceElement) && readOnly &&
                  <div key={sourceElement} className='mb-40'>
                    <div className={styles.elementTitle}>{t('inputs.placeholders.source')}</div>
                    <div>{data?.[sourceElement]}</div>
                  </div>
                }
              </div>
            )
          })
          }
          {!readOnly && <div className='row pb-30'>
            <div className='col-12 center'>
              <button className='basic-button blue-button' onClick={onSubmit}>{t('buttons.save')}</button>
            </div>
          </div>}
        </div>
        : <LoadingComponent />}
    </>
  )
}
