import {
  AssessmentBodyDTO,
  CountryDTO,
  MerYearDTO,
  RoundDTO,
  SpecificCharacteristicsOptionsDTO,
  Step3CompareResponseDTO,
} from '../model/dto-module'

import BaseService, { FindAllType } from './BaseService'
import { CodeDescription } from '../model/models-module'

export enum FilterServiceEnum {
  ASSESSMENT_BODY = 'ASSESSMENT_BODY',
  ROUND = 'ROUND',
  DATE = 'DATE',
  SPECIFIC_CHARACTERISTICS = 'SPECIFIC CHARACTERISTICS',
  COUNTRY = 'COUNTRY',
}

export default class ComparisonToolService extends BaseService {
  private static _instance: ComparisonToolService

  public static getInstance(setError: (message: string | null) => void): ComparisonToolService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async filter(
    filterType: FilterServiceEnum,
    appLanguage?: string,
    dataList?: any[]
  ): Promise<CodeDescription[] | Map<string, CodeDescription[]>> {
    let url = ''
    let data
    switch (filterType) {
      case FilterServiceEnum.ASSESSMENT_BODY:
        url = `${appLanguage}/default/assessment-bodies`
        break
      case FilterServiceEnum.ROUND:
        url = `${appLanguage}/default/rounds`
        break
      case FilterServiceEnum.DATE:
        url = `${appLanguage}/mer/mer-years`
        break
      case FilterServiceEnum.SPECIFIC_CHARACTERISTICS:
        url = `${appLanguage}/default/specific-characteristics-options`
        break
      case FilterServiceEnum.COUNTRY:
        url = `${appLanguage}/default/countries`
        break
    }

    try {
      if (!dataList) {
        const response = await this.client({
          url,
          method: 'GET',
        })
        data = response.data.data
      } else {
        data = dataList
      }

      if (filterType === FilterServiceEnum.SPECIFIC_CHARACTERISTICS) {
        return SpecificCharacteristicsOptionsDTO.fromData(data)
      }

      const dataConverted: CodeDescription[] = []
      data.forEach((d) => {
        switch (filterType) {
          case FilterServiceEnum.ASSESSMENT_BODY:
            dataConverted.push(AssessmentBodyDTO.fromData(d))
            break
          case FilterServiceEnum.ROUND:
            dataConverted.push(RoundDTO.fromData(d))
            break
          case FilterServiceEnum.DATE:
            dataConverted.push(MerYearDTO.fromData(d))
            break
          case FilterServiceEnum.COUNTRY:
            dataConverted.push(CountryDTO.fromData(d))
            break
        }
      })

      return dataConverted
    } catch (err: any) {
      this.handleError(err)
    }
  }

  async step2MenuRound4(language: string): Promise<any> {
    const dataConverted: Map<string, any> = new Map()
    try {
      const url = `${language}/comparison-tool/step2-menu/rounds/4`

      const response = await this.client({
        url,
        method: 'GET',
      })

      const data = response.data.data

      for (const prop in data) {
        if (prop === 'menu') {
          dataConverted.set(prop, data[prop])
        } else {
          const dataArray = []
          data[prop].forEach((el) => {
            dataArray.push(CodeDescription.fromData(el))
          })

          switch (prop) {
            case 'riskAndContextFactors': {
              dataConverted.set(prop, dataArray)
              break
            }
            case 'recommendation01':
            case 'recommendation02':
            case 'recommendation03':
            case 'recommendation04':
            case 'recommendation05':
            case 'recommendation06':
            case 'recommendation07':
            case 'recommendation08':
            case 'recommendation09':
            case 'recommendation10':
            case 'recommendation11':
            case 'recommendation12':
            case 'recommendation13':
            case 'recommendation14':
            case 'recommendation15':
            case 'recommendation16':
            case 'recommendation17':
            case 'recommendation18':
            case 'recommendation19':
            case 'recommendation20':
            case 'recommendation21':
            case 'recommendation22':
            case 'recommendation23':
            case 'recommendation24':
            case 'recommendation25':
            case 'recommendation26':
            case 'recommendation27':
            case 'recommendation28':
            case 'recommendation29':
            case 'recommendation30':
            case 'recommendation31':
            case 'recommendation32':
            case 'recommendation33':
            case 'recommendation34':
            case 'recommendation35':
            case 'recommendation36':
            case 'recommendation37':
            case 'recommendation38':
            case 'recommendation39':
            case 'recommendation40': {
              if (!dataConverted.has('technicalCompliance')) {
                dataConverted.set('technicalCompliance', new Map())
              }
              dataConverted.get('technicalCompliance').set(prop, dataArray)
              break
            }
            default:
              {
                if (!dataConverted.has('effectiveness')) {
                  dataConverted.set('effectiveness', new Map())
                }
                dataConverted.get('effectiveness').set(prop, dataArray)
              }
              break
          }
        }
      }
    } catch (err: any) {
      this.handleError(err)
      throw err
    }

    return dataConverted
  }

  async compare(language: string, comparisonRequest: any): Promise<any> {
    try {
      const url = `${language}/comparison-tool/step3/compare`

      const response = await this.client({
        url,
        method: 'POST',
        data: comparisonRequest,
      })

      const data = response.data.data
      return Step3CompareResponseDTO.fromData(language, data)
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  // async pdf(language: string, html: string): Promise<any> {
  //   const url = `${language}/comparison-tool/step3/pdf`

  //   try {
  //     const response = await this.client({
  //       url,
  //       method: 'POST',
  //       data: { htmlContent: html },
  //       responseType: 'blob',
  //     })
  //     return response.data
  //   } catch (error) {
  //     this.handleError(error)
  //   }
  // }

  // async word(language: string, html: string): Promise<any> {
  //   const url = `${language}/comparison-tool/step3/word`

  //   try {
  //     const response = await this.client({
  //       url,
  //       method: 'POST',
  //       data: { htmlContent: html },
  //       responseType: 'blob',
  //     })
  //     return response.data
  //   } catch (error) {
  //     this.handleError(error)
  //   }
  // }


  async findDataGrid({ lang, args = '', limit = 10, offset, sort }: FindAllType) {
    try {
      const url = `${lang}/comparison-tool/datatable`;
      return await this.baseFindAll({ lang, args, limit, offset, sort }, url)

    } catch (err: any) {
      throw await this.handleError(err)
    }

  }


}
