import { useTranslation } from 'react-i18next'
import styles from './../../../mer-fur.module.css'
import { effectivenessMER } from '../../../../../configs/data'

interface ViewerProps {
  data: any[]
  methodology: string
}

export function ImmediateOutcomeViewer({ data, methodology }: ViewerProps) {

  const { t } = useTranslation('global')

  const getIOTitle = (code: string) => {
    const key = code.slice(0, 16)
    const immediateOutcome = +code.slice(16, 18)
    return { key, immediateOutcome }
  }

  const getCITitle = (code: string) => {
    const key = code.slice(0, 13)
    const immediateOutcome = +code.slice(9, 11)
    const coreIssueNumber = +code.slice(11, 13)
    return { key, immediateOutcome, coreIssueNumber }
  }
  const elements = effectivenessMER.filter(el => el.visibleWithMethodology.find(it => it === methodology))

  return (
    <>
      <div className={styles.separator} >{t('update-mer.menu.effectiveness.title')}</div>
      <div className={styles.merViewerContent}>
        {
          data.map(el => {
            const { immediateOutcome: iONumber } = getIOTitle(el.immediateOutcomecode)
            const io = elements.find(io => io.key === el.immediateOutcomecode)
            const ioMethodology = io.items.filter(x => x.visibleWithMethodology.find(y => y === methodology))
            return (
              <div id={el.immediateOutcomecode} key={el.immediateOutcomecode}>
                <div className={styles.sectionTitle}>  {t(`immediateOutcome.${methodology}.title`)} {iONumber} </div>
                {el.keyFindings !== undefined &&
                  <>
                    <div className={styles.sectionSubtitle}>  {t(`immediateOutcome.${methodology}.keyFindings`)}</div>
                    <div className='ql-editor'
                      dangerouslySetInnerHTML={{ __html: el['keyFindings'] }}>
                    </div>
                  </>
                }
                {ioMethodology.find(e => e.code === 'recommendedActions') &&
                  <>
                    <div className={styles.sectionSubtitle}>  {t(`immediateOutcome.${methodology}.recommendedActions`)}</div>
                    <div className='ql-editor'
                      dangerouslySetInnerHTML={{ __html: el['recommendedActions'] }}>
                    </div>
                  </>
                }
                {ioMethodology.find(e => e.code === 'otherRecommendedActions') &&
                  <>
                    <div className={styles.sectionSubtitle}>   {t(`immediateOutcome.${methodology}.otherRecommendedActions`)}</div>
                    <div className='ql-editor'
                      dangerouslySetInnerHTML={{ __html: el['otherRecommendedActions'] }}>
                    </div>
                  </>
                }
                {
                  el['coreIssues'].map(ci => {
                    const { coreIssueNumber } = getCITitle(ci.coreIssueCode)
                    return (
                      <div key={`${el.immediateOutcomecode}${ci.coreIssueCode}`} >
                        <div className={styles.sectionSubtitle}>
                          {`${t(`immediateOutcome.${methodology}.coreIssue`)} ${coreIssueNumber}:${t(`${el.immediateOutcomecode}.${methodology}.${ci.coreIssueCode}`)}`}
                        </div>
                        <div className='ql-editor'
                          dangerouslySetInnerHTML={{ __html: ci.description }}>
                        </div>
                      </div>)
                  })
                }

                {ioMethodology.find(e => e.code === 'conclusion') &&
                  <div>
                    <div className={styles.sectionSubtitle}>   {t(`immediateOutcome.${methodology}.conclusion`)}</div>
                    <div className='ql-editor'
                      dangerouslySetInnerHTML={{ __html: el['conclusion'] }}>
                    </div>
                  </div>
                }
              </div >
            )
          })
        }
      </div>
    </>
  )
}
