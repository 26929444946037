import './step-three.css'
import 'react-quill/dist/quill.snow.css'

import { useEffect, useRef, useState } from 'react'

import ComparisonToolService from '../../../services/comparison-tool.service'
import CustomFlag from '../../../components/common/flag/flag'
import LoadingComponent from '../../../components/common/loading/loading'
import { ReactComponent as PdfIcon } from './../../../assets/icons/pdf-icon.svg'
import { Step3CompareResponseDTO } from '../../../model/dto-module'
import { TableDetailCell } from '../../../model/models-module'
import { ReactComponent as WordIcon } from './../../../assets/icons/word-icon.svg'
import { ReactComponent as XlsIcon } from './../../../assets/icons/xls-icon.svg'
import { handleFile } from '../../../utils/CommonUtils'
import { useLanguage } from '../../../contexts/language-context'
import { useTranslation } from 'react-i18next'
import { useErrorContext } from '../../../contexts/error-context'
import ReportingService from '../../../services/reporting.service'

interface Props {
  setStep: any
  data: { round: number; mers: Map<number, TableDetailCell>; comparables: string[] }
}

const ComparisonStepThree: React.FC<Props> = ({ setStep, data }: Props) => {
  const { t } = useTranslation('global')
  const { language } = useLanguage()
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [compareResponseData, setCompareResponseData] = useState<Step3CompareResponseDTO[]>()
  const divRef = useRef(null)
  const { setError: setContextError } = useErrorContext()
  const comparisonToolService = ComparisonToolService.getInstance(setContextError)
  const reportingService = ReportingService.getInstance(setContextError)
  const [comparisonToolRequest, setComparisonToolRequest] = useState<any>(null)
  const downloadXLS = async () => {

    try {
      setShowSpinner(true)
      const result = await reportingService.downloadComparisonToolExcel(language, comparisonToolRequest)
      handleFile(result?.data, 'comparison-tool-report.xlsx')
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setShowSpinner(false)
    }
  }

  const handlePdf = async () => {
    try {
      setShowSpinner(true)
      if (divRef.current) {
        const result = await reportingService.downloadPdf(language, divRef.current.innerHTML)
        if (result) handleFile(result, 'comparison.pdf')
      }
    } finally {
      setShowSpinner(false)
    }
  }

  const handleWord = async () => {
    try {
      setShowSpinner(true)
      if (divRef.current) {
        // const result = await comparisonToolService.word(language, divRef.current.innerHTML)
        const result = await reportingService.downloadDoc(language, divRef.current.innerHTML)
        if (result) handleFile(result, 'comparison.docx')
      }
    } finally {
      setShowSpinner(false)
    }
  }

  useEffect(() => {
    let comparisonRequest = null
    setDataLoaded(false)

    if (language && data) {
      comparisonRequest = {
        round: data.round,
        mers: [],
        comparables: data.comparables,
      }

      data.mers.forEach((value, _) => {
        const mer = {
          id: value.data.merId,
          lang: value.data.merLanguage,
        }
        comparisonRequest.mers.push(mer)
      })
    }
    setComparisonToolRequest(comparisonRequest)
    comparisonToolService.compare(language, comparisonRequest)
      .then((result: Step3CompareResponseDTO[]) => {
        setCompareResponseData(result)
      })
      .catch((e) => {
        setCompareResponseData([])
        console.error(e)
      })
      .finally(() => {
        setDataLoaded(true)
      })
  }, [language, data])

  useEffect(() => {
    setShowSpinner(!dataLoaded)
  }, [dataLoaded])

  const onReturnStepTwo = () => {
    setStep(2)
  }

  return (
    <>
      {showSpinner ? (
        <div
          style={{
            position: 'fixed',
            height: '100%',
            width: '100%',
            textAlign: 'center',
            zIndex: '1201',
            left: '0',
            background: '#00000026',
          }}
        >
          <LoadingComponent />
        </div>
      ) : null}
      {dataLoaded && (
        <div className='page-content'>
          <div className='step-three-container'>
            <div className='title'>{t('comparison-tool.step-3.results')}</div>
            <div className="result-wrapper">
              <div ref={divRef} className='results'>
                {compareResponseData.map(({ comparable }, idx) => {
                  return (
                    <div key={idx} className='comparison-key'>
                      <div className='comparison-key-name'>{comparable.title}</div>
                      <div className='comparison-key-content'>
                        {comparable.comparison.map((comparison, j) => {
                          return (
                            <div key={j} className='comparison-key-item'>
                              <div className='result-item-title'>
                                <span style={{ paddingRight: '20px' }}>
                                  <CustomFlag code={comparison.countryCode} />
                                </span>
                                {`${comparison.countryName} - ${comparison.merDate} - ${comparison.merRound}`}
                              </div>
                              <div>
                                <div
                                  className='ql-editor'
                                  dangerouslySetInnerHTML={{
                                    __html: comparison.description,
                                  }}
                                ></div>
                              </div>
                              <div className='page-break'></div>
                            </div>
                          )
                        })}
                      </div>
                      <div className='page-break'></div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='buttons-section'>
              <button className='mr-20 basic-button blue-button lato' onClick={onReturnStepTwo}>
                {t('buttons.return')}
              </button>
              {compareResponseData?.length > 0 && (
                <>
                  <button className='mr-20 basic-button blue-button lato' onClick={handlePdf}>
                    <PdfIcon className='pr-15' /> {t('buttons.download')} PDF
                  </button>
                </>
              )}
              <button
                className='mr-20 basic-button-icon blue-button lato svg-button'
                onClick={downloadXLS}
              >
                <XlsIcon /> {t('buttons.download')} XLS
              </button>


              {compareResponseData?.length > 0 && (
                <>
                  <button className='mr-20 basic-button blue-button lato' onClick={handleWord}>
                    <WordIcon className='pr-15' /> {t('buttons.download')} DOC
                  </button>
                </>
              )}

            </div>
            <div className='not-available'>{t('comparison-tool.step-3.not-available')}</div>
          </div>
        </div>
      )}
    </>
  )
}
export default ComparisonStepThree
