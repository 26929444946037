/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';
import { FollowUpReport } from '../model/models-module';

export default class FollowUpReportService extends BaseService {

  private static _instance: FollowUpReportService;

  public static getInstance(setError: (message: string | null) => void): FollowUpReportService {
    if (!this._instance) {
      this._instance = new this(setError)
    }
    return this._instance
  }

  private constructor(setError: (message: string | null) => void) {
    super(setError)
  }

  async saveFUR(appLanguage: string, data: any): Promise<FollowUpReport> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur`, method: 'POST', data })
      if (result.data?.errorCode && result.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async addReRating(appLanguage: string, furId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}/re-rating`, method: 'POST', data })
      if (result.data?.errorCode && result.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async updateFurFile(appLanguage: string, furId: number, data: any): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}/file`, method: 'PUT', data })
      if (result.data?.errorCode && result.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async downloadFurPDF(appLanguage: string, furId: number, language: string): Promise<any> {
    try {
      const result = await this.client({ url: `${appLanguage}/fur/${furId}/file/${language}`, method: 'GET', responseType: 'blob' })
      if (result?.data?.errorCode && result?.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }


  async deleteReRating(appLanguage: string, furId: number, id: number): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}/re-rating/${id}`, method: 'DELETE' })
      if (result.data?.errorCode || result.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    }
    catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async deleteFur(appLanguage: string, furId: number, language: string): Promise<any> {
    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}?lang=${language}`, method: 'DELETE' })
      if (result.data?.errorCode || result.data?.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    }
    catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async publishFur(appLanguage: string, furId: number, language: string): Promise<any> {

    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}/publish?lang=${language}`, method: 'PUT' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }

  async unPublishFur(appLanguage: string, furId: number, language: string): Promise<any> {

    try {
      const { data: result } = await this.client({ url: `${appLanguage}/fur/${furId}/unpublish?lang=${language}`, method: 'PUT' })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result
    } catch (err: any) {
      throw await this.handleError(err)
    }
  }



}
