import axios, { AxiosError, AxiosInstance } from 'axios'

import AxiosInterceptor from './http/AxiosInterceptor'
import { IErrorResponse } from '../components/hooks/useData'

export type FindAllType = {
  lang: string
  args?: string
  limit?: number
  offset?: number
  sort?: string
}
export default class BaseService {

  baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL
  // timeout: number = +process.env.REACT_APP_API_REQUEST_TIMEOUT_MS || 50000
  client: AxiosInstance

  _error: (message: string | null) => void

  get error() {
    return this._error
  }
  set error(error: (message: string | null) => void) {
    this._error = error
  }
  constructor(setError: (message: string | null) => void) {
    this.client = AxiosInterceptor.addInterceptor(this.baseURL)
    this.error = setError
  }

  // async handleError(err: any): Promise<void> {
  //   const error = err as Error | AxiosError<IErrorResponse>
  //   if (axios.isAxiosError(error)) {
  //     if (error.code === AxiosError.ERR_CANCELED) {
  //       return
  //     }

  //     let data
  //     if (error?.response?.data && error?.response?.data instanceof Blob) {
  //       const text = await error.response.data.text()
  //       data = JSON.parse(text)
  //     } else {
  //       data = error?.response?.data
  //     }

  //     if (Array.isArray(data?.message)) {
  //       // const diaglog = new ConfirmDialog()
  //       toast.error(data?.message[0], {
  //         position: toast.POSITION.TOP_RIGHT,
  //       })
  //     } else {
  //       toast.error(error.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       })
  //     }
  //   } else {
  //     console.error(error.message)
  //     toast.error(error.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     })
  //   }
  // }

  async handleError(err: any): Promise<void> {
    const error = err as Error | AxiosError<IErrorResponse>
    let errorMsg = null
    if (axios.isAxiosError(error)) {
      if (error.code === AxiosError.ERR_CANCELED) {
        return
      }

      let data

      if (error?.response?.data && error?.response?.data instanceof Blob) {
        const text = await error.response.data.text()
        data = JSON.parse(text)
      } else {
        data = error?.response?.data
      }

      if (Array.isArray(data?.message)) {
        errorMsg = data?.message[0]
      } else {
        errorMsg = error.message
      }
    } else {
      console.error(error.message)
      errorMsg = error.message
    }
    this.error(errorMsg)
    return errorMsg
  }
  async baseFindAll({ args = '', limit = 10, offset, sort }: FindAllType, url: string) {
    try {
      let params = ''
      if (offset) {
        params += `&offset=${offset}`
      }
      if (args != '') {
        params += `&filter=${args}`
      }
      if (sort! && sort != '') {
        params += `&sort=${sort}`
      }
      const { data: result } = await this.client({
        url: `${url}?limit=${limit}${params}`,
        method: 'GET',
      })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
