import { FC, createElement } from 'react'
import classes from './progress-bar.module.css'

interface ProgressBarInterface {
  icon: FC<any>
  title: string,
  subtitle?: string, 
  children: React.ReactNode,
}

export default function ProgressBar({ title, subtitle, icon, children }: ProgressBarInterface) {
  return (
    <div className='progress-bar lato'>
      <div className='description-wrapper'>
        {createElement(icon)}
        <div>
          <div>{title}</div>
          <div className={classes['subtitleProgressBar']}>{subtitle}</div>
        </div>
      </div>
      {children ?? children}
    </div>
  )
}
