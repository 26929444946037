import { useTranslation } from 'react-i18next'
import CustomAccordionFilter, { AccordionGroupData } from '../../components/common/accordionV2/accordion-v2'
import { useCallback, useEffect, useMemo, useState } from 'react'
import LoadingComponent from '../../components/common/loading/loading'
import DataTable from '../../components/common/datatable/Datatable'
import { ChildrenTypeEnum } from '../../configs/data'
import { ReactComponent as ExpandIconDefault } from '../../assets/icons/expand-icon.svg'
import { ReactComponent as CollapseIconDefault } from '../../assets/icons/collapse-icon.svg'
import { useDefaultData } from '../../contexts/default-data-context'
import { CodeDescription, TableAction, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { useLanguage } from '../../contexts/language-context'
import ProgressBar from '../../components/common/progress-bar/progress-bar';
import { ReactComponent as KeyIcon } from '../../assets/icons/icono-key.svg'
import { SpecificCharacteristicsOptionsDTO } from '../../model/dto-module'
import DefaultDataService from '../../services/default-data.service'
import DiscussionService from '../../services/discussion.service'
import CustomFlag from '../../components/common/flag/flag'
import { useNavigate } from 'react-router-dom'
import NewDiscussion from './new-discussion/new-discussion-modal'
import classes from './discussion.module.css'
import { ReactComponent as NewMerIcon } from './../../assets/icons/new-mer-icon.svg'
import { ReactComponent as EditIcon } from './../../assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from './../../assets/icons/delete.svg'
import ConfirmDialog from '../../components/common/confirm-dialog/confirmDialog'
import { parseParams } from '../../utils/CommonUtils'
import { useErrorContext } from '../../contexts/error-context'


const Discussion = () => {
  const { setError: setContextError } = useErrorContext()
  // Menu filters
  const [initialValues, setInitialValues] = useState<Map<string, any>>(undefined)
  const [filtersData, setFiltersData] = useState<Map<string, any>>(new Map<string, any>())
  const { countries, assessmentBodies, error: filterErrors, specificCharacteristics, rounds, loading } = useDefaultData()
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingDataGrid, setLoadingDataGrid] = useState<boolean>(true);
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [dataTo, setDataTo] = useState<number>(10)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [page, setPage] = useState<number>(1);

  const navigate = useNavigate();
  const { t } = useTranslation('global')
  const [accordionData, setAccordionData] = useState<AccordionGroupData[]>();
  const { language: appLanguage } = useLanguage();
  const [defaultService] = useState<DefaultDataService>(DefaultDataService.getInstance(setContextError));
  const [discussionService] = useState<DiscussionService>(DiscussionService.getInstance(setContextError));
  const [addDiscussion, setAddDiscussion] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<any>(null)
  const [sortParam, setSortParam] = useState<any>({})
  const goToEdit = useCallback((id, lang) => {
    navigate(`/main/update-discussions/${id}/${lang}`);
  }, [])

  const deleteDiscussionT = (id, lang) => {
    setOpenConfirm(true)
    const newConfirm = {
      title: t('dialog.delete.title'),
      content: t('dialog.delete.content'),
      cb: async () => {
        setLoadingDataGrid(true);
        await discussionService.deleteDiscussionT(appLanguage, id, lang);
        setLoadingDataGrid(false);
        getDataTable({ params: filtersData, limit: dataTo, sort: sortParam, offset: dataOffset });
      }
    }
    setConfirm(newConfirm)


  }

  useMemo(() => {
    // valido alguno de los filtros para ver que esten en el contexto
    if (!loading && assessmentBodies !== null) {

      Promise.all([
        defaultService.findAll(appLanguage, 'working-groups'),
        defaultService.findAll(appLanguage, 'plenaries'),
      ]).then(([workingGroups, plenaries]) => {

        setAccordionData([

          {
            customKey: 'assessment_body',
            parentDictionaryKey: 'discussion.menu.assessment-body',
            summary: t('discussion.menu.assessment-body'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: assessmentBodies.map(ab => new CodeDescription(ab.id, ab.id, ab.assessmentBodyName))
          },
          {
            customKey: 'round',
            parentDictionaryKey: 'discussion.menu.round',
            summary: t('discussion.menu.round'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: rounds.map(r => new CodeDescription(r.id, r.roundCode, r.roundValue))
          },
          {
            customKey: 'country',
            parentDictionaryKey: 'discussion.menu.country',
            summary: t('discussion.menu.country'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: countries.map(c => new CodeDescription(c.id, c.countryCode, c.countryName))
          },
          {
            customKey: 'workingGroup',
            parentDictionaryKey: 'discussion.menu.workingGroup',
            summary: t('discussion.menu.workingGroup'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: workingGroups.map((wg, i) => {

              return new CodeDescription(wg.id, wg.id.toString(), wg.groupName.toString());
            })
          },
          {
            customKey: 'plenary',
            parentDictionaryKey: 'discussion.menu.plenary',
            summary: t('discussion.menu.plenary'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: plenaries.map((pl, i) => {
              const date = new Date(pl);
              const month = `0${(date.getMonth() + 1).toString()}`;
              const day = `0${(date.getDate()).toString()}`;
              return new CodeDescription(i, `${day.length > 2 ? day.substring(1, 3) : day}/${month.length > 2 ? month.substring(1, 3) : month}/${date.getFullYear()}`, `${t(`months.${date.getMonth() + 1}`)}  ${date.getFullYear()}`);
            })
          },
          {
            customKey: 'specific-charasteristics',
            parentDictionaryKey: 'discussion.menu.specific-characteristics',
            summary: t('discussion.menu.specific-characteristics.title'),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: SpecificCharacteristicsOptionsDTO.fromData(specificCharacteristics)
          },
        ]);
        setLoadingData(false);
      })
    }
  }, [loading, appLanguage])

  useMemo(() => {
    // Inicializo la cabecera 1 vez, solo se refresca si cambia el idioma.
    setDataHeader([
      // new TableHeaderCell({ key: 'flag', label: t('discussion.table.flag') }),
      new TableHeaderCell({ key: 'countryName', sorteable: true, label: t('discussion.table.countryName') }),
      new TableHeaderCell({ key: 'discussionLanguage', sorteable: true, label: t('discussion.table.lang') }),
      new TableHeaderCell({ key: 'assessmentBodyName', sorteable: true, label: t('discussion.table.assesmentBody') }),
      new TableHeaderCell({ key: 'merRound', sorteable: true, label: t('discussion.table.round') }),
      new TableHeaderCell({ key: 'groupName', sorteable: true, label: t('discussion.table.workingGroup') }),
      new TableHeaderCell({ key: 'plenary', sorteable: true, label: t('discussion.table.plenary') }),
    ]);
  }, [appLanguage]);

  const onSort = (key: string, order: string) => {
    const oldValue = { ...sortParam }
    setSortParam(Object.assign(oldValue, { [key]: order }))
  }
  const getDataTable = useCallback(({ params, lang, limit = dataTo, offset = dataOffset, sort }: { params?: Map<string, string[]>, sort, lang?, limit?, offset?, } = { params: filtersData, limit: dataTo, offset: dataOffset, sort: sortParam }) => {

    setLoadingDataGrid(true);
    // Params
    const args = parseParams(params);
    localStorage.setItem('discussionFilters', JSON.stringify({ params: Object.fromEntries(params), limit: limit, offset: offset, sort }));

    let sortArg = ''
    for (const key in sort) {
      if (sort[key] && sort[key] !== '') {
        if (sortArg === '') {
          sortArg += `${key}:${sort[key]}`
        }
        else {
          sortArg += `,${key}:${sort[key]}`
        }
      }
    }

    discussionService.findAll({ args, lang: lang ?? appLanguage, limit, offset, sort: sortArg }).then((data) => {
      setCount(data.count);
      setDataShow(data.results.map((r, i) => {
        const date = new Date(r.plenary);
        return new TableDetailCell(r, [
          new TableAction(t('discussion.table.buttons.edit'), (r, i) => { goToEdit(r.id, r.discussionLanguage) }, <EditIcon></EditIcon>),
          new TableAction(t('discussion.table.buttons.delete'), (r, i) => { deleteDiscussionT(r.id, r.discussionLanguage) }, <DeleteIcon></DeleteIcon>),
        ], new Map(
          [
            ['countryName', <CustomFlag key={i} code={r.countryCode} flagName={r.countryName} />],
            ['plenary', <span key={i}>
              {`${t(`months.${date.getMonth() + 1}`)}  ${date.getFullYear()}`}
            </span>],
          ]
        ))
      }))
    }).finally(() => { setLoadingDataGrid(false) });

  }, [filtersData, appLanguage, dataTo, dataOffset, sortParam]);

  // Se utiliza use memo para que solo se invoque sin filtros en la inicializacion, solo se vuelve a disparar si se cambia el idioma.
  useMemo(() => {
    const { params: auxParams, limit, offset, sort } = JSON.parse(localStorage.getItem('discussionFilters')) ?? {};
    const params = new Map();
    for (const prop in auxParams) {
      params.set(prop, auxParams[prop]);
    }
    setFiltersData(params ?? new Map());
    setDataOffset(offset ?? 0);
    setDataTo(limit ?? 10);
    const auxOffset: number = offset ? offset + 1 : 1
    setPage(auxOffset);
    setSortParam(sort ?? new Map());
    setInitialValues(params ?? new Map())
    getDataTable({ params: params ?? new Map(), lang: appLanguage, limit: limit ?? 10, offset: offset ?? 0, sort: sort ?? new Map() });
  }, [appLanguage])

  useEffect(() => {
    if (sortParam)
      getDataTable({ params: filtersData, sort: sortParam });
  }, [sortParam])
  return (
    <>
      {confirm &&
        <ConfirmDialog
          content={confirm.content}
          open={openConfirm}
          title={confirm.title}
          setOpen={setOpenConfirm}
          cb={confirm.cb}
        />}
      {/* {
        onDelete ?
          (
            <LoadingComponent styles={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '0', background: 'transparent', zIndex: '1001' }} />
          )
          :
          null
      } */}

      {
        addDiscussion &&
        <NewDiscussion
          open={addDiscussion}
          setOpen={setAddDiscussion}
        />
      }

      <ProgressBar title={t('discussion.title')} icon={KeyIcon} >

        <div className={'d-flex right'} style={{ alignItems: 'center' }}>
          <button className={`${classes.buttonBlue} mr-25`} onClick={() => setAddDiscussion(true)}>
            <NewMerIcon style={{ width: '28px', height: '28px' }} />
            {t('discussion.buttons.newDiscussion')}
          </button>
        </div>
      </ProgressBar>
      <div className='main-content'>

        <CustomAccordionFilter
          data={accordionData}
          initialValues={initialValues}
          loadingData={loadingData || initialValues === undefined}
          onSelectableChange={(dataSelected) => {
            setFiltersData(dataSelected);
            setDataOffset(0);
            setPage(1);
            getDataTable({ params: dataSelected, sort: sortParam });
          }}
          withChips={true}
        />
        <div className='page-content'>
          {loadingDataGrid ? (
            <LoadingComponent />
          ) :
            <div className='table'>
              <div className={'container-abm-page'}>
                <div className={'table-templates-container'}>
                  <DataTable
                    headers={dataHeader}
                    rows={dataShow}
                    countItems={count}
                    rowsPerPage={dataTo}
                    hasActions={true}
                    appendOnScrollToBottom={false}
                    onPageSizeChange={(size) => {
                      setDataTo(size)
                      setDataOffset(0)
                      getDataTable({ params: filtersData, limit: size, offset: 0, sort: sortParam });
                    }}
                    onPageChange={(newPage: number) => {

                      if (newPage !== page) {
                        setPage(newPage)
                        setDataOffset(newPage - 1);
                        getDataTable({ params: filtersData, offset: newPage - 1, limit: dataTo, sort: sortParam });
                      }
                    }}
                    onSortChange={onSort}
                    pageSelected={page}
                    pageable={true}
                    selectable={false}
                    className=' thinner'
                  ></DataTable>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Discussion
