import { Box, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './infoModal.module.css'
import { useErrorContext } from '../../contexts/error-context'
import { useState } from 'react'
import DefaultDataService from '../../services/default-data.service'
import { useLanguage } from '../../contexts/language-context'
import { handleFile } from '../../utils/CommonUtils'
import LoadingComponent from '../../components/common/loading/loading'

interface InfoModalInterface {
  open: boolean
  setOpen: any
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%) scale(0.7)',
  width: 855,
  bgcolor: 'white',
  border: 'none',
  borderRadius: '40px',
  outline: 'none'
}

export default function InfoModal({ open, setOpen }: InfoModalInterface) {
  const { t } = useTranslation('global')
  const { language } = useLanguage()
  const { setError } = useErrorContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const service = DefaultDataService.getInstance(setError)

  const handleClose = () => {
    setOpen(false)
  }
  const downloadPDF = async () => {
    try {
      setIsLoading(true)
      const result = await service.getInfoPDF(language)
      handleFile(result.data, 'info.pdf')
    } catch (e) {
      console.log(e)
    }
    finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <div className={`${styles.modalContainer}`}>
          <h1>{t('info.title')}</h1>
          <a className='lato c-pointer' href="https://gafilat.campustercertermino.com/login/index.php">
            {t('info.button')}
          </a>
          <div className={`${styles.contact}`}>
            <p>{t('info.contact')} <span className={`${styles.link}`}>{t('info.contact-mail')}</span> </p>
          </div>
          {!isLoading ?
            <div className={`${styles.modifications}`}>
              <p>{t('info.modifications')}
                <span onClick={downloadPDF} className={`${styles.link} c-pointer`}> {t('info.here')}</span>
              </p>
            </div>
            : <LoadingComponent />
          }

        </div>
      </Box>
    </Modal>
  )
}
