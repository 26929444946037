import './radiobutton.css'

export interface InputProps {
  name?: string
  label?: string
  checked?: boolean
  onChange?: any
}

const CustomRadioButton: React.FC<InputProps> = ({
  label,
  name,
  checked = false,
  onChange,
}: InputProps) => {
  const handleChange = () => {
    if (onChange) {
      onChange(checked)
    }
  }

  return (
    <>
      <input
        className='custom-radio'
        type='radio'
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      {label && <span>{label}</span>}
    </>
  )
}

export default CustomRadioButton
