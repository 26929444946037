import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ExpandIcon } from './../../../../assets/icons/minus-icon.svg';
import { ReactComponent as MerIcon } from './../../../../assets/icons/icono-mer.svg';
import MainLeftMenu from '../../../../components/layout/left-menu/left-menu';
import styles from './../../mer-fur.module.css';
import backgroundImage from './../../../../assets/images/step2-image.png'
import SpecificCharacteristics from './components/specific-characteristics';
import MerTextAreaList from './components/mer-text-area-list';
import { newMerMenu } from '../../../../configs/new-mer.config';
import CustomAccordion from '../../../../components/common/accordion/accordion';
import { getMerInformationForm } from '../../../../configs/data';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import MutualEvaluationService from '../../../../services/mutual-evaluation.service';
import KeyDeficiencies from './components/key-deficiencies';
import ImmediateOutcome from './components/immediate-outcome';
import Recommendation from './components/recommendation';
import { useTranslation } from 'react-i18next';
import CustomFlag from '../../../../components/common/flag/flag';
import UpdateFileMerModal from './update-mer-file-modal';
import { useData } from '../../../../components/hooks/useData';
import { useLanguage } from '../../../../contexts/language-context';
import { handleFile } from '../../../../utils/CommonUtils';
import { CircularProgress } from '@mui/material';
import PlusIcon from '../../../../components/common/icon/plus-icon/plus-icon';
import LoadingComponent from '../../../../components/common/loading/loading';
import { useErrorContext } from '../../../../contexts/error-context';

export default function UpdateMER() {

  const { t } = useTranslation('global')
  const { id: merId, lang: merLanguage } = useParams()
  const { language: appLanguage } = useLanguage()

  const [methodology, setMethodology] = useState<string>('2022');

  const [loadingMerData, error, merData] = useData<any>(`${appLanguage}/mer/${merId}/header-info?lang=${merLanguage}`, 'GET')
  const [openModal, setOpenModal] = useState(false)
  const [isDownloadPdf, setIsDownloadPdf] = useState(false)
  const [selectedItem, setSelectedItem] = useState<{ menu: number, element: number }>(null)
  const [fullName, setFullName] = useState<string>('')
  const [renderList, setRenderList] = useState<any[]>([])
  const { chapterOne, executiveSummary } = getMerInformationForm()
  const { setError: setContextError } = useErrorContext()
  const service = MutualEvaluationService.getInstance(setContextError)
  const form = { chapterOne, executiveSummary }
  const { handleSubmit, watch, control, setValue, reset } = useForm({
    defaultValues: form
  })
  const downloadPdf = async () => {
    try {
      setIsDownloadPdf(true)
      const result = await service.downloadMutualEvaluationPDF(appLanguage, +merId, merLanguage)
      const filename = `MER-${merData?.countryDesc}-${merData?.period}-${merData?.roundDesc}-${merLanguage.toUpperCase()}`
      handleFile(result.data, `${filename}.pdf`)
    } catch (error) {
      // setContextError(error)
      console.log(error)
    }
    finally {
      setIsDownloadPdf(false)
    }
  }

  const getFixedIndex = (index: number): string => {
    return index < 9 ? `0${index + 1}` : `${index + 1}`
  }

  const getPutBody = (form: any) => {
    return {
      merId: +merId,
      lang: merLanguage,
      ...form
    }
  }
  const getItemFullName = (i: number, j: number): string => {
    const selectedItemCode = newMerMenu[i]?.detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[j]?.code
    let description: string = null
    let name = null

    if (selectedItemCode === 'recommendation' || selectedItemCode === 'immediateOutcome') {
      if (selectedItemCode === 'recommendation') {
        description = `recommendations.${methodology}.` + selectedItemCode + getFixedIndex(j)
      } else {
        description = selectedItemCode + getFixedIndex(j) + `.${methodology}.title`
      }
      name = `${t(`${selectedItemCode}.${methodology}.title`)} ${j + 1}`
    } else {
      name = `${t(`${selectedItemCode}.${methodology}.title`)}`
    }
    return description ? `${name} - ${t(description)}` : `${name}`
  }
  const handleSelection = useCallback((i: number, j: number) => {

    setFullName(getItemFullName(i, j))
    setSelectedItem({ menu: i, element: j })
    const renderList = (newMerMenu[i]?.detailList ?? []).filter(item => (item.visibleWithMethodology ?? []).find(met => met === methodology) !== undefined)[j]?.items.filter(item => (item.visibleWithMethodology ?? []).find(met => met === methodology) !== undefined);
    setRenderList(renderList ?? [])
  }, [methodology])

  const resetSelection = () => {
    setSelectedItem(null)
  }

  useEffect(() => {
    if (selectedItem) {
      const fullName: string = getItemFullName(selectedItem.menu, selectedItem.element)
      setFullName(fullName)
    }
  }, [appLanguage])

  useMemo(() => {
    if (merData!) {
      setMethodology(merData.roundMethodology ?? '2022');
    }

  }, [merData])

  return (
    <>
      {openModal && <UpdateFileMerModal open={openModal} setOpen={setOpenModal} />}
      <div className='progress-bar lato'>
        <div className='description-wrapper'>
          <MerIcon />
          <div>
            <span>{t('mer.description')}</span>
            {
              fullName &&
              <div style={{ color: '#5ECDE9', fontSize: '22px' }}>{fullName}</div>
            }
          </div>
        </div>
        {!loadingMerData && <div className='mer-info right'>
          <CustomFlag code={merData?.countryCode} />
          <span className='pl-15'>{merData?.countryDesc} - {merData?.period} - {merData?.roundDesc} - {merLanguage.toUpperCase()}</span>
        </div>}
      </div>
      <div className='main-content'>
        <MainLeftMenu>
          {

            loadingMerData ?
              <LoadingComponent></LoadingComponent>
              :
              newMerMenu.map((item, i) => {
                const path = 'update-mer.menu'
                const summary = t(path + '.' + item.summary + '.title')
                return (
                  <CustomAccordion
                    onClick={resetSelection}
                    customKey={i}
                    key={i}
                    summary={summary}
                    detailList={item.detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined).map((r, i) => {
                      const aux = path + '.' + item.summary + '.' + r.code + '.title'
                      return (r.code === 'recommendation' || r.code === 'immediateOutcome') ?
                        `${t(path + '.' + item.summary + '.' + r.code + '.title')} ${i + 1}`
                        : t(aux)
                    })}
                    expandIcon={ExpandIcon}
                    collapseIcon={PlusIcon}
                    onClickChildren={(childrenId: number) => { handleSelection(i, childrenId) }}
                  />
                )
              })
          }
        </MainLeftMenu>
        <div className='page-content'>
          {
            loadingMerData ?
              <LoadingComponent></LoadingComponent>
              :
              <>
                {!selectedItem && <div className={`pt-64 ${styles.newMerContainer}`}>
                  <img className={styles.introImage} src={backgroundImage}></img>
                  <h1 className='primary-title'>{t('mer.description')}</h1>
                  <div className='primary-description'>{t('update-mer.welcome')}</div>
                  <div className='secondary-description' style={{ marginTop: '100px' }}>
                    {!isDownloadPdf && <p className={styles.modifyFile} onClick={downloadPdf}>
                      {t('update-mer.download')}
                    </p>}
                    {isDownloadPdf && <CircularProgress disableShrink />}
                    <p className={styles.modifyFile} onClick={() => setOpenModal(true)} >{t('update-mer.update')}</p>
                  </div>
                </div>
                }
                {selectedItem &&
                  <div className={`${styles.newMerContainer}`}>
                    {selectedItem.menu === 0 && renderList.length > 0 && newMerMenu[selectedItem.menu].detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[selectedItem.element].code !== 'specificCharacteristics' &&
                      <MerTextAreaList
                        methodology={methodology}
                        reset={reset}
                        list={renderList}
                        formData={newMerMenu[selectedItem.menu].detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[selectedItem.element].code}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        urlFetchData={newMerMenu[selectedItem.menu].detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[selectedItem.element].name}
                        merLanguage={merLanguage}
                        onSubmit={handleSubmit(async (value) => {
                          const id = +merId
                          const el = newMerMenu[selectedItem.menu].detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[selectedItem.element];
                          const formName = el.code;
                          const form = value[formName]
                          await service.updateMerPart(appLanguage, id, el.name, getPutBody(form))
                        })}
                      />
                    }
                    {selectedItem.menu === 0 && newMerMenu[selectedItem.menu].detailList.filter(dl => dl.visibleWithMethodology.find(vm => vm === methodology) !== undefined)[selectedItem.element].code === 'specificCharacteristics' &&
                      <SpecificCharacteristics
                        methodology={methodology}
                        merId={+merId}
                        merLanguage={merLanguage}
                      />
                    }

                    {selectedItem.menu === 1 &&
                      <ImmediateOutcome
                        methodology={methodology}
                        immediateOutcomeId={`immediateOutcome${getFixedIndex(selectedItem.element)}`}
                        merId={+merId}
                        merLanguage={merLanguage}

                      />
                    }

                    {selectedItem.menu === 2 && selectedItem.element !== 40 &&
                      <Recommendation
                        methodology={methodology}
                        recommendationId={`recommendation${getFixedIndex(selectedItem.element)}`}
                        merId={+merId}
                        merLanguage={merLanguage}
                      />
                    }

                    {selectedItem.menu === 2 && selectedItem.element === 40 &&
                      <KeyDeficiencies
                        methodology={methodology}
                        merId={+merId}
                        merLanguage={merLanguage}
                      />
                    }
                  </div>
                }
              </>
          }
        </div>
      </div>
    </>
  )
}
